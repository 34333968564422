import axios from 'axios';
import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { hosting } from '../cases/Hosting';

interface VersionContextType {
  version: string;
}

const VersionContext = createContext<VersionContextType>({ version: '' });

export const useVersion = () => useContext(VersionContext);

export const VersionProvider = ({ children }: { children: ReactNode }) => {
    const [version, setVersion] = useState('');

    useEffect(() => {
        const fetchVersion = async () => {
            try {
                const versionResponse = await axios.get(`${hosting}/version`);
                const fetchedVersion = versionResponse.data; 
                setVersion(fetchedVersion);
            } catch (error) {
                console.error(error);
            }
        };

        fetchVersion();
    },[]); 

    return (
        <VersionContext.Provider value={{ version }}>
            {children}
        </VersionContext.Provider>
    );
};
