import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";
import { formatPlacement } from "../../cases/GameTimer";
export default function SummonerBadges({summoner, index}:any){
    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

    return(
        <>
        {summoner[index]?.participant.placement !== 0 ? (<span className="h-[18px] pl-2 pr-2 pt-px pb-px bg-secondary_light text-white dark:bg-primary rounded-lg text-[10px] text-center">{formatPlacement(summoner[index]?.participant.placement)} place</span>):(null)}
        {summoner[index]?.participant.individualPosition !== "Invalid" ? (
            <span className="h-[18px] lowercase first-letter:uppercase pl-2 pr-2 pt-px pb-px bg-secondary_light text-white dark:bg-primary rounded-lg text-[10px]">
                {summoner[index]?.participant.individualPosition === "UTILITY" ? `${currLangData.lines.Support}` : currLangData.lines[summoner[index]?.participant.individualPosition.charAt(0).toUpperCase() + summoner[index]?.participant.individualPosition.slice(1).toLowerCase()]}
            </span>
        ) : (null)}
        {summoner[index]?.participant.largestMultiKill > 1 && (<span className="h-[18px] pl-2 pr-2 pt-px pb-px bg-secondary_light text-white dark:bg-primary text-[10px] rounded-lg">{['','',`${currLangData.kills.dk}`,`${currLangData.kills.tk}`,`${currLangData.kills.qk}`,`${currLangData.kills.pk}`,`${currLangData.kills.hk}`][summoner[index]?.participant.largestMultiKill]}</span>)}
        {summoner[index]?.participant.firstBloodKill === true ? (<span className="h-[18px] lowercase first-letter:uppercase pl-2 pr-2 pt-px pb-px bg-secondary_light text-white dark:bg-primary rounded-lg text-[10px] text-center">{currLangData.kills.fb}</span>) : (null)}    
        {summoner[index]?.csM > 10 ? (<span className="h-[18px] pl-2 pr-2 pt-px pb-px bg-secondary_light text-white dark:bg-primary rounded-lg text-[10px] text-center">Great CS</span>) : (null)}
        </>
    )
}