import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";
export default function WhatsNew(){
    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

    const whatsNew = [  currLangData.main.st, 
                        currLangData.main.nd,
                        currLangData.main.rd,
                        currLangData.main.th,
                        currLangData.main.thA,];

    return(
        <div className="w-full flex flex-col justify-center items-center md:w-[220px] h-[270px] md:h-[325px] bg-primary_l_trans dark:bg-primary_trans p-2 rounded-none md:rounded-md shadow-md BoxShadow">
                <div className="text-black dark:text-white text-center mb-2">{currLangData.main.wn}</div>
                    <div className="w-full md:w-[204px] h-[220px] md:h-[275px] overflow-y-scroll rounded-md">
                        {whatsNew.map((item:any, index:number)=>(
                            <div key={index} className="mt-[6px] first:mt-0 p-1 px-2 bg-third_light dark:bg-primary rounded-md border-[1px] border-pink">
                                <span className="text-sm text-text_light dark:text-text">{item}</span>
                            </div>
                        ))}
                    </div>
            </div>
    )
}