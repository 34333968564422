import { useEffect, useState } from "react";
import { useVersion } from "../contexts/VersionContext";
import axios from "axios";
import CreatorProfile from "../components/CreatorProfile";
import WhatsNew from "../components/WhatsNew";
import Featured from "../components/Featured";
import PatchNotes from "../components/PatchNotes";
import Rotation from "../components/Rotation";
interface Champion{
    id: string;
    key: string;
    name: string;
    tags: string;
}
export default function MainPage(){
    const {version} = useVersion();
    const [champions, setChampions] = useState<Champion[]>([]);
    const [randomChampIndex, setRandomChampIndex] = useState<number>(0);



    useEffect(() => {
        if (!version) return;
    
        const fetchChamps = async () => {
            try {
                const champsResponse = await axios.get(`https://ddragon.leagueoflegends.com/cdn/${version}/data/en_US/champion.json`);
                setChampions(Object.values(champsResponse.data.data));
                setRandomChampIndex(Math.floor(Math.random() * champions?.length));
            } catch (error) {
                console.error(error);
            }
        };
        fetchChamps();
    }, [version, champions.length]);

    useEffect(() => {
        if (champions.length > 0) {
            setRandomChampIndex(Math.floor(Math.random() * champions.length));
        }
    }, [champions, champions.length]);

    const champion = champions[randomChampIndex];



    return(
    <div className="w-full flex justify-center h-[1400px] md:h-[950px] overflow-hidden md:pb-0 pb-10 items-start md:items-center position: relative">
        <div className="positon: absolute w-full overflow-hidden bg-primary flex justify-center items-center">
        {version?<img src={`https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${champion?.id}_0.jpg`} alt={champion?.id} className="w-full h-[1400px] md:h-[950px] blur-[15px] object-cover"/>:null}
        </div>

        <div className="z-10 pt-20 md:pt-0 w-full md:w-[660px] ">

        <div className="flex justify-center md:justify-between flex-wrap gap-5 mt-5">
        
            <CreatorProfile />

            <Featured/>
            
            <WhatsNew/>

            <PatchNotes/>

            <Rotation/> 
        </div>
        </div>
    </div>
    )
}