import { useEffect, useState } from 'react';
import RecentItem from '../components/RecItem';
import { FavoriteItem } from '../components/FavItem';
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";

export default function DropdownRecFav({onLinkClick} :any) {
  const [selectedTab, setSelectedTab] = useState('Recent');
  const [favorites, setFavorites] = useState([]);
  const [recent, setRecent] = useState([]);
  const {language: lang} = useLanguage();
  const currLangData: any = (languageData as any)[lang] || (languageData as any).en;


  useEffect(() => {
    setFavorites(JSON.parse(localStorage.getItem('favorites') || '[]'));
    setRecent(JSON.parse(localStorage.getItem('recent') || '[]'));
  }, [selectedTab]);
  

  const removeFavorite = (summoner: string, tagLine: string) => {
    const updatedFavorites = favorites.filter((fav: any) => !(fav.summoner === summoner && fav.tagLine === tagLine));
    setFavorites(updatedFavorites);
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
  };

  const removeRecent = (summoner: string, tagLine: string) => {
    const updatedRecent = recent.filter((rec: any) => !(rec.summoner === summoner && rec.tagLine === tagLine));
    setRecent(updatedRecent);
    localStorage.setItem('recent', JSON.stringify(updatedRecent));
  };
  

  return (
    <div className="text-white dark:text-text_dark bg-third_light dark:bg-third position: absolute top-[37px] left-[72px] shadow-[0_8px_12px_0_rgba(0,0,0,0.3)] z-50 rounded-b-md overflow-hidden w-[256px]">
      <div className="flex">
        <button className={`w-[128px] py-[6px] px-[8px] text-sm text-black dark:text-white ${selectedTab === 'Recent' ? '' : 'dark:bg-primary shadow-md bg-primary_light'}`} onClick={() => setSelectedTab('Recent')}>
        {currLangData.nav.searchBar.recent}
        </button>
        <button className={`w-[128px] py-[6px] px-[8px] text-sm text-black dark:text-white ${selectedTab === 'Favorites' ? '' : 'dark:bg-primary shadow-md bg-primary_light'}`} onClick={() => setSelectedTab('Favorites')}>
        {currLangData.nav.searchBar.fav}
        </button>
      </div>
      <div className='dark:text-white text-black max-h-[250px] overflow-y-scroll'>
      {selectedTab === 'Recent' ? 
          recent.map((rec, index) => <RecentItem key={index} rec={rec} onLinkClick={onLinkClick} removeRecent={removeRecent}/>)
          :
          favorites.map((fav, index) => <FavoriteItem key={index} fav={fav} onLinkClick={onLinkClick} removeFavorite={removeFavorite} />)
        }
      </div>
    </div>
  );
}
