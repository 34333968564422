import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { useVersion } from "../contexts/VersionContext";

import { FaTwitch } from "react-icons/fa";
import { Link } from "react-router-dom";
import TiltCard from "./TiltCard";
import { ClipLoader } from "react-spinners";
import { hosting } from "../cases/Hosting";


export default function Featured(){
    const {version} = useVersion();
    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;
    const [summonerData, setSummonerData] = useState<any | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const puuids= ['Fanh3_HXNwyvWxUv__3r7v5PSfhexKVUrqNS4XNXBo8eJvZR0cdRwShVt6NpZdaHMXzDfi4R1gT81w',
        'PymV0YILsxyujePfgymDwIrFmc-5AYw51JGxyseyR_txS-h6Mj0gAgYJjf_7IYu0IBnh11jWArjBRQ',
        'FfjfA7IcKqWObUfAmvjtW7mTUs3MBog6_bi1OlL2iy0Bzb8B0ecGjEGHZ4mRFjq442oLgXh_Avdv5g']    
    
        const fetchData = async () => {
          try {
            setLoading(true);
            const promises = puuids.map(puuid =>
                axios.get(`${hosting}/summoner/euw/${puuid}`)
            );
            const responses = await Promise.all(promises);
            const data = responses.map(response => response.data);
            setSummonerData(data);
            setLoading(false);
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchData();
        }, []);

    return(
        <div className={`${loading ? ('flex items-center justify-center'):(null)} w-full md:w-[420px] h-[325px] bg-primary_l_trans dark:bg-primary_trans p-2 rounded-none md:rounded-md shadow-md BoxShadow `}>
            {loading ? (<ClipLoader color="#85417C"/>):(
            <>
                <div className="dark:text-white text-black text-center mb-2">{currLangData.main.feat}</div>
                
                <div className={`flex flex-col items-center ${loading ? ('h-[270px] justify-center'):(null)} gap-5`}>
                    {summonerData?.map((summoner: any, index: number) => {
                        const soloQueueRank = summoner?.summonerRank?.find((rank: any) => rank.queueType === "RANKED_SOLO_5x5");
                        const ttv = ['https://www.twitch.tv/vysotzky', 'https://www.twitch.tv/xayoo_', 'https://www.twitch.tv/lukisteve']
                        return (
                            <TiltCard key={index}>

                                <Link target="_blank" className="text-white hover:text-text position: absolute top-0 right-0 bg-pink p-1 rounded-bl-md" to={ttv[index]}><FaTwitch/></Link>

                                <Link to={`/summoner/euw/${summoner.gameName}/${summoner.tagLine}`} className="flex items-start bg-third_light dark:bg-primary p-1 rounded-md w-full">
                                    <div className="position: relative bg-third_light dark:bg-primary p-[5px] rounded-[11px] h-[70px] w-[70px] flex justify-center">
                                        <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${summoner?.summonerInfo.profileIconId}.png`} alt={summoner?.summonerInfo.profileIconId} className="w-[60px] h-[60px] rounded-md"/>
                                    </div>
                                    <div className="flex flex-col ml-1">
                                        <div className="text-black dark:text-white flex items-center">{summoner.gameName}<span className="text-text">#{summoner.tagLine}</span></div>
                                        <span className="text-xs text-text">{currLangData.widgets.rankSol}</span>
                                        <span className='text-text_light dark:text-text text-[14px] flex items-center gap-1 h-7 uppercase'>
                                        {soloQueueRank ? (
                                            <span className="text-xs flex items-center">
                                                <img className="w-7 h-7" src={`/tiers/${soloQueueRank.tier}.png`} alt={soloQueueRank.tier}/>
                                                {["GRANDMASTER", "CHALLENGER", "MASTER"].includes(soloQueueRank.tier) ?
                                                    `${soloQueueRank.tier} (${soloQueueRank.leaguePoints} LP)` :
                                                    `${currLangData.ranks[(soloQueueRank.tier).toLowerCase()]} ${soloQueueRank.rank} (${soloQueueRank.leaguePoints} LP)`}
                                            </span>
                                        ) : (
                                            `${currLangData.ranks.ur}`
                                        )}
                                        </span> 
                                    </div>
                                </Link>
                            </TiltCard>
                        )})}
                
                </div>
            </>
            )}
        </div>
    )
}