import { useState, useRef, useEffect } from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import RegionList from '../navBar/RegionList';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";
import { useVersion } from '../contexts/VersionContext';
import { hosting } from '../cases/Hosting';

export default function SearchBarCompare({setdataCompare,setLoadingCompare,setSearchCompare,setPreLoad}:any) {
  const [showRegions, setShowRegions] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('eune');
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [searchResults, setSearchResults] = useState<any>([]);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const [spinner, setSpinner] = useState(false);
  const toggleRegions = () => setShowRegions(!showRegions);


  const {language: lang} = useLanguage();
  const currLangData: any = (languageData as any)[lang] || (languageData as any).en;
  const { version } = useVersion();

  const summonersToShow = searchResults?.summoners?.slice(0, 5) || [];

  const parseInputValue = (inputValue:string) => {
    const splitIndex = inputValue.indexOf('#');
    if (splitIndex !== -1) {
      return {
        summoner: inputValue.substring(0, splitIndex).trim(),
        tagLine: inputValue.substring(splitIndex + 1).trim(),
      };
    }
    return { summoner: inputValue.trim(), tagLine: '' };
  };

  const parsedInput = parseInputValue(inputValue);

  const handleGoClick = async (summonerName:any, tagLine:any, urlRegion :any) => {
    const urlTagLine = tagLine || urlRegion.toUpperCase();
    setSpinner(true);
    setPreLoad(false);
    setLoadingCompare(true); 
    try {
      const response = await axios.get(`${hosting}/compare/${urlRegion}/${encodeURIComponent(summonerName)}/${encodeURIComponent(urlTagLine)}`);
      const data = await response.data;
      if (data) {
        const CompareData = response.data;
        setdataCompare(CompareData);
        setLoadingCompare(false);
        setSearchCompare(false);
        setSpinner(false);
      } else {
        throw new Error('Summoner not found');
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
      setLoadingCompare(false);
      setSpinner(false);
    }
  };
  
  const handleRegionSelect = (region:string) => {
    setSelectedRegion(region);
    setInputValue("");
    setShowRegions(false);
  };

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    if (value) {
      const results = await fetchSearchResults(value, selectedRegion);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
    setShowRegions(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
        setShowRegions(false);
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  async function fetchSearchResults(searchTerm: string, selectedRegion: string) {
    try {
        const response = await fetch(`${hosting}/searchBar?searchTerm=${encodeURIComponent(searchTerm)}&region=${encodeURIComponent(selectedRegion)}`);
        if (response.ok) {const data = await response.json(); return data;} else {console.error('Failed to fetch search results'); return [];}
    } catch (error) {
        console.error('Failed to fetch search results', error);
        return [];
    }
  }


  return (
    <div ref={searchBarRef} className="flex w-full md:w-auto rt:w-auto mt-3 md:mt-0 position: relative">
      <button onClick={toggleRegions} className="text-black w-[72px] justify-between rounded-l-md flex items-center bg-inputColor px-2 h-9 font-semibold text-sm">
        {selectedRegion.toUpperCase()} {showRegions ? <MdArrowDropUp fontSize={20} /> : <MdArrowDropDown fontSize={20} />}
      </button>
      {showRegions && <RegionList onSelect={handleRegionSelect} />}
      <input type="text" placeholder={`Summoner#${selectedRegion.toUpperCase()}`} value={inputValue} onChange={handleInputChange}
        onFocus={handleInputFocus}
        className={`${isFocused || inputValue ? "w-full md:w-64 rt:w-64" : "w-full md:w-32 rt:w-32"} rounded-none dark:bg-white text-black outline-none px-1 h-9 text-sm input-transition`}
      />
      <button onClick={() => handleGoClick(parsedInput.summoner, parsedInput.tagLine, selectedRegion)}
        className="h-9 w-9 rounded-r-md bg-white hover:bg-inputColor text-black font-semibold text-sm px-2 flex items-center justify-center"
        disabled={inputValue === ''}>
        {spinner ? (<ClipLoader color="#85417C" size={20}/>) : ('GO')}
    </button>
      
      <div className="text-white dark:text-text_dark bg-third_light dark:bg-third position: absolute top-[37px] left-[72px] shadow-[0_8px_12px_0_rgba(0,0,0,0.3)] z-20 rounded-b-md overflow-hidden overflow-y-auto max-h-[450px]">
        <div className="w-[250px]">
        {summonersToShow.length !== 0 ? (<div className="bg-primary_light dark:bg-secondary p-2 pt-1 pb-1 text-sm text-black dark:text-white">{currLangData.nav.settings.suggested.summs}</div>):(null)}
        {summonersToShow.map((summoner:any) => (
          <button 
            onClick={() => handleGoClick(summoner.gameName, summoner.tagLine, summoner.urlRegion)}
            key={summoner.puuid} 
            className="flex w-[250px] p-2 hover:bg-primary_light dark:hover:bg-secondary">
              <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${summoner.summonerIcon}.png`} 
                  alt={summoner.summonerIcon} 
                  className="w-[40px] h-[40px] rounded-md mr-2"/>
              <div className="text-black dark:text-white text-sm">
                  <div className="flex">
                      <span className="max-w-[100px] text-ellipsis whitespace-nowrap overflow-hidden">{summoner.gameName}</span>
                      <span className="text-text"> #{summoner.tagLine}</span>
                  </div>
                  <p className="text-text text-left">{currLangData.level} {summoner.summonerLevel}</p>
              </div>
          </button>
        ))}
        </div>
      </div>

    </div>
  );
}
