import { useState, useRef, useEffect } from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import RegionList from './RegionList';
import DropdownRecFav from './DropdownRecFav';
import SuggestedSearch from './SuggestedSearch';
import { ClipLoader } from 'react-spinners';
import { useExpand } from '../contexts/ExpandContext';
import { hosting } from '../cases/Hosting';

export default function SearchBar() {
  const [showRegions, setShowRegions] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('eune');
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const [isSuggestedSearchVisible, setIsSuggestedSearchVisible] = useState(true);
  const [load, setLoad] = useState(false);
  const { expand } = useExpand();

  const handleLinkClick = () => {
    setIsSuggestedSearchVisible(false);
    setInputValue('');
    setIsSuggestedSearchVisible(true);
    setIsFocused(false);
  };

  const toggleRegions = () => setShowRegions(!showRegions);

  const parseInputValue = (inputValue:string) => {
    const splitIndex = inputValue.indexOf('#');
    if (splitIndex !== -1) {
      return {
        summoner: inputValue.substring(0, splitIndex).trim(),
        tagLine: inputValue.substring(splitIndex + 1).trim(),
      };
    }
    return { summoner: inputValue.trim(), tagLine: '' };
  };

  const handleGoClick = async () => {
    const { summoner, tagLine } = parseInputValue(inputValue);
    const urlTagLine = tagLine || selectedRegion.toUpperCase(); 
    setLoad(true);
    try {
      const response = await fetch(`${hosting}/summoner/${selectedRegion}/${encodeURIComponent(summoner)}/${encodeURIComponent(urlTagLine)}`);
      if (!response.ok) {
        throw new Error('Summoner not found');
      }
      const data = await response.json();
      if (data) {
        const newSearch = { summoner, tagLine: urlTagLine, region: selectedRegion };
        addSearchToRecent(newSearch);
        setLoad(false);
        window.location.href = `/summoner/${selectedRegion}/${encodeURIComponent(summoner)}/${encodeURIComponent(urlTagLine)}`;
      } else {
        throw new Error('Summoner not found');
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  };
  
  const addSearchToRecent = (search:any) => {
    let recentSearches = JSON.parse(localStorage.getItem('recent') || '[]');
    const isExisting = recentSearches.some((recent : any) => recent.summoner === search.summoner && recent.region === search.region && recent.tagLine === search.tagLine);
    if (!isExisting) {
      recentSearches = [search, ...recentSearches].slice(0, 10);
      localStorage.setItem('recent', JSON.stringify(recentSearches));
    }
  };

  const handleRegionSelect = (region:string) => {
    setSelectedRegion(region);
    setInputValue("");
    setShowRegions(false);
  };

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    if (value) {
      const results = await fetchSearchResults(value, selectedRegion);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
    setShowRegions(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
        setShowRegions(false);
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  async function fetchSearchResults(searchTerm: string, selectedRegion: string) {
    try {
        const response = await fetch(`${hosting}/searchBar?searchTerm=${encodeURIComponent(searchTerm)}&region=${encodeURIComponent(selectedRegion)}`);
        if (response.ok) {const data = await response.json(); return data;} else {console.error('Failed to fetch search results'); return [];}
    } catch (error) {
        console.error('Failed to fetch search results', error);
        return [];
    }
  }

  function handleKeyPress(e:any){
    var key=e.keyCode || e.which;
    if (key===13){
        handleGoClick();
    }
  }

  return (
    <div ref={searchBarRef} className="flex w-full md:w-auto rt:w-auto mt-3 md:mt-0 position: relative dark:ShadowUnset BoxShadowBlk rounded-md ">
      <button onClick={toggleRegions} className="text-black w-[72px] justify-between rounded-l-md flex items-center bg-inputColor px-2 h-9 font-semibold text-sm">
        {selectedRegion.toUpperCase()} {showRegions ? <MdArrowDropUp fontSize={20} /> : <MdArrowDropDown fontSize={20} />}
      </button>
      {showRegions && <RegionList onSelect={handleRegionSelect} />}
      <input type="text" placeholder={`Summoner#${selectedRegion.toUpperCase()}`} value={inputValue} onChange={handleInputChange}
        onFocus={handleInputFocus}
        onKeyDown={handleKeyPress}
        className={`${expand ? (isFocused || inputValue ? ("w-full md:w-64 rt:w-64") : ("w-full md:w-32 rt:w-32")):('w-full md:w-64 rt:w-64')} dark:bg-white text-black outline-none px-1 h-9 rounded-none text-sm input-transition`}
      />
      {isFocused && !inputValue && !showRegions && <DropdownRecFav onLinkClick={handleLinkClick}/>}
      {isSuggestedSearchVisible && isFocused && inputValue && !showRegions && <SuggestedSearch addSearchToRecent={addSearchToRecent} searchResults={searchResults} onLinkClick={handleLinkClick} />}
        <button 
        onClick={handleGoClick} 
        className="h-9 w-9 rounded-r-md bg-white hover:bg-inputColor text-black font-semibold text-sm px-2 flex justify-center items-center"
        disabled={inputValue === ''}
        >
        {load ? (<ClipLoader color="#85417C" size={20}/>) : ('GO')}
        </button>
    </div>
  );
}
