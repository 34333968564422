import { ClipLoader } from "react-spinners";
import { useVersion } from "../contexts/VersionContext";
import StatisticRow from "./CompareStatic";
import { useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import SearchBarCompare from "../components/SearchBarCompare";
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";

export default function SummonerCompare({matches, summonerData, loading}:any){
    const { version } = useVersion();
    const [compareSearch, setSearchCompare] = useState(false);

    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;


    const AAAAA = () => {
        setSearchCompare((prev: any) => !prev)
    };

    const [dataCompare, setdataCompare] = useState<any>([]);
    const [loadingCompare, setLoadingCompare] = useState(true);
    const [preLoad, setPreLoad] = useState(true);

    function processSummonerData(matches: any, puuid: string) {
        return matches?.map((matchData: any) => {
            const gameDur = matchData.match.info.gameDuration;
            const participant = matchData.match.info.participants.find((p: any) => p.puuid === puuid);
            if (!participant) {return null;}
            const { win, gameEndedInEarlySurrender: remake, kills, deaths, assists, teamId, totalMinionsKilled, neutralMinionsKilled, timePlayed } = participant;
            const kda = ((kills + assists) / Math.max(deaths, 1)).toFixed(1);
            const matchResult = remake ? "Remake" : win ? "Victory" : "Defeat";
            const teamKills = matchData.match.info.teams.find((team: any) => team.teamId === teamId)?.objectives.champion.kills || 0;
            const kpRatio = teamKills > 0 ? ((kills + assists) / teamKills * 100).toFixed(0) : "0";
            const cs = totalMinionsKilled + neutralMinionsKilled;
            const min = Math.floor(timePlayed/60);
            const sec = timePlayed-(min*60);
            const time = (min * 60 + sec)/60; 
            const csM = (cs/time).toFixed(1);
            const dmgDealt = participant.totalDamageDealtToChampions;
            const dmgTaken = participant.totalDamageTaken;
            const gold = participant.goldEarned;
            const wards = participant.detectorWardsPlaced;
            const wins = participant.win;
            return {gameDur, participant, result: matchResult, kills, deaths, assists, kda, teamId, kpRatio, cs, csM, dmgDealt, dmgTaken, gold, wins, wards};
        }).filter(Boolean);
    }

      const calculateAverages = (summonerData:any) => {const stats:any = {gameDur: 0, kills: 0, deaths: 0, assists: 0, cs: 0, csM: 0, kpRatio: 0, dmgDealt: 0, dmgTaken: 0, gold: 0, wins: 0, losses: 0, wards: 0,};
    
        summonerData?.forEach((participant:any) => {
            stats.gameDur += participant.gameDur; 
            stats.kills += participant.kills;
            stats.deaths += participant.deaths;
            stats.assists += participant.assists;
            stats.cs += participant.cs;
            stats.csM += parseFloat(participant.csM);
            stats.kpRatio += parseFloat(participant.kpRatio); 
            stats.dmgDealt += participant.dmgDealt;
            stats.dmgTaken += participant.dmgTaken;
            stats.gold += participant.gold;
            stats.wins += participant.wins;
            stats.wards += participant.wards;
        });
    
        const totalGames = summonerData?.length;
        Object.keys(stats).forEach((key:any) => {if (key !== 'kpRatio') { stats[key] = stats[key] / totalGames;}});
        stats.kpRatio = (stats.kpRatio / totalGames).toFixed(0) + '%';
        stats.gameDurFormatted = Math.floor(stats.gameDur / 60);
        stats.killsM = (stats.kills/stats.gameDurFormatted);
        stats.assistsM = (stats.assists/stats.gameDurFormatted);
        stats.deathsM = (stats.deaths/stats.gameDurFormatted);
        stats.dmgDM = (stats.dmgDealt/stats.gameDurFormatted);
        stats.dmgTM = (stats.dmgTaken/stats.gameDurFormatted);
        stats.dmgDPD = (stats.dmgDealt/stats.deaths);
        stats.dmgTPD = (stats.dmgTaken/stats.deaths);
        stats.goldPM = (stats.gold/stats.gameDurFormatted);
        stats.kda = ((stats.kills+stats.assists)/stats.deaths).toFixed(1);
        return stats;
    };
    
    const summoner = processSummonerData(matches?.matches, summonerData?.summonerInfo.puuid);
    const summonerCompare = processSummonerData(dataCompare?.matches, dataCompare?.summonerInfo?.puuid);

    const avgSummoner = calculateAverages(summoner);
    const avgCompare = calculateAverages(summonerCompare);


    function ButtonCompare(){
        return(
            <button onClick={AAAAA} className="w-[28px] h-[28px] flex justify-center items-center bg-pink rounded-tr-[11px] rounded-bl-[11px] right-0 top-0 position: absolute text-white"><FaMagnifyingGlass /></button>
        )
    }

    return(
        <div className="w-full flex justify-center mt-12">
            <div className={`w-[540px] flex flex-col justify-between bg-third_light dark:bg-secondary smComp:rounded-md position: relative`}>
                    
                    {compareSearch && (<div className="position: absolute top-0 left-0 w-full h-full dark:bg-[rgba(0,0,0,.7)] bg-[rgba(88,88,88,0.2)] z-10 rounded-md flex flex-col p-2">
                        <div className="flex justify-end"><IoCloseOutline onClick={AAAAA} className="w-6 h-6 cursor-pointer text-white hover:text-pink"/></div>
                        <div className="flex justify-center"><SearchBarCompare setdataCompare={setdataCompare} setLoadingCompare={setLoadingCompare} setSearchCompare={setSearchCompare} setPreLoad={setPreLoad}/></div>
                    </div>)}

            <div className={`flex justify-between p-2 ${compareSearch ? ('blur-sm') : ('')}`}>
                    <div>
                        <div className="position: relative bg-primary_light dark:bg-primary p-[5px] rounded-[11px] h-[130px] w-[110px] flex justify-center">
                            {!loading ? (
                            <>
                            <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${summonerData?.summonerInfo?.profileIconId}.png`} alt={summonerData?.summonerInfo?.profileIconId} className="w-[100px] h-[100px] rounded-md"/>

                            <div className=" position: absolute bottom-[3px] left-0 flex justify-center w-full">
                                <span className="flex justify-center bg-primary_light dark:bg-primary text-[12px] rounded-md dark:text-text text-black">{currLangData.level} {summonerData?.summonerInfo?.summonerLevel}</span>
                            </div>
                            </>
                            ):(<ClipLoader color="#85417C" className="mt-10"/>)}
                        </div>
                    </div>

                        <div className="flex justify-center items-center text-[25px] text-black dark:text-white font-bold">
                            VS
                        </div>

                    <div className="text-right flex flex-col items-end">
                        <div className="position: relative bg-primary_light dark:bg-primary p-[5px] rounded-[11px] h-[130px] w-[110px] flex justify-center">
                            {!loadingCompare ? (
                            <>
                            <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${dataCompare?.summonerInfo?.profileIconId}.png`} alt={dataCompare?.summonerInfo?.profileIconId} className="w-[100px] h-[100px] rounded-md"/>
                            <ButtonCompare/>
                            <div className=" position: absolute bottom-[3px] left-[0px] flex justify-center w-full">
                                <span className="flex justify-center bg-primary_light dark:bg-primary text-[12px] rounded-md dark:text-text text-black">{currLangData.level} {dataCompare?.summonerInfo?.summonerLevel}</span>
                            </div>
                            
                            </>
                            ):(<ButtonCompare/>)}
                        </div>
                    </div>
            </div>
            
            <div className={`flex flex-col ${compareSearch ? ('blur-sm') : ('')} compare`}>
            <StatisticRow label="Name" value={`${summonerData?.gameName}`} loadingCompare={loadingCompare} loading={loading} preLoad={preLoad} valueComp={`${dataCompare?.gameName}`}/>
            <StatisticRow label={currLangData.compare.winrate} value={`${(avgSummoner.wins * 100).toFixed(0)}%`} loadingCompare={loadingCompare} loading={loading} preLoad={preLoad} valueComp={`${avgCompare.wins * 100}%`}/>
            <StatisticRow label={currLangData.compare.kda} value={`${avgSummoner.kda}:1`} loadingCompare={loadingCompare} loading={loading} preLoad={preLoad} valueComp={`${avgCompare.kda}:1`}/>
            <StatisticRow label={currLangData.compare.cs} value={avgSummoner.cs} loadingCompare={loadingCompare} loading={loading} preLoad={preLoad} valueComp={avgCompare.cs}/>
            <StatisticRow label={currLangData.compare.csM} value={avgSummoner.csM.toFixed(1)} loadingCompare={loadingCompare} loading={loading} preLoad={preLoad} valueComp={avgCompare.csM.toFixed(1)}/>
            <StatisticRow label={currLangData.compare.gold} value={avgSummoner.gold} loadingCompare={loadingCompare} loading={loading} preLoad={preLoad} valueComp={avgCompare.gold}/>
            <StatisticRow label={currLangData.compare.goldM} value={avgSummoner.goldPM.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.goldPM.toFixed(1)}/>
            <StatisticRow label={currLangData.compare.kills} value={avgSummoner.kills.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.kills.toFixed(1)}/>
            <StatisticRow label={currLangData.compare.killsM} value={avgSummoner.killsM.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.killsM.toFixed(1)}/>
            <StatisticRow label={currLangData.compare.deaths} value={avgSummoner.deaths.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.deaths.toFixed(1)} />
            <StatisticRow label={currLangData.compare.deathsM} value={avgSummoner.deathsM.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.deathsM.toFixed(1)} />
            <StatisticRow label={currLangData.compare.assists} value={avgSummoner.assists.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.assists.toFixed(1)} />
            <StatisticRow label={currLangData.compare.assistsM} value={avgSummoner.assistsM.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.assistsM.toFixed(1)}  />
            <StatisticRow label={currLangData.compare.dmgD} value={avgSummoner.dmgDealt.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.dmgDealt.toFixed(1)} />
            <StatisticRow label={currLangData.compare.dmgDM} value={avgSummoner.dmgDM.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.dmgDM.toFixed(1)} />
            <StatisticRow label={currLangData.compare.dmgDD} value={avgSummoner.dmgDPD.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.dmgDPD.toFixed(1)} />
            <StatisticRow label={currLangData.compare.dmgT} value={avgSummoner.dmgTaken.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.dmgTaken.toFixed(1)} />
            <StatisticRow label={currLangData.compare.dmgTM} value={avgSummoner.dmgTM.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.dmgTM.toFixed(1)} />
            <StatisticRow label={currLangData.compare.dmgTD} value={avgSummoner.dmgTPD.toFixed(1)} loadingCompare={loadingCompare} preLoad={preLoad} loading={loading} valueComp={avgCompare.dmgTPD.toFixed(1)} />
            <StatisticRow label={currLangData.compare.ward} value={avgSummoner.wards} loadingCompare={loadingCompare} loading={loading} preLoad={preLoad} valueComp={avgCompare.wards} />

            </div>

            </div>
        </div>
    )
}