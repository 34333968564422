export default function Tos(){

    return(
        <div className="flex justify-center">
        <div className="flex flex-col justify-center max-w-[1080px] text-black dark:text-white px-2 pt-5">
        <p className="font-semibold pt-[10px] text-[13px]">1. Introduction</p>
            <p className="text-left text-text font-[11px]">
                Welcome to rafte.ch! These Terms of Service ("Terms") govern your use of our website located at 
                <a href="https://lol.rafte.ch" className="text-pink"> rafte.ch</a> ("Website"), and any related services provided by Rafte.ch. By accessing our Website, you agree to these Terms in full. If you disagree with any part of these Terms, you must not use our Website.
            </p>

            <p className="font-semibold pt-[10px] text-[13px]">2. Intellectual Property</p>
            <p className="text-left text-text font-[11px]">
            <span className="text-black dark:text-white text-[13px] font-semibold">2.1 </span>The content on our Website includes material like text, images, video clips, and other multimedia elements related to League of Legends, which is owned by Riot Games and other respective copyright and trademark holders. Such content is used under the terms of fair use or with permission and is not owned by Rafte.ch.
            </p>
            <p className="text-left text-text font-[11px]">
            <span className="text-black dark:text-white text-[13px] font-semibold">2.2 </span>All original content published and made available on our Website is the property of Rafte.ch, including but not limited to written articles, commentary, reviews, user interfaces, and original graphics.
            </p>

            <p className="font-semibold pt-[10px] text-[13px]">3. Age Restrictions</p>
            <p className="text-left text-text font-[11px]">
                The minimum age to use our Website is 13 years old. By using this Website, you represent that you are at least 13 years old or older.
            </p>

            <p className="font-semibold pt-[10px] text-[13px]">4. Acceptable Use</p>
            <p className="text-left text-text font-[11px]">
            <span className="text-black dark:text-white text-[13px] font-semibold">4.1 </span>You agree not to use our Website in a way that causes damage to the Website or impairment of the availability or accessibility of our Website.
            </p>
            <p className="text-left text-text font-[11px]">
            <span className="text-black dark:text-white text-[13px] font-semibold">4.2 </span>You agree not to use our Website in any way that is unlawful, illegal, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
            </p>

            <p className="font-semibold pt-[10px] text-[13px]">5. Accounts</p>
            <p className="text-left text-text font-[11px]">
            <span className="text-black dark:text-white text-[13px] font-semibold">5.1 </span>When you create an account on our Website, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms.
            </p>
            <p className="text-left text-text font-[11px]">
            <span className="text-black dark:text-white text-[13px] font-semibold">5.2 </span>You are responsible for safeguarding the password that you use to access the Website and for any activities or actions under your password.
            </p>

            <p className="font-semibold pt-[10px] text-[13px]">6. User Content</p>
            <p className="text-left text-text font-[11px]">
                Users may post, link, store, share, and otherwise make available certain information, text, graphics, videos, or other material on the Website ("User Content"). You are responsible for the User Content that you post to the Website, including its legality, reliability, and appropriateness.
            </p>

            <p className="font-semibold pt-[10px] text-[13px]">7. Changes to These Terms</p>
            <p className="text-left text-text font-[11px]">
                We reserve the right to modify these Terms at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes.
            </p>

            <p className="font-semibold pt-[10px] text-[13px]">8. Limitation of Liability</p>
            <p className="text-left text-text font-[11px]">
                Rafte.ch and its employees, contractors, and any other associates will not be liable for any actions, claims, losses, damages, liabilities, and expenses including legal fees from your use of the Website.
            </p>

            <p className="font-semibold pt-[10px] text-[13px]">9. Governing Law</p>
            <p className="text-left text-text font-[11px]">
                These Terms are governed by the laws of Switzerland without regard to its conflict of law provisions.
            </p>

            <p className="font-semibold pt-[10px] text-[13px]">10. Contact Information</p>
            <p className="text-left text-text font-[11px]">
                For any questions or concerns regarding these Terms, please contact us at <a href="mailto:info@rafte.ch" className="text-pink">info@rafte.ch</a>.
            </p>

            <p className="font-semibold pt-[10px] text-[13px]">Effective Date: 26.04.2024</p>

        </div>
        </div>
    )
}