import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useVersion } from '../contexts/VersionContext';
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";
interface Champion{
    id: string;
    key: string;
    name: string;
    tags: string;
}

export default function Champions() {
    const {version} = useVersion();
    const [champions, setChampions] = useState<Champion[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState('');

    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

    useEffect(() => {
        if (!version) return;
    
        const fetchChamps = async () => {
            try {
                const champsResponse = await axios.get(`https://ddragon.leagueoflegends.com/cdn/${version}/data/en_US/champion.json`);
                setChampions(Object.values(champsResponse.data.data));
            } catch (error) {
                console.error(error);
            }
        };
        fetchChamps();
    }, [version]);

    const handleRoleClick = (role:string) => {
        setSelectedRoles(prevSelectedRoles =>
            prevSelectedRoles.includes(role)
                ? prevSelectedRoles.filter(r => r !== role) 
                : [...prevSelectedRoles, role]
        );
    };
    
    const filteredChampions = champions.filter((champion) => {
        const matchesSearch = champion.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesRoles = selectedRoles.every(role => champion.tags.includes(role));
        return matchesSearch && (selectedRoles.length === 0 || matchesRoles);
    });

    const roles = ['Assassin', 'Marksman', 'Fighter', 'Tank', 'Mage', 'Support'];

    return (

        <>
        <div className='w-full flex justify-center'>
            <div className='flex items-center flex-col dark:bg-secondary bg-third_light my-2 p-2 rounded-[12px]'>
            <input
                className='w-[220px] dark:bg-white text-black rounded-md p-1.5 mb-2 text-sm outline-none border-text border-[1px] dark:border-none'
                placeholder={currLangData.champions.search}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
                    <div className='flex gap-[2px]'>
                    {roles.map((role, index) => (
                        <button key={index} className={`p-[5px] dark:hover:bg-third hover:bg-primary_light  rounded-md ${selectedRoles.includes(role) ? 'bg-primary_light dark:bg-primary' : ''}`} onClick={() => handleRoleClick(role)}>
                            <img src={`/roles/${role}.png`} className='w-[25px]' alt={role}></img>
                        </button>
                    ))}

                    </div>
            </div>
        </div>

        <div className='flex flex-wrap justify-center gap-[20px] text-[15px] mx-1'>
            {filteredChampions.map(champion => (
                <Link to={`../champion/${champion.id}`} className='champImg w-[175px] h-[275px] overflow-hidden position: relative' key={champion.id}>
                    <img className='w-[205px] h-[305px] scale-[1.20] hover:scale-[1.1] hover:ease-[cubic-bezier(0.075,0.82,0.165,1)] duration-[0.3s]' alt={champion.id} src={`https://ddragon.leagueoflegends.com/cdn/img/champion/loading/${champion.id}_0.jpg`}/>
                    <span className='text-white champNameHover position: absolute bottom-0 w-full flex items-center uppercase justify-center italic text-[16px] font-semibold bg-[rgb(0,0,0,0.8)] h-[45px] tracking-[2px]'>{champion.name}</span>
                </Link>
            ))}
        </div>
        </>
    );
}
