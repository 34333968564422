import { Link, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";

export default function Buttons({ live, load } : any) {
  const { region, summoner, tagLine } = useParams();
  const navigate = useNavigate();
  const [activeOption, setActiveOption] = useState('');

  const {language: lang} = useLanguage();
  const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

  const options = useMemo(() => [
    { name: `${currLangData.summonerProfile.buttons.summary}`, path: '' },
    { name: `${currLangData.summonerProfile.buttons.maestry}`, path: 'maestry' },
    { name: `${currLangData.summonerProfile.buttons.compare}`, path: 'compare' },
    { name: `${currLangData.summonerProfile.buttons.live}`, path: 'live' },
  ], [currLangData]);

  useEffect(() => {
    const currentPath = window.location.pathname.split('/').pop();
    const matchedOption = options.find(option => option.path === currentPath);
    setActiveOption(matchedOption ? matchedOption.path : '');
  }, [setActiveOption, options]);

  const handleOptionClick = (optionPath : any) => {
    setActiveOption(optionPath);
    const urlPath = optionPath ? `/summoner/${region}/${summoner}/${tagLine}/${optionPath}` : `/summoner/${region}/${summoner}/${tagLine}`;
    navigate(urlPath);
  };

  return (
    <div className="w-full bg-white dark:bg-secondary flex justify-center">
      <div className="w-full lg:w-[1080px] gap-1 flex">
        {options.map(({ name, path }) => (
          <Link 
            to={`/summoner/${region}/${summoner}/${tagLine}/${path}`}
            onClick={() => handleOptionClick(path)}
            key={name}
            className={`relative px-4 md:px-8 py-1 rounded-t-md text-[11px] md:text-sm hover:dark:bg-primary hover:bg-primary_light text-black dark:text-white ${activeOption === path ? 'dark:bg-primary bg-primary_light' : ''}`}
          >
            {name}
            {!load && live.length !== 0 && name === `${currLangData.summonerProfile.buttons.live}` ? (
              <span className='bg-red-900 h-2 w-2 rounded-full absolute top-1 right-1 animate-pulse'></span>
            ) : null} 
          </Link>
        ))}
      </div>
    </div>
  );
}