import { useEffect, useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";

export default function RecentItem({ rec, onLinkClick, removeRecent } : any) {
    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        const favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
        const isFav = favorites.some((fav: any) => fav.summoner === rec.summoner && fav.tagLine === rec.tagLine);
        setIsFavorite(isFav);
    }, [rec.summoner, rec.tagLine]);

    const toggleFavorite = (e: any) => {
        e.preventDefault(); 
        let favorites = JSON.parse(localStorage.getItem('favorites') || '[]');

        if (isFavorite) {
            favorites = favorites.filter((fav: any) => !(fav.summoner === rec.summoner && fav.tagLine === rec.tagLine));
        } else {
            favorites.push({ region: rec.region, summoner: rec.summoner, tagLine: rec.tagLine });
        }

        localStorage.setItem('favorites', JSON.stringify(favorites));
        setIsFavorite(!isFavorite); 
    };

    return (
        <Link to={`/summoner/${rec.region}/${rec.summoner}/${rec.tagLine}`} className='flex items-center justify-between dark:hover:bg-secondary hover:bg-primary_light'>
            <div className='p-2 flex items-center justify-start' onClick={onLinkClick}>
                <div className='bg-pink text-white w-9 font-semibold p-1 rounded-md uppercase text-[10px] text-center mr-1'><span>{rec.region}</span></div>
                <span className='text-[14px] max-w-[85px] whitespace-nowrap text-ellipsis overflow-hidden'>{rec.summoner}</span>
                <span className='text-text text-[14px]'>#{rec.tagLine}</span>
            </div>
            <div className='flex items-center'>
                {isFavorite ? (
                    <AiFillStar className="w-5 h-5 fill-pink" onClick={toggleFavorite}/>
                ) : (
                    <AiOutlineStar className="w-5 h-5 fill-text_darker hover:fill-pink" onClick={toggleFavorite}/>
                )}
                <CgClose className='m-2 hover:scale-[1.2] text-text hover:text-pink w-5 h-5' onClick={(e) => {removeRecent(rec.summoner, rec.tagLine); e.preventDefault();}}/>
            </div>
        </Link>
    );
}
