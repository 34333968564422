import { useState, useEffect } from 'react';
import axios from 'axios';
import { useVersion } from "../contexts/VersionContext"; 

const useRunes = () => {
  const { version } = useVersion(); 
  const [runeData, setRuneData] = useState<any>([]);

  useEffect(() => {
    const fetchRuneData = async () => {
      if (!version) return;
      try {
        const response = await axios.get(`https://ddragon.leagueoflegends.com/cdn/${version}/data/en_US/runesReforged.json`);
        setRuneData(response.data);
      } catch (error) {
        console.error('Failed to fetch rune data:', error);
      }
    };

    fetchRuneData();
  }, [version]); 

  const getRuneStyleImageUrl = (runeId:number) => {
    let runeStyle = runeData.find((path:any) => path.id === runeId);
    if (runeStyle) {
      return `https://ddragon.leagueoflegends.com/cdn/img/${runeStyle.icon}`;
    } else {
      for (let path of runeData) {
        for (let slot of path.slots) {
          let rune = slot.runes.find((rune:any) => rune.id === runeId);
          if (rune) {
            return `https://ddragon.leagueoflegends.com/cdn/img/${rune.icon}`;
          }
        }
      }
    }
    return '/noicon.jpg';
  };

  return { getRuneStyleImageUrl };
};

export default useRunes;