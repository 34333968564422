import { useEffect, useState } from "react";

const spells = ['P', 'Q', 'W', 'E', 'R'];

export default function ChampionVideo({ champion, version, currLangData } : any) {
  const [videoUrls, setVideoUrls] = useState<any>([]);
  const [videoIndex, setVideoIndex] = useState(0);
  const [selectedSpell, setSelectedSpell] = useState({ name: '', description: '' });
  const [videoContainerHeight, setVideoContainerHeight] = useState('553px');
  const [videoError, setVideoError] = useState(false);
  const handleVideoChange = (index : number) => {
    setVideoIndex(index);
    setVideoError(false);
  };

  useEffect(() => {
    const updateVideoContainerHeight = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 300 && screenWidth <= 768) {
        const height = Math.round(273 + ((553 - 275) * ((screenWidth - 400) / (768 - 400))));
        setVideoContainerHeight(`${height}px`);
      } else if (screenWidth > 768) {
        setVideoContainerHeight('553px');
      }
    };

    updateVideoContainerHeight();
    window.addEventListener('resize', updateVideoContainerHeight);

    return () => window.removeEventListener('resize', updateVideoContainerHeight);
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      if (champion) {
        const championId = champion.key.toString().padStart(4, '0');
        const urls = spells.map(spell => `https://d28xe8vt774jo5.cloudfront.net/champion-abilities/${championId}/ability_${championId}_${spell}1.mp4`);

        const checkUrls = await Promise.all(urls.map(async (url) => {
          try {
            const response = await fetch(url);
            if (!response.ok) return '';
            return url;
          } catch (error) {
            return '';
          }
        }))
        
        setVideoUrls(checkUrls);
        setSelectedSpell({
          name: champion.passive.name,
          description: champion.passive.description
        });
      }
    };

    fetchVideos();
  }, [champion]);

  useEffect(() => {
    if (videoUrls.length > 0) {
      if (videoUrls[videoIndex] === '') {
        setVideoError(true);
      } else {
        setVideoError(false);
        const video = document.getElementById('videoElement') as HTMLVideoElement;
        if (video) {
          video.load();
          // video.play();
        }
      }
    }
    if (videoIndex === 0) {
      setSelectedSpell({ name: champion.passive.name, description: champion.passive.description });
    } else {
      const spell = champion.spells[videoIndex - 1];
      setSelectedSpell({ name: spell.name, description: spell.description });
    }
  }, [videoIndex, videoUrls, champion.passive, champion.spells]);

  return (
    <div className="w-full rt:h-[600px] pt-[30px] flex justify-center rt:flex-row flex-col-reverse h-auto items-center position: relative gap-[10px]">

        <div className={`absolute left-[25px] top-[300px] h-[100px] w-px border border-current hidden md:block dark:text-white`}>
          <div className={`absolute rotate-[-90deg] left-[-73px] top-[-110px] w-[150px] text-[15px] uppercase tracking-[8px]`}>{currLangData.champion.abs}</div>
        </div>

      <div className="flex flex-col gap-10 justify-center mx-4">

        <div className="flex rt:justify-start gap-[20px] rt:gap-[50px] justify-center">
          <button className={`overflow-hidden rounded-md ${videoIndex === 0 ? 'shadow-[0_0_10px_#85417C]' : ''}`} onClick={() => handleVideoChange(0)}>
            <img className="hover:scale-[1.1] transition-all" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/passive/${champion.passive.image.full}`} alt={champion.passive.name}/>
          </button>

          {champion.spells.map((spell: any, index: number) => (
            <button className={`overflow-hidden rounded-md ${videoIndex === index + 1 ? 'shadow-[0_0_10px_#85417C]' : ''}`} key={index} onClick={() => handleVideoChange(index + 1)}>
              <img className="hover:scale-[1.1] transition-all" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/spell/${spell.image.full}`} alt={spell.name}/>
            </button>
          ))}
        </div>

        <div className="flex flex-col">
          <span className="text-[20px] font-semibold text-black dark:text-white">{selectedSpell.name} <span className="text-[12px] text-text">{videoIndex === 0 ? `${currLangData.champion.passive}` : `${spells[videoIndex]}`}</span></span>
          <span dangerouslySetInnerHTML={{ __html: selectedSpell.description }} className="max-w-[700px] rt:min-w-[700px]  max-h-[400px] sm:max-h-[300px] pb-10 md:pb-0 overflow-hidden md:h-[200px] rt:h-[200px] text-[15px] text-text font-normal"></span>
        </div>

      </div>


      <div className="w-full md:h-[560px] md:w-[calc(750px+2vw)] h-[522px] rt:w-[600px] rt:ml-0 pb-[30px] rt:pb-0 flex items-center justify-center" style={{ height: videoContainerHeight }}>
          {!videoError ? (
            <video id="videoElement" playsInline autoPlay muted loop>
              <source src={videoUrls[videoIndex]} type="video/mp4" />
            </video>
          ) : (
            <div className="position: relative flex justify-center">
            <img className="" src={'/noAbility.jpg'} alt={'no video'}/>
            <span className="position: absolute top-1/2 w-full text-center font-bold text-xl text-white">{currLangData.champion.noVid}</span>
            </div>
          )}
      </div>

    </div>
  );
}
