import { useCookieConsent } from "../contexts/CookieContext";
import { useState, useEffect } from "react";
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";

export default function CookiePopup() {
  const { consentGiven, setConsentGiven } = useCookieConsent();
  const [showThankYou, setShowThankYou] = useState(false);

  const {language: lang} = useLanguage();
  const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

  useEffect(() => {
    let timer:any;
    if (showThankYou) {
      timer = setTimeout(() => {
        setShowThankYou(false); 
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [showThankYou]);

  const handleAccept = () => {
    setConsentGiven(true);
    setShowThankYou(true); 
  };

  if (consentGiven && !showThankYou) return null;

  return (
    showThankYou ? (
    <div className="fixed bottom-2 right-2 bg-white p-5 rounded-md shadow-md fade-out w-[250px] h-[130px] flex justify-center items-center z-50"> 
        <p className="text-sm">{currLangData.cookie.thx}</p>
    </div>
      ) : (
        <div className="fixed bottom-2 right-2 bg-white p-4 rounded-md shadow-md w-[250px] h-[130px] flex flex-col justify-between z-50"> 
          <p className="text-sm text-center">{currLangData.cookie.text}</p>
          <div className="flex justify-evenly">
            <button className="bg-pink py-1 px-2 rounded-md text-white text-sm" onClick={handleAccept}>{currLangData.cookie.acc}</button>
            <button className="bg-pink py-1 px-2 rounded-md text-white text-sm" onClick={() => setConsentGiven(false)}>{currLangData.cookie.dec}</button>
          </div>
        </div>
      )
  );
}
