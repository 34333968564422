export function mapType(mapid : number){
    switch(mapid){
      default:    return "Loading"             ;
      case 0:     return "Custom"             ;
      case 2:     return "Normal blind"       ;
      case 6:     return "Ranked solo/duo"    ;
      case 11:    return "Custom"             ;
      case 32:    return "Co-op vs AI"        ;
      case 33:    return "Co-op vs AI"        ;
      case 65:    return "ARAM"               ;
      case 76:    return "Ultra Rapid Fire"   ;
      case 100:   return "ARAM"               ;
      case 400:   return "Normal draft"       ;
      case 420:   return "Ranked solo/duo"    ;
      case 430 :  return "Normal"             ;
      case 440:   return "Ranked flex"        ;
      case 450:   return "ARAM"               ;
      case 700:   return "Clash"              ;
      case 720:   return "ARAM Clash"         ;
      case 830:   return "Co-op vs AI"        ;
      case 840:   return "Co-op vs AI"        ;
      case 850:   return "Co-op vs AI"        ;
      case 900:   return "AR Ultra Rapid Fire";
      case 1020:  return "One for All"        ;
      case 1300:  return "Nexus blitz"        ;
      case 1400:  return "Ultimate spellbook" ;
      case 1700:  return "Arena"              ;
      case 1710:  return "Arena"              ;
      case 1900:  return "Ultra Rapid Fire"   ;
      case 2010:  return "Tutorial"           ;
      case 2000:  return "Tutorial"           ;
      case 2020:  return "Tutorial"           ;
    };
  };

  export function spell(spellid : number){
    switch(spellid){
      default:      return "NA" 
      case 2202:    return "SummonerCherryFlash"             ;
      case 14:      return "SummonerDot"                     ;
      case 21:      return "SummonerBarrier"                 ;
      case 4:       return "SummonerFlash"                   ;
      case 1:       return "SummonerBoost"                   ;
      case 2201:    return "SummonerCherryHold"              ;
      case 3:       return "SummonerExhaust"                 ;
      case 6:       return "SummonerHaste"                   ;
      case 7:       return "SummonerHeal"                    ;
      case 13:      return "SummonerMana"                    ;
      case 11:      return "SummonerSmite"                   ;
      case 30:      return "SummonerPoroRecall"              ;
      case 31:      return "SummonerPoroThrow"               ;
      case 32:      return "SummonerSnowball"                ;
      case 39:      return "SummonerSnowURFSnowball_Mark"    ;
      case 12:      return "SummonerTeleport"                ;
      case 54:      return "Summoner_UltBookPlaceholder"     ;
      case 55:      return "Summoner_UltBookSmitePlaceholder";
    };
  };
