import { useState } from "react";
import Difficulty from "../../components/Difficulty";

export default function ChampionOverview({ champion, currLangData }:any) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="w-full flex justify-center">
      <div className={`w-[calc(100%-60px)] rt:w-[1080px] border-x-[1px] border-b-[1px] border-text flex flex-col rt:flex-row h-fit items-center`}>
        
        <div className="rt:w-1/2 flex justify-evenly items-start gap-2 rt:p-[30px] p-[30px] w-full">
            
          <div className="w-[90px] h-[100px] flex flex-col items-center justify-between">
            <img src={`/roles/${champion.tags[0]}.png`} alt="Role Icon" className="w-[35px] h-[35px]" />
            <div className="flex flex-col items-center text-center uppercase">
              <span className="text-sm text-black dark:text-white">{currLangData.champion.role}</span>
              <span className="text-sm text-text">{currLangData.roles[champion.tags[0].toUpperCase()]}</span>
            </div>
          </div>
          <div className="h-[100px] flex">
            <Difficulty scale={champion.info.difficulty} currLangData={currLangData}/>
          </div>
        </div>

        <span className={`h-[1px] w-[calc(100%-60px)] borderHor rt:borderVer rt:w-[1px] rt:h-[150px]`}></span>

        <div className={`rt:w-1/2 rt:p-[30px] p-[30px] pt-[10px] overflow-hidden h-auto`}>
          {isExpanded ? (
            <span className="text-text block">{champion.lore}</span>
          ) : (
            <span className="text-text block">{champion.blurb}</span>
          )}
          <button onClick={toggleExpansion} className="text-pink hover:text-pink_light text-sm mt-2">
            {isExpanded ? `${currLangData.champion.readLess}` : `${currLangData.champion.readMore}`}
          </button>
        </div>
      </div>
    </div>
  );
}
