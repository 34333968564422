import { createContext, useContext, useState } from 'react';

const ExpandContext = createContext<any>(null);

export const useExpand = () => useContext(ExpandContext);

export const ExpandProvider = ({ children }:any) => {
    const [expand, setExpand] = useState(localStorage.getItem('expand') === 'true');

    const toggleExpand = (value:any) => {
        localStorage.setItem('expand', value);
        setExpand(value);
    };

    return (
        <ExpandContext.Provider value={{ expand, toggleExpand }}>
            {children}
        </ExpandContext.Provider>
    );
};