import { Link } from "react-router-dom"
import { FaGithub } from "react-icons/fa";

export default function Changelog(){

    const changes = {

        '04.04.2024' : [
          {
            date: '04.04.2024',
            author: 'Abstaxcv',
            state: 'Update',
            title: 'Light theme',
            description: 'Updated css properties, added light mode support for summoner summary and maestry page',
            commitLink: 'https://github.com/Raflexyo/raftech-league/commit/50ba4b3a4ec686fe187b34876a31ca7a8fc46f2d'
        },{
                date: '04.04.2024',
                author: 'Abstaxcv',
                state: 'Update',
                title: 'Updated changelog',
                description: 'Updated changelog, fixed minor isues with matches component',
                commitLink: 'https://github.com/Raflexyo/raftech-league/commit/37bd14e256bbb31a24ee1daced1b5fdc7c902859'
            },{
                date: '04.04.2024',
                author: 'Abstaxcv',
                state: 'Added',
                title: 'Desription on hover',
                description: 'Added description of items on hover, fixed issues with items component',
                commitLink: 'https://github.com/Raflexyo/raftech-league/commit/04fa241537f9d26317bc6ddd5ea728d36c40f380'
            },            {
                date: '04.04.2024',
                author: 'Abstaxcv',
                state: 'Added',
                title: 'Overview section',
                description: 'Added complete overview section (summoners, kda, champion played, items, bans, etc.).',
                commitLink: 'https://github.com/Raflexyo/raftech-league/commit/d15cdf8f7852df2c1c08e2223e302c2c4bf7ff38'
            },
            {
                date: '04.04.2024',
                author: 'Abstaxcv',
                state: 'Added',
                title: 'Additional info tabs',
                description: 'Added tabs (overview, build, details) to each match',
                commitLink: 'https://github.com/Raflexyo/raftech-league/commit/78e0e1c8c4128fcb46e27b1252acba84751b304c'
            }],



            '03.04.2024' : [

                {
                    date: '03.04.2024',
                    author: 'Abstaxcv',
                    state: 'Adjust',
                    title: 'Scroll content',
                    description: 'Adjusted summoner badges.',
                    commitLink: 'https://github.com/Raflexyo/raftech-league/commit/33c58ca84f3f4a2f01462c995fcd30471be406a3'
                  },              {
                    date: '03.04.2024',
                    author: 'Abstaxcv',
                    state: 'Added',
                    title: 'Game stats',
                    description: 'Added summoner badges and game stats (components of summoner summary match display).',
                    commitLink: 'https://github.com/Raflexyo/raftech-league/commit/8ae93547a82cb627da3aee409e5c2e885573df96'
                  },              {
                    date: '03.04.2024',
                    author: 'Abstaxcv',
                    state: 'Adjust',
                    title: 'Functions',
                    description: 'Adjusted fucntions responsible for rendering images, separated them into separate components, added changelog, added content to summoner summary.',
                    commitLink: 'https://github.com/Raflexyo/raftech-league/commit/56696dbdd085bf885244cf3fadd5ece2b96302ad'
                  },              {
                    date: '03.04.2024',
                    author: 'Abstaxcv',
                    state: 'Added',
                    title: 'Summoner summary',
                    description: 'Added basic layout of summonr summary page (main page of summoner profile), updated server code.',
                    commitLink: 'https://github.com/Raflexyo/raftech-league/commit/8b74dde610b7a3373dd6550d4ac8cd1029da8bd0'
                  },

                {
                date: '03.04.2024',
                author: 'Abstaxcv',
                state: 'Update',
                title: 'Champion suggestion',
                description: 'Updated search component to show champions and summoners when searching in searchbar, fixed minor UI bugs.',
                commitLink: 'https://github.com/Raflexyo/raftech-league/commit/4d77b4f613a70668705d1f2ed3342fa3828dfd6a'
              }],


              '30.03.2024' : [
                {
                    author: 'Abstaxcv',
                    state: 'Update',
                    title: 'Summoner live page ',
                    description: 'Updated code responsible for displaying summoners, now function tries to optimize summoner placement based on champion position.',
                    commitLink: 'https://github.com/Raflexyo/raftech-league/commit/44b42432fcb2e7a0604d52c3cd04a0309915e231'
                  },
                {
                  author: 'Abstaxcv',
                  state: 'Added',
                  title: 'Summoner live',
                  description: 'Added summoner live page, page allows user to spectate summoner in game.',
                  commitLink: 'https://github.com/Raflexyo/raftech-league/commit/0884e1b1d45720240f5dc60d7e93467ae8f49f99'
                }],
    
              '31.03.2024' : [
                {
                  author: 'Abstaxcv',
                  state: 'Added',
                  title: 'Backend',
                  description: 'Added backend and updated Live game page to redirect user to summoner profile page.',
                  commitLink: 'https://github.com/Raflexyo/raftech-league/commit/fd5aa8f67d09f7a992cfb4501de3da7745f72fee'
                },
                {
                  author: 'Abstaxcv',
                  state: 'Added',
                  title: 'Live games page',
                  description: 'Added page that allows user to see suggestes live summoner games, fixed issues with summoner live page, added more functions.',
                  commitLink: 'https://github.com/Raflexyo/raftech-league/commit/36e256160b0f6d11f9fd45f3a5fcb6bb0ccb0bbc'
                }],
    
               '02.04.2024' : [
                {
                    author: 'Abstaxcv',
                    state: 'Added',
                    title: 'Summoner statistics',
                    description: 'Added summoner statistics page, updated search page, fixed minor bugs.',
                    commitLink: 'https://github.com/Raflexyo/raftech-league/commit/8bbbcfc8e8fecd44651de76aa76ff4f64e5c529f'
                  },                {
                    author: 'Abstaxcv',
                    state: 'Updated',
                    title: 'Search component',
                    description: 'Updated styling and functionality of search component, cleaned code in search component, added recent searches.',
                    commitLink: 'https://github.com/Raflexyo/raftech-league/commit/909c9e21f8071618b9861a842fe1a22351ced2e5'
                  },
                {
                  author: 'Abstaxcv',
                  state: 'Added',
                  title: 'Search update is here!',
                  description: 'Added remove from search button, fixed styling.',
                  commitLink: 'https://github.com/Raflexyo/raftech-league/commit/46a08b2503dc8476fb86dd96b2f70422d61a5cb9'
                },                {
                    author: 'Abstaxcv',
                    state: 'Added',
                    title: 'New RiotID system',
                    description: 'Updated server code to handle new RiotID system, updated UI of summonerLive (added banned champions), added function to save summoner to favorites.',
                    commitLink: 'https://github.com/Raflexyo/raftech-league/commit/31a62a33a3bb79dca11b60f0675c5e41d2fb9ec7'
                  }

            ],

            '29.03.2024' : [
            {
                author: 'Abstaxcv',
                state: 'Added',
                title: 'Summoner maestry',
                description: 'Added summoner maestry page, page allows user to see maestry score on given number of champions.',
                commitLink: 'https://github.com/Raflexyo/raftech-league/commit/11d131745c6c7ea61a597c5d5df86aa5b11e4671'
            }],

            '27.03.2024' : [
            {
                author: 'Abstaxcv',
                state: 'Added',
                title: 'Summoner profile',
                description: 'Added basic summoner page layout, fixed minor issues with champions page.',
                commitLink: 'https://github.com/Raflexyo/raftech-league/commit/8d6eee37491c4bfd75efb4801a8bc5ec6017cb8e'
            }],

            '26.03.2024' : [
            {
                    author: 'Abstaxcv',
                    state: 'Added',
                    title: 'Champions are here !',
                    description: 'Added champions and champion page, champion page allows user to browse thru every champion in game, champion page displays important information about selected champion, page also includes skins, tips, abilities, and stats.',
                    commitLink: 'https://github.com/Raflexyo/raftech-league/commit/80c18d5fe798f7ae84ace1f9a4ec4a0809490637'
            },
            {
              author: 'Abstaxcv',
              state: 'Start',
              title: 'Beginning of a journey',
              description: 'Added page layout with basic components.',
              commitLink: 'https://github.com/Raflexyo/raftech-league/commit/98cb9c897ef0903bc2638a7529af9727aac56b19'
            }],


    }


    return(
        <div className="w-full flex justify-center">
            <div className="w-[1080px]">
                <div>
                {Object.entries(changes).map(([date, changes]) => (
                    <div key={date}>
                    <div className="flex flex-col pt-4 pb-4">
                        <span className="text-secondary text-base font-bold first:hidden">|</span>
                        <span className="text-secondary text-base font-bold">| <span className="dark:text-white text-black">{date}</span></span>
                    </div>
                    <div className="flex flex-col gap-1 p-1 overflow-hidden">
                        {changes.map((change, index) => (
                        <div className="border-2 border-secondary flex flex-row justify-between rounded-md h-[100px] relative" key={index}>
                            <div className="flex ">
                                <div className="flex flex-col justify-center items-center bg-secondary pl-2 pr-2">
                                    <img src={'/profile.jpg'} className="rounded-full w-10 h-10 mb-2" alt={'profile icon'}/>
                                    <span className="text-[10px] text-text leading-3">Author:</span>
                                    <span className="text-xs text-white leading-3">{change.author}</span>
                                </div>
                                <div className="flex flex-col justify-center pl-1 pr-1 pt-1 pb-1 gap-1">
                                    <span className="text-text_white dark:text-white text-xs p-2 pl-0 w-fit"><span className="bg-pink p-1 rounded-md text-white mr-1 text-xs">{change.state}</span>{change.title}</span>
                                    <span className="dark:text-text text-text_white text-xs md:max-w-[500px] max-w-full">{change.description}</span>
                                </div>
                            </div>
                                <Link className="flex items-center bg-secondary absolute top-0 right-0 p-1 rounded-bl-md text-[#e6edf3] hover:text-pink" to={change.commitLink}>
                                    <FaGithub className="w-6 h-6"/>
                                </Link>
                        </div>
                        ))}
                    </div>

                    </div>
                ))}
                </div>
            </div>
        </div>
    )
}