import Skeleton from "react-loading-skeleton";

export default function StatisticRow({ label, value, loading, loadingCompare, valueComp, preLoad } : any) {

    const numValue = parseFloat(value);
    const numValueComp = parseFloat(valueComp);

    const valueStyle = !loading && !loadingCompare && numValue >= numValueComp ? "dark:text-white text-black" : "text-text";
    const valueCompStyle = !loading && !loadingCompare && numValueComp >= numValue ? "dark:text-white text-black" : "text-text";

    return (
      <div className="flex justify-between items-center border-t-[1px] dark:border-[rgb(255,255,255,0.1)] border-[rgb(0,0,0,0.2)] py-1 px-2 dark:hover:bg-[rgb(255,255,255,0.1)] hover:bg-[rgb(0,0,0,0.2)]">
            
            <span className={`hidden dark:flex ${valueStyle} text-[18px] w-[120px] justify-start text-ellipsis overflow-hidden whitespace-nowrap`}>
            {!loading ? (
                <span>{String(value)}</span>
            ) : (
                <Skeleton borderRadius={0} baseColor="#282830" highlightColor="#31313C" containerClassName="flex-1" />
            )}
            </span>
        
            <span className={`flex dark:hidden ${valueStyle} text-[18px] w-[120px] text-ellipsis overflow-hidden whitespace-nowrap`}>
            {!loading ? (
                <span>{String(value)}</span>
            ) : (
                <Skeleton borderRadius={0} baseColor="#FFFFFF" highlightColor="#EBEEF1" containerClassName="flex-1" />
            )}
            </span>

        <span className="text-center text-black dark:text-white text-[14px] font-medium label">{label}</span>
        
            <span className={`hidden dark:flex ${valueCompStyle} text-[18px] w-[120px] justify-end text-ellipsis overflow-hidden whitespace-nowrap`}>    
            {preLoad ? (null):(
            !loadingCompare ? (
                <span>{String(valueComp)}</span>
            ) : (
                <Skeleton borderRadius={0} baseColor="#282830" highlightColor="#31313C" containerClassName="flex-1" />
            )
            )}
            </span>
        
            <span className={`flex dark:hidden ${valueCompStyle} text-[18px] w-[120px] justify-end text-ellipsis overflow-hidden whitespace-nowrap`}>
            {preLoad ? (null):(
            !loadingCompare ? (
                <span>{String(valueComp)}</span>
            ) : (
                <Skeleton borderRadius={0} baseColor="#FFFFFF" highlightColor="#EBEEF1" containerClassName="flex-1" />
            )
            )}
            </span>
    
    </div>
    );
}
