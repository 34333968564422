import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Link } from 'react-router-dom';
import { useVersion } from '../../contexts/VersionContext';
import PositionIndicator from '../../components/PositionIndicator';
import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";


export default function InformationWidget({numDisplayed,champions, summoner}:any){

    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;


    const { version } = useVersion();
    const positionStats:any = { MIDDLE: { count: 0 }, TOP: { count: 0 }, UTILITY: { count: 0 }, JUNGLE: { count: 0 }, BOTTOM: { count: 0 } };
    const champStats:any = {};

    summoner?.map((match : any)=>{
        const champPlayed = match?.participant.championId;
        const positionPlayed = match?.participant.teamPosition;
        if (!champStats[champPlayed]) {
            champStats[champPlayed] = {
              champCount: 1,
              id: match?.participant.championId,
              position: match?.participant.teamPosition,
              kills: match?.participant.kills,
              deaths: match?.participant.deaths,
              assists: match?.participant.assists,
              gamesPlayed: 1,
              gamesWon: match?.participant.win ? 1 : 0,
              gamesLost: match?.participant.win ? 0 : 1
            };
        }else {
            champStats[champPlayed].champCount++;
            champStats[champPlayed].kills += match?.participant.kills;
            champStats[champPlayed].deaths += match?.participant.deaths;
            champStats[champPlayed].assists += match?.participant.assists;
            champStats[champPlayed].gamesPlayed++;
            if (match?.participant.win) {
              champStats[champPlayed].gamesWon++;
            } else {
              champStats[champPlayed].gamesLost++;
            };
          }
          if (positionPlayed in positionStats) {
            positionStats[positionPlayed].count++;
          }
          return null;
        });
        
        const positionTop:any = (positionStats.TOP.count/20)*100;
        const positionAdc:any = (positionStats.BOTTOM.count/20)*100;
        const positionSup:any = (positionStats.UTILITY.count/20)*100;
        const positionJng:any = (positionStats.JUNGLE.count/20)*100;
        const positionMid:any = (positionStats.MIDDLE.count/20)*100;

        const totalKills:any = Object.values(champStats).reduce((total, champ:any) => total + champ.kills, 0);
        const totalDeaths:any = Object.values(champStats).reduce((total, champ:any) => total + champ.deaths, 0);
        const totalAssists:any = Object.values(champStats).reduce((total, champ:any) => total + champ.assists, 0);

        const totalWins:any = Object.values(champStats).reduce((total, champ:any) => total + champ.gamesWon, 0);
        const totalLosses:any = Object.values(champStats).reduce((total, champ:any) => total + champ.gamesLost, 0);

        const totalWR:any = ((totalWins/(totalWins+totalLosses))*100).toFixed(0);

        const sortedChampStats = Object.entries(champStats)
            .map(([champ, stats]:any) => ({ champ, ...stats }))
            .sort((a, b) => b.gamesPlayed - a.gamesPlayed);
                
    return(
        <div className=" w-full lg:w-[332px] dark:bg-secondary bg-third_light h-[165px] lg:rounded-md">
            <div className="borderHor p-2 pb-0">
                <span className="dark:text-white text-black text-sm">{currLangData.widgets.inf} <span className="text-[10px]">({currLangData.widgets.infSub1} {numDisplayed} {currLangData.widgets.infSub2})</span></span>
            </div>
            <div className='flex justify-between h-[130px] p-2'>
                <ul className='h-full flex flex-col justify-between'>
                {sortedChampStats.slice(0,3).map((champ, y)=>(
                    <li className="flex items-center" key={y}>
                    <Link target="_blank" className="w-7 h-7 overflow-hidden rounded-md mr-1" to={`/champion/${champions[champ.id]?.name}`}><img className='scale-[1.1]' src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[champ.id]?.name}.png`} alt={champions[champ.id]?.name}/></Link> 
                    <div className="text-xs">
                        <span className='dark:text-white text-black'>{(champ.gamesWon/(champ.gamesWon+champ.gamesLost)*100).toFixed(0)}% </span>
                        <span className='flex text-text'>{champ.gamesWon}{currLangData.compare.w} {champ.gamesLost}{currLangData.compare.l}</span>
                    </div>
                    </li>
                    ))}
                </ul>
                <div className="flex flex-col justify-between">
                    <div className="flex flex-col leading-5 text-base">
                    {isNaN(totalKills) ? 
                        (<><span className='dark:text-white text-black font-bold'>0 / <span className="text-val">0</span> / 0</span><span className='text-text text-xs'>0.00:1 KDA</span></>):(<><span className='dark:text-white text-black font-bold'>{totalKills/20} / <span className="text-val">{totalDeaths/20}</span> / {totalAssists/20}</span><span className='text-text text-xs'>{((totalKills+totalAssists)/totalDeaths).toFixed(2)}:1 {currLangData.compare.kda}</span></>)}
                    </div>
                    <div className="position: relative">
                        <ul className="flex justify-evenly items-end text-center w-[120px]">
                        <PositionIndicator 
                        positionName="toplane" 
                        imgSrc="/positions/top.svg" 
                        stats={positionStats.TOP} 
                        barHeight={positionTop} 
                        />
                        <PositionIndicator 
                        positionName="jungle" 
                        imgSrc="/positions/jng.svg" 
                        stats={positionStats.JUNGLE} 
                        barHeight={positionJng} 
                        />
                        <PositionIndicator 
                        positionName="midlane" 
                        imgSrc="/positions/mid.svg" 
                        stats={positionStats.MIDDLE} 
                        barHeight={positionMid} 
                        />
                        <PositionIndicator 
                        positionName="adc" 
                        imgSrc="/positions/adc.svg" 
                        stats={positionStats.BOTTOM} 
                        barHeight={positionAdc} 
                        />
                        <PositionIndicator 
                        positionName="support" 
                        imgSrc="/positions/sup.svg" 
                        stats={positionStats.UTILITY} 
                        barHeight={positionSup} 
                        />
                        </ul>
                    </div>
                </div>

                <div className="position: relative flex items-center">
                    <CircularProgressbar
                        value={totalWR}
                        className="w-20 h-20"
                        strokeWidth={15}
                        styles={buildStyles({
                        strokeLinecap: "butt"
                        })}/>
                    <div className="position: absolute top-[45px] left-[18px] flex flex-col text-center leading-3">
                    <span className="text-Victory font-bold text-[13px]">{totalWR+"%"}</span>
                    <span className="text-text w-11 text-[10px]">{totalWins+`${currLangData.compare.w} `+totalLosses+`${currLangData.compare.l}`}</span>
                    </div>
                </div>

            </div>
        </div>
    )
}