import { createContext, useContext, useState } from 'react';

const LayoutContext = createContext<any>(null);

export const useLayout = () => useContext(LayoutContext);

export const LayoutProvider = ({ children }:any) => {
    const [matchesFirst, setMatchesFirst] = useState(localStorage.getItem('matchesFirst') === 'true');

    const toggleMatchesFirst = (value:any) => {
        localStorage.setItem('matchesFirst', value);
        setMatchesFirst(value);
    };

    return (
        <LayoutContext.Provider value={{ matchesFirst, toggleMatchesFirst }}>
            {children}
        </LayoutContext.Provider>
    );
};