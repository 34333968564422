import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";
interface RegionListProps {
  onSelect: (division: string, tier: string) => void;
}
export default function DivisionList({ onSelect }: RegionListProps) {

  const {language: lang} = useLanguage();
  const currLangData: any = (languageData as any)[lang] || (languageData as any).en;


  const divisions = [
    { id: 'CHALLENGER', name: 'Challenger', tiers: ['I'] },
    { id: 'GRANDMASTER', name: 'Grandmaster', tiers: ['I'] },
    { id: 'MASTER', name: 'Master', tiers: ['I'] },
    { id: 'EMERALD', name: `${currLangData.ranks.emerald}`, tiers: ['I', 'II', 'III', 'IV'] },
    { id: 'DIAMOND', name: `${currLangData.ranks.diamond}`, tiers: ['I', 'II', 'III', 'IV'] },
    { id: 'PLATINUM', name: `${currLangData.ranks.platinum}`, tiers: ['I', 'II', 'III', 'IV'] },
    { id: 'GOLD', name: `${currLangData.ranks.gold}`, tiers: ['I', 'II', 'III', 'IV'] },
    { id: 'SILVER', name: `${currLangData.ranks.silver}`, tiers: ['I', 'II', 'III', 'IV'] },
    { id: 'BRONZE', name: `${currLangData.ranks.bronze}`, tiers: ['I', 'II', 'III', 'IV'] },
    { id: 'IRON', name: `${currLangData.ranks.iron}`, tiers: ['I', 'II', 'III', 'IV'] },
  ];

  return (
    <div className="max-h-[250px] overflow-y-auto position: absolute left-[218px] top-[37px] bg-white w-[150px] text-black rounded-md shadow-[0_8px_12px_rgba(0,0,0,0.19)] z-10">
      {divisions.map(division => (
        division.tiers.map(tier => (
          <button key={`${division.id}-${tier}`} onClick={() => onSelect(division.id, tier)} className="hover:bg-[#F7F7F9] flex w-full p-1 gap-2 text-[15px]">
            <img className="w-7 h-7" src={`/tiers/${division.id}.png`} alt={`${division.name} ${tier}`} />
            <span>
            {division.id === "CHALLENGER" || division.id === "GRANDMASTER" || division.id === "MASTER" ? 
                division.name : 
                `${division.name} ${tier}`}
            </span>
          </button>
        ))
      ))}
    </div>
  );
}