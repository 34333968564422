import { createContext, useContext, useState, useEffect } from 'react';

const CookieConsentContext = createContext<any>({
  consentGiven: false,
  setConsentGiven: () => {}
});

export const useCookieConsent = () => useContext(CookieConsentContext);

export const CookieConsentProvider = ({ children }:any) => {
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    setConsentGiven(consent === 'true');
  }, []);

  const handleSetConsent = (value:any) => {
    localStorage.setItem('cookieConsent', value.toString());
    setConsentGiven(value);
  };

  return (
    <CookieConsentContext.Provider value={{ consentGiven, setConsentGiven: handleSetConsent }}>
      {children}
    </CookieConsentContext.Provider>
  );
};
