export default function PositionIndicator({ positionName, imgSrc, stats, barHeight }:any) {
  return (
    <li className='flex flex-col items-center'>
      <div className="position: absolute top-[-50px] w-[18px] h-[50px] bg-[rgb(63,65,73,0.2)] rounded-t-md flex flex-col justify-end overflow-hidden items-center">
        <div className="bg-text position: relative w-[18px]" style={{ height: `${barHeight}%` }}></div>
        <div className="position: absolute font-bold text-xs text-white">{stats.count}</div>
      </div>
      <img src={imgSrc} alt={positionName} />
    </li>
  );
}
