export default function Difficulty({ scale, currLangData }:any) {
    const numericScale = Number(scale);

    const getOpacity = (index:number) => {
        if (numericScale < 3) {
            return index === 0 ? 1 : 0.3;
        } else if (numericScale < 7) {
            return index < 2 ? 1 : 0.3;
        } else {
            return 1;
        }
    };
    return (
        <div className="flex flex-col justify-between">
            <div className="flex h-[50px] relative w-20">
                {[...Array(3)].map((_, index) => (
                    <span key={index} className="bg-pink w-6 h-6 skew-y-[30deg] absolute" style={{ left: `${index * 30}px`, rotate: '-30deg', top: '0', opacity: getOpacity(index) }}></span>
                ))}
            </div>
            <div className="flex flex-col items-center">
                <span className="text-sm text-black dark:text-white uppercase">{currLangData?.champion.diff}</span>
                <span className="text-center uppercase text-sm text-text">{numericScale < 3 ? `${currLangData?.champion.easy}` : numericScale < 7 ? `${currLangData?.champion.medium}` : `${currLangData?.champion.hard}`}</span>
            </div>
        </div>
    );
}