import detectBrowser from "../cases/Browser";
import { useExpand } from "../contexts/ExpandContext";
import { useFontFamily } from "../contexts/FontContext";
import { useLanguage } from "../contexts/LanguageContext";
import { useLayout } from "../contexts/LayoutContext";
import languageData from "../json/languageData.json";

export default function UserSettings() {
    const { toggleMatchesFirst, matchesFirst } = useLayout();
    const {toggleExpand, expand} = useExpand();
    const {toggleLanguage, language: lang} = useLanguage();
    const { fontFamily, changeFontFamily } = useFontFamily();

    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

    const languages = [
        {name:`${currLangData.nav.settings.thirdsetting.option1}`, short: "pl"},
        {name:`${currLangData.nav.settings.thirdsetting.option2}`, short: "en"},
        {name:`${currLangData.nav.settings.thirdsetting.option3}`, short: "es"},
        {name:`${currLangData.nav.settings.thirdsetting.option4}`, short: "de"}
    ];

    const fonts = [
        {name: "Poppins, sans-serif", short: "Poppins"},
        {name: "Arial, sans-serif", short: "Arial"},
        {name: "Oswald, sans-serif", short:"Oswald"},
        {name: "Ubuntu, sans-serif", short: "Ubuntu"}
    ]
    
    return (
        <div className='position: absolute left-[-160px] top-[40px] bg-[rgb(59,91,147)] p-1 px-0 z-50 w-[250px] rounded-bl-md '>
                
                <span className="text-sm p-1 border-b-[1px] border-lol_light flex justify-center ">{currLangData.nav.settings.name}</span>

            <div className="p-1 border-b-[1px] border-lol_light">
                <span className="flex justify-center text-center text-xs pb-1 ">{currLangData.nav.settings.firstsetting.name}</span>
                <div className="flex justify-evenly text-xs px-1">
                    <button onClick={() => toggleMatchesFirst(true)} className={`px-1 hover:underline ${matchesFirst?('text-white'):('text-text')}`}>{currLangData.nav.settings.firstsetting.option1}</button>
                    <button onClick={() => toggleMatchesFirst(false)} className={`px-1 hover:underline ${!matchesFirst?('text-white'):('text-text')}`}>{currLangData.nav.settings.firstsetting.option2}</button>
                </div>
            </div>

            <div className="p-1 border-b-[1px] border-lol_light">
                <span className="flex items-center flex-col text-xs pb-1">{currLangData.nav.settings.secondsetting.name} <span className="text-[10px] ml-1"> ({currLangData.nav.settings.secondsetting.subtext})</span></span>
                <div className="flex justify-evenly text-xs px-1">
                    <button onClick={() => toggleExpand(true)} className={`px-1 hover:underline ${expand?('text-white'):('text-text')}`}>{currLangData.nav.settings.secondsetting.option1}</button>
                    <button onClick={() => toggleExpand(false)} className={`px-1 hover:underline ${!expand?('text-white'):('text-text')}`}>{currLangData.nav.settings.secondsetting.option2}</button>
                </div>
            </div>

            <div className="p-1 border-b-[1px] border-lol_light">
                <span className="flex justify-center text-xs pb-1">{currLangData.nav.settings.thirdsetting.name}</span>
                <div className="flex justify-evenly text-xs px-1">
                    <select onChange={(e) => toggleLanguage(e.target.value)} value={lang} className="text-black rounded-md">
                        {languages.map((language, index) => (
                            <option key={index} value={language.short} className={`hover:text-white text-black`}>
                                {language.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="p-1 border-b-[1px] border-lol_light">
                <span className="flex justify-center text-xs pb-1">{currLangData.nav.settings.fifthsetting.name}</span>
                <div className="flex justify-evenly text-xs px-1">
                    <select onChange={(e) => changeFontFamily(e.target.value)} value={fontFamily} className="text-black rounded-md">
                        {fonts.map((font, index) => (
                            <option key={index} value={font.name} className={`hover:text-white text-black`} style={{fontFamily: font.name}}>
                                {font.short}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="p-1 border-b-[1px] border-lol_light">
                <span className="flex justify-center text-xs pb-1">{currLangData.nav.settings.fourthsetting.name}</span>
                <div className="flex justify-evenly text-xs px-1">
                    <button className={`text-text hover:underline px-1`} disabled>{currLangData.nav.settings.fourthsetting.option1}</button>
                </div>
            </div>

            <div className="flex flex-col justify-center items-center mt-2">
                <span className="text-xs">{detectBrowser()}</span>
                <span className="text-xs">{currLangData.nav.settings.information.name} v1.01.0</span>
            </div>
        </div>
    )
}