import { Link } from "react-router-dom";
import { useVersion } from "../contexts/VersionContext";
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";
export default function SuggestedSearch({searchResults, onLinkClick, addSearchToRecent }:any){

    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;
  

    const summonersToShow = searchResults?.summoners?.slice(0, 5) || [];
    const championsToShow = searchResults?.champions?.slice(0, 5) || [];

    const { version } = useVersion();

    function handleLinkClick(e:any, region :any,gameName :any,tagLine :any) {
        onLinkClick();
        const newSearch = { summoner: gameName, tagLine: tagLine, region: region };
        addSearchToRecent(newSearch);
    }

return(
    <div className="text-white dark:text-text_dark bg-third_light dark:bg-third position: absolute top-[37px] left-[72px] shadow-[0_8px_12px_0_rgba(0,0,0,0.3)] z-20 rounded-b-md overflow-hidden overflow-y-auto max-h-[450px]">
        <div className="w-[250px]">
        {summonersToShow.length !== 0 ? (<div className="bg-primary_light dark:bg-secondary p-2 pt-1 pb-1 text-sm text-black dark:text-white">{currLangData.nav.settings.suggested.summs}</div>):(null)}
            {summonersToShow.map((summoner : any)=> (
                <Link onClick={(event) => handleLinkClick(event, summoner.urlRegion, summoner.gameName, summoner.tagLine)} key={summoner.puuid} to={`/summoner/${summoner.urlRegion}/${summoner.gameName}/${summoner.tagLine}`} className="flex p-2 hover:bg-primary_light dark:hover:bg-secondary">
                    <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${summoner.summonerIcon}.png`} alt={summoner.summonerIcon} className="w-[40px] h-[40px] rounded-md mr-2"/>
                    <div className="text-black dark:text-white text-sm">
                    <div className="flex"><span className="max-w-[100px] text-ellipsis whitespace-nowrap overflow-hidden">{summoner.gameName}</span><span className="text-text"> #{summoner.tagLine}</span></div>
                        <p className="text-text">{currLangData.level} {summoner.summonerLevel}</p>
                    </div>
                </Link>
            ))}
        </div>
        <div className="w-[250px]">
            {championsToShow.length !== 0 ? (<div className="bg-primary_light dark:bg-secondary p-2 pt-1 pb-1 text-sm text-black dark:text-white">{currLangData.nav.settings.suggested.champs}</div>):(null)}
            {championsToShow.map((champion : any)=> (
                <Link onClick={onLinkClick} key={champion.Name} to={`/champion/${champion.Name}`} className="flex p-2 hover:bg-primary_light dark:hover:bg-secondary">
                    <div className="w-[40px] h-[40px] rounded-md overflow-hidden mr-2"><img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.Name}.png`} alt={champion.Name} className="w-[40px] h-[40px] scale-[1.1]"/></div>
                    <div className="text-black dark:text-white text-sm">
                    <span className="w-[100px] text-ellipsis whitespace-nowrap overflow-hidden">{champion.champName}</span>
                    <p className="text-text">{currLangData.lines[champion.positionMain.replace(/\s+/g, '')]}{champion.positionSecond !== null ? ", "+currLangData.lines[champion.positionSecond.replace(/\s+/g, '')] : (null)}</p>
                    </div>
                </Link>
            ))}
        </div>
    </div>
)

}