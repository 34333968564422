import { Link } from "react-router-dom";
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";

export default function NavButtons() {
  const {language: lang} = useLanguage();
  const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

  const NavLink = ({ href, children } : {href : string, children: any}) => (
    <Link to={href} className="hover:border-b-[1px] dark:border-white border-text_light h-12 flex items-center">
      {children}
    </Link>
  );

  return (
    <div className="flex gap-2 h-12 text-text_light dark:text-white">
      <NavLink href="/champions">{currLangData.nav.navButtons.champions}</NavLink>
      <NavLink href="/leaderboard">{currLangData.nav.navButtons.leaderboard}</NavLink>
      <NavLink href="/live-games">{currLangData.nav.navButtons.liveGames}</NavLink>
    </div>
  );
}