import { Link } from "react-router-dom";
import { spell } from "../../cases/MapsSpells";
import useRunes from "../../cases/UseRunes";
import ItemDisplay from "../../components/ItemDisplay";

export default function ChampionInformation({summoner, index, version, champions}:any){

    const { getRuneStyleImageUrl } = useRunes();
    return(
        <>
            <div className="overflow-hidden rounded-[8px] w-[42px] h-[42px] lg:w-[50px] lg:h-[50px] position: relative bg-primary p-[2px] flex items-start justify-center">
                <Link to={`/champion/${champions[summoner[index]?.participant.championId]?.name}`} target="_blank" className="overflow-hidden rounded-md"><img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[summoner[index]?.participant.championId]?.name}.png`} alt={champions[summoner[index]?.participant.championId]?.name} className="scale-[1.1]"/></Link>
                    <span className="text-white bg-[rgb(28,28,31,0.8)] position: absolute top-0 left-0 rounded-br-md text-[12px] w-[25px] text-center font-semibold">{summoner[index]?.participant.champLevel}</span>
            </div>

            <div className="flex flex-col gap-[2px] justify-center">
                <img className="w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] ml-[2px] mr-[2px]" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/spell/${spell(summoner[index]?.participant.summoner1Id)}.png`} alt={summoner[index]?.participant.summoner1Id}/>
                <img className="w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] ml-[2px] mr-[2px]" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/spell/${spell(summoner[index]?.participant.summoner2Id)}.png`} alt={summoner[index]?.participant.summoner2Id}/>
            </div>

            {summoner[index]?.gameMode !== "CHERRY" ? (
            <div className="flex flex-col gap-[2px] justify-center">
                <img className="w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] bg-black" src={`${getRuneStyleImageUrl(summoner[index]?.participant.perks.styles[0].selections[0].perk)}`} alt={summoner[index]?.participant.perks.styles[0].selections[0].perk}/>
                <img className="w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] bg-black p-1" src={`${getRuneStyleImageUrl(summoner[index]?.participant.perks.styles[1].style)}`} alt={summoner[index]?.participant.perks.styles[1].style}/>
            </div>
            ):(
                <>
                <div className="flex flex-col gap-[2px] justify-center">
                {summoner[index]?.participant.playerAugment1 !== 0 ? (
                    <img className="w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] bg-black" src={`https://cdn5.xdx.gg/augment48/${summoner[index]?.participant.playerAugment1}.webp`} alt={summoner[index]?.participant.playerAugment1}/>
                ) : (
                    <div className="w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] bg-[rgb(0,0,0,0.2)]"></div>
                )}
                {summoner[index]?.participant.playerAugment3 !== 0 ? (
                    <img className="w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] bg-black" src={`https://cdn5.xdx.gg/augment48/${summoner[index]?.participant.playerAugment3}.webp`} alt={summoner[index]?.participant.playerAugment3}/>
                ) : (
                    <div className="w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] bg-[rgb(0,0,0,0.2)]"></div>
                )}
                </div>
                <div className="flex flex-col gap-[2px] justify-center ml-[2px]">
                {summoner[index]?.participant.playerAugment2 !== 0 ? (
                    <img className="w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] bg-black" src={`https://cdn5.xdx.gg/augment48/${summoner[index]?.participant.playerAugment2}.webp`} alt={summoner[index]?.participant.playerAugment2}/>
                ) : (
                    <div className="w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] bg-[rgb(0,0,0,0.2)]"></div>
                )}
                {summoner[index]?.participant.playerAugment4 !== 0 ? (
                    <img className="w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] bg-black" src={`https://cdn5.xdx.gg/augment48/${summoner[index]?.participant.playerAugment4}.webp`} alt={summoner[index]?.participant.playerAugment4}/>
                ) : (
                    <div className="w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] bg-[rgb(0,0,0,0.2)]"></div>
                )}
                </div>
            
                </>
            )} 

            <ItemDisplay
                marginLeft="5px"
                items={[
                    { id: summoner[index]?.participant.item0, order: "order-1" },
                    { id: summoner[index]?.participant.item1, order: "order-2" },
                    { id: summoner[index]?.participant.item2, order: "order-3" },
                    { id: summoner[index]?.participant.item6, order: "order-4", rounded: 'rounded-full' },  //ward
                    { id: summoner[index]?.participant.item3, order: "order-5" },
                    { id: summoner[index]?.participant.item4, order: "order-6" },
                    { id: summoner[index]?.participant.item5, order: "order-7" },
                ]}
                version={version}
            />
        </>
    )
}