import { gameDuration, timeElapsedString } from "../../cases/GameTimer";
import { mapType } from "../../cases/MapsSpells";
import {RiArrowDownSLine} from "react-icons/ri";
import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";

export default function MatchInformationMobile({summoner,match,index,setButtonIndex, buttonIndex}:any){
    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;
  

    const toggleInfo = (index:number) => {
        setButtonIndex(buttonIndex === index ? -1 : index);
    };

    return(
        <>
        <div className="flex gap-2 w-[220px]">
            <span className="text-text_drk dark:text-white font-semibold text-[12px]">{mapType(match.match.info.queueId)}</span>
            <span className="text-[12px]">{timeElapsedString(match.match.info.gameCreation, false, currLangData)}</span>
        </div>
        
        <div className="flex gap-2">
            <div className="flex gap-2 font-semibold">
                <span className={`text-${summoner[index]?.result} text-[12px]`}>{currLangData.compare[summoner[index]?.result]}</span>
                <span className="text-[12px]">{gameDuration(match.match.info.gameDuration)}</span>
            </div>
            <div>
                <button onClick={() => toggleInfo(index)} className={`h-4 w-4 flex justify-center items-center rounded-md ${
                                                                    summoner[index]?.result === 'Victory' ? 'button-Victory dark:buttonl-Victory' :
                                                                    summoner[index]?.result === 'Defeat' ? 'button-Defeat dark:buttonl-Defeat' :
                                                                    'button-Remake dark:buttonl-Remake'}`}>
                    <RiArrowDownSLine className={`w-6 h-6 ${buttonIndex === index ? 'rotate-180' : null} text-${summoner[index]?.result}`}/>
                </button>
            </div>
        </div>
        </>
    )
}