import { useState } from 'react';

function TiltCard({ children }:any) {
    const [style, setStyle] = useState({});

    const handleMouseMove = (event:any) => {
        const { clientX, clientY, currentTarget } = event;
        const { left, top, width, height } = currentTarget.getBoundingClientRect();

        const xPos = (clientX - left) / width - 0.5;
        const yPos = (clientY - top) / height - 0.5;

        const rotateX = yPos * 30; 
        const rotateY = xPos * 30;

        setStyle({
            transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`
        });
    };

    const handleMouseLeave = () => {
        setStyle({});
    };

    return (
        <div 
            className="hoverEffect position: relative overflow-hidden rounded-md w-[364px] md:w-full BoxShadowBlk"
            style={style}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
        >
            {children}
        </div>
    );
}

export default TiltCard;