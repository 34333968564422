import { Link, useParams } from "react-router-dom";
import { useVersion } from "../../contexts/VersionContext";
import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";

export default function FriendsWidget({match, summonerData}:any){
    const {region} = useParams();
    const {version} = useVersion();

    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

    const teammates:any = {};
    const summonerTeamIdByMatch:any = {};
  
    match?.map((match:any) => {
      const summonerParticipant = match.match.info.participants.find((p:any) => p.puuid === summonerData?.summonerInfo.puuid);
      if (!summonerParticipant) {
        return null;
      }
    
      const summonerTeamId = summonerParticipant.teamId;
      summonerTeamIdByMatch[match.match.metadata.matchId] = summonerTeamId;
    
      match.match.info.participants.forEach((participant:any) => {
        const teammateName = participant.riotIdGameName;
        if (participant.puuid !== summonerData.summonerInfo.puuid && participant.teamId === summonerTeamId) {
          if (!teammates[teammateName]) {
            teammates[teammateName] = { wins: 0, losses: 0, profileIcon: participant.profileIcon, tag: participant.riotIdTagline };
          }
          if (participant.win) {
            teammates[teammateName].wins++;
          } else {
            teammates[teammateName].losses++;
          }
        }
      });
      return null;
    });
  
    const frequentTeammates = Object.entries(teammates)
      .filter(([name, stats]:any) => stats.wins + stats.losses >= 3)
      .map(([name, stats]) => ({ name, stats }));

    return(
      frequentTeammates.length > 0 ? (
        <div className="w-full lg:w-[332px] dark:bg-secondary bg-third_light lg:rounded-md">
        <div className="borderHor p-2 pb-0 mb-2">
            <span className="dark:text-white text-black text-sm">{currLangData.widgets.recPlayed}</span>
        </div>
            <div className="p-2 pt-0">
                {frequentTeammates.splice(0,4).map(({name, stats}:any) => (
                    <div key={name} className="flex justify-between">
                        <Link to={`/summoner/${region}/${name}/${stats.tag}`} className="flex items-center gap-1 hover:underline text-text">
                            <img className="w-8 h-8 rounded-md" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${stats.profileIcon}.png`} alt={`${name}'s profile icon`}/>
                            <span className="text-black dark:text-white text-sm w-[125px] overflow-hidden text-ellipsis whitespace-nowrap">{name}<span className="text-text">#{stats.tag}</span></span>
                        </Link>

                        <div className=" flex text-sm items-center pl-2 pr-2 font-semibold">
                            <span className="text-text"><span className="text-Victory">{stats.wins}</span> / <span className="text-val">{stats.losses}</span></span>
                        </div>

                        <div className="text-sm">
                            <div className="text-end">
                                {Number(((stats.wins / (stats.wins + stats.losses)) * 100).toFixed(0)) > 50 ?
                                    (<span className='text-val'>{((stats.wins / (stats.wins + stats.losses)) * 100).toFixed(0)}%</span>)
                                    :
                                    (<span className='dark:text-white text-black'>{((stats.wins / (stats.wins + stats.losses)) * 100).toFixed(0)}%</span>)}
                            </div>
                                <span className='text-text'>{stats.wins + stats.losses} {currLangData.widgets.played}</span>
                        </div>


                    </div>
                ))}
            </div>
        </div>
        ):(null)
    )
}