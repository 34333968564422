import { ClipLoader } from "react-spinners";
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";

interface LoadMoreProps {
    setNumDisplayed: React.Dispatch<React.SetStateAction<number>>;
    loadMatches: any;
    numDisplayed: number;
  }

  const LoadMore: React.FC<LoadMoreProps> = ({ setNumDisplayed, loadMatches, numDisplayed }) => {
    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;
  
    function handleShowMore() {
      setNumDisplayed((prevState) => prevState + 20);
    }
  
    return (
      numDisplayed<=100 ? (
      <button onClick={handleShowMore} className="dark:hover:border-[2px] border-pink hover:border-[2px] text-xs w-full h-8 bg-third_light dark:bg-secondary text-black dark:text-white lg:rounded-md flex justify-center items-center">
        {!loadMatches ? (`${currLangData.summonerProfile.buttons.loadMore}`):(<ClipLoader color="#85417C" size={20}/>)}
      </button>
      ):(null)
    );
  }
export default LoadMore;