import { Link } from "react-router-dom";
import { useVersion } from "../../contexts/VersionContext";
import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";

export default function MostPlayedWidget({match, summonerData, champions}:any){

    const champStats:any = {};
    const {version} = useVersion();

    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;


    match?.forEach((match:any) => {
      const summonerParticipant = match.match.info.participants.find((p:any) => p.puuid === summonerData?.summonerInfo.puuid);
      const champPlayed = summonerParticipant?.championName;
      if (!champStats[champPlayed]) {
        champStats[champPlayed] = {
          champCount: 1,
          id: summonerParticipant?.championId,
          kills: summonerParticipant?.kills,
          deaths: summonerParticipant?.deaths,
          assists: summonerParticipant?.assists,
          cs: summonerParticipant?.totalMinionsKilled,
          gamesPlayed: 1,
          gamesWon: summonerParticipant?.win ? 1 : 0,
          gamesLost: summonerParticipant?.win ? 0 : 1
        };
      } else {
        champStats[champPlayed].champCount++;
        champStats[champPlayed].kills += summonerParticipant?.kills;
        champStats[champPlayed].deaths += summonerParticipant?.deaths;
        champStats[champPlayed].assists += summonerParticipant?.assists;
        champStats[champPlayed].cs += summonerParticipant?.totalMinionsKilled;
        champStats[champPlayed].gamesPlayed++;
        if (summonerParticipant?.win) {
          champStats[champPlayed].gamesWon++;
        } else {
          champStats[champPlayed].gamesLost++;
        }
      }
    });
    
    const sortedChampStats = Object.entries(champStats)
      .map(([champ, stats]:any) => ({ champ, ...stats }))
      .sort((a, b) => b.gamesPlayed - a.gamesPlayed);

    return(
        champStats ? (
        <div className="w-full lg:w-[332px] dark:bg-secondary bg-third_light lg:rounded-md">
        <div className="borderHor p-2 pb-0 mb-2">
            <span className="dark:text-white text-black text-sm">{currLangData.widgets.mostPlayed}</span>
        </div>
        <div className="p-2 pt-0 flex flex-col gap-1">
            {sortedChampStats.slice(0,5).map((champ:any) => (
                <div key={champ.champ} className="flex justify-between">
                    <div className="flex gap-1">
                        <Link className="w-[32px] h-[32px] rounded-md overflow-hidden" target="_blank" to={`/champion/${champions[champ.id]?.name}`}><img className="scale-[1.1]" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[champ.id]?.name}.png`} alt={champions[champ.id]?.name}/></Link>
                        <div className="flex flex-col text-xs">
                            <span className="text-balck dark:text-white w-20 font-semibold">{champions[champ.id]?.displayName}</span>
                            <span className="text-text">{(champ.cs/champ.gamesPlayed).toFixed(1)} CS</span>
                        </div>
                    </div>
                    <div className="flex flex-col text-xs items-center">
                        <span className="dark:text-white text-black font-semibold">{(champ.kills/champ.gamesPlayed).toFixed(1)} / <span className="text-val">{(champ.deaths/champ.gamesPlayed).toFixed(1)}</span> / {(champ.assists/champ.gamesPlayed).toFixed(1)}</span>
                        <span className="text-text">{champ.deaths === 0 ? (<span>{currLangData.compare.perfect}</span>):(((champ.kills+champ.assists)/champ.deaths).toFixed(2)+":1 "+ currLangData.compare.kda)}</span>
                    </div>
                    <div className="flex flex-col text-end text-xs ">
                    {Number((champ.gamesWon/champ.gamesPlayed*100).toFixed(0)) > 50
                        ? <span className="text-val font-semibold">{(champ.gamesWon/champ.gamesPlayed*100).toFixed(0)}%</span>
                        : <span className="text-black dark:text-white font-semibold">{(champ.gamesWon/champ.gamesPlayed*100).toFixed(0)}%</span>
                        }
                    <span className="text-text">{champ.gamesPlayed} {currLangData.widgets.played}</span>
                    </div>
                </div>
            ))}
        </div>
        </div>
        ):(null)
    )
}