import React, { useState } from 'react';
import ChampionTooltips from './ChampionTooltips';

interface ChampionOverviewProps {
  champion: any;
  currLangData: any;
}


export default function ChampionStats({ champion, currLangData }: ChampionOverviewProps) {
  const [selectedLevel, setSelectedLevel] = useState(1);

  const statsArray = [
    { key: 'hp', name: `${currLangData.champion.health}`, perLevelKey: 'hpperlevel' },
    { key: 'mp', name: `${champion.partype !== "Mana" ? (currLangData.champion.energy) : (currLangData.champion.mana)}`, perLevelKey: 'mpperlevel' },
    { key: 'armor', name: `${currLangData.champion.armor}`, perLevelKey: 'armorperlevel' },
    { key: 'spellblock', name: `${currLangData.champion.magicRes}`, perLevelKey: 'spellblockperlevel' },
    { key: 'attackrange', name: `${currLangData.champion.attackR}` },
    { key: 'hpregen', name: `${currLangData.champion.healthReg}`, perLevelKey: 'hpregenperlevel' },
    { key: 'crit', name: `${currLangData.champion.crit}` },
    { key: 'attackdamage', name: `${currLangData.champion.attackD}`, perLevelKey: 'attackdamageperlevel' },
    { key: 'attackspeed', name: `${currLangData.champion.attackS}`, perLevelKey: 'attackspeedperlevel' },
    { key: 'movespeed', name: `${currLangData.champion.movSpe}` },
  ];

  const calculateStatValue = (baseValue: number, perLevelValue: number, level: number) => {
    return baseValue + perLevelValue * (level - 1);
  };

  const formatStatValue = (value: number) => {
    return Number.isInteger(value) ? value.toString() : value.toFixed(2).replace(/\.0$/, '');
  };

  const LevelDropdown = () => (
    <select
      value={selectedLevel}
      onChange={(e) => setSelectedLevel(parseInt(e.target.value))}
      className="mb-5 p-2 rounded dark:bg-secondary dark:text-white  bg-secondary_light text-black"
    >
      {Array.from({ length: 18 }, (_, i) => (
        <option key={i + 1} value={i + 1}>
          {currLangData.level} {i + 1}
        </option>
      ))}
    </select>
  );


  return (
    <div className="w-full flex flex-col rt:flex-row justify-center gap-0 rt:gap-[150px] items-center rt:items-start position: relative">

        <div className={`absolute left-[25px] top-[250px] h-[100px] w-px border border-current hidden md:block dark:text-white`}>
          <div className={`absolute rotate-[-90deg] left-[-125px] top-[-170px] w-[255px] text-[15px] tracking-[8px] uppercase`}>{currLangData.champion.sANDt}</div>
        </div>

        <div className='flex flex-col items-center w-full md:w-[450px] rt:w-fit'>
        <LevelDropdown /> 

      <div className='flex justify-center gap-5 flex-wrap flex-col rt:flex-col max-h-[330px] w-full rt:w-[450px] '>
        {statsArray.map(stat => {
          const baseValue = champion.stats[stat.key];
          const perLevelValue = stat.perLevelKey ? champion.stats[stat.perLevelKey] : 0;
          const value = calculateStatValue(baseValue, perLevelValue, selectedLevel);
          return (
            <div key={stat.key} className="flex-col flex items-center">
              <div className="flex items-center gap-2">
                <img className="w-5 h-5" src={`/statsIcons/${stat.key}.png`} alt={stat.key} />
                <span className='text-black dark:text-white text-xs md:text-base'>{stat.name}</span>
              </div>
              <span className='text-text'>{formatStatValue(value)}</span>
            </div>
          );
        })}
      </div>
      </div>

      
      <ChampionTooltips champion={champion}/>
    </div>
  );
}
