import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLanguage } from "../contexts/LanguageContext";
interface ItemDisplayProps {
  marginLeft: string;
  items: Array<{
    id: number;
    order: string;
    rounded?: string;
  }>;
  version: string;
}

const ItemDisplay: React.FC<ItemDisplayProps> = ({
  items,
  version,
  marginLeft,
}) => {
  const [allItems, setAllItems] = useState<any>({});
  const [hoveredItem, setHoveredItem] = useState<any>(null);
  const [hoveredItemPosition, setHoveredItemPosition] = useState<any>({ x: 0, y: 0 });

  const {language: lng} = useLanguage();

  let lang:any;
  if(lng === "pl"){
      lang = "pl_PL";
  }else if(lng === "en"){
      lang = "en_US"
  }else if(lng === "es"){
      lang = "es_ES"
  }else(
      lang = "de_DE"
  );

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(
          `https://ddragon.leagueoflegends.com/cdn/${version}/data/${lang}/item.json`
        );
        setAllItems(response.data.data);
      } catch (error) {
        console.error('Failed to fetch items:', error);
      }
    };

    fetchItems();
  }, [version, lang]);

  const handleMouseOver = (itemId:number, event:any) => {
    const item = allItems[itemId];
    if (!item) return;

    setHoveredItem(item);

    const imgRect = event.target.getBoundingClientRect();
    const x = imgRect.left + window.scrollX + imgRect.width + 10;
    const y = imgRect.top + window.scrollY;

    setHoveredItemPosition({ x, y });
  };

  return (
    <div
      className={`flex ml-[${marginLeft}] w-[90px] lg:w-[118px] gap-[2px] justify-start items-center flex-wrap`}
    >
      {items.map(({ id, order, rounded }, i) => (
        <div key={i} onMouseOver={(e) => handleMouseOver(id, e)} onMouseLeave={() => setHoveredItem(null)} className='w-[20px] lg:w-[24px] h-[20px] lg:h-[24px]'>
          {id !== 0 ? (
            <img
              className={`w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] ${order} ${rounded ?? ''}`}
              src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/item/${id}.png`}
              alt={`Item ${i}`}
            />
          ) : (
            <div className={`w-[20px] lg:w-[24px] h-[20px] lg:h-[24px] rounded-[4px] lg:rounded-[9px] bg-[rgb(0,0,0,0.2)] ${order}`}/>
          )}
        </div>
      ))}

      {hoveredItem && (
        <div className='lg:block hidden position: absolute bg-black rounded-md p-2 z-20 shadow-[0,0,10,rgba(0,0,0,0.5] text-xs w-[250px] text-left' style={{left: hoveredItemPosition.x,top: hoveredItemPosition.y,}}>
          <h3 className='font-bold text-pink'>{hoveredItem.name}</h3>
          <div dangerouslySetInnerHTML={{ __html: hoveredItem.description }} />
          <p className='mb-2'>{hoveredItem.plaintext}</p>
          
          {hoveredItem.gold.total === 0 ? (
            <p>Cost: <span className='text-[rgb(255,198,89)]'>Free</span></p>
          ) : (
            <p>Cost: <span className='text-[rgb(255,198,89)]'>{hoveredItem.gold.total}G ({hoveredItem.gold.sell}G)</span></p>
          )}
        </div>
      )}
    </div>
  );
};

export default ItemDisplay;
