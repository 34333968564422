import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";

const getRankImage = (tier:any) => {
  const imageTier = tier ? tier.toUpperCase() : "ur";
  return `/tiers/${imageTier}.png`;
};

const calculateWinRatio = (wins:any, losses:any) => {
  if (wins) {
    return Math.ceil((wins / (wins + losses)) * 100);
  }
  return 0;
};

const formatTierAndRank = (tier:any, rank:any, currLangData:any) => {
  const highTiers = ["GRANDMASTER", "CHALLENGER", "MASTER"];
  if (highTiers.includes(tier)) {
    return tier;
  }
  return tier ? `${currLangData.ranks[tier.toLowerCase()]} ${rank || ""}`.trim() : `${currLangData.ranks.ur}`;
};

const RankDetails = ({ rankData }:any) => {
  const { wins, losses, tier, rank, leaguePoints } = rankData;
  const winRatio = calculateWinRatio(wins, losses);
  
  const {language: lang} = useLanguage();
  const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <div className="flex justify-center">
          <img className="w-[48px] h-[48px] bg-[#1c1c1f80] dark:bg-primary rounded-md" src={getRankImage(tier)} alt={tier || "Unranked"} />
          <div className="flex flex-col ml-2">
            <div>
              <span className="text-black dark:text-white font-semibold">{formatTierAndRank(tier, rank, currLangData)}</span>
              <span className="text-xs text-text ml-2">{leaguePoints != null ? `${leaguePoints} LP ` : ""}</span>
            </div>
            <span className=" text-xs">
              <span className="text-text">{wins || 0}{currLangData.compare.w} {losses || 0}{currLangData.compare.l}</span>
            </span>
          </div>
        </div>
        <CircularProgressbar
          value={winRatio}
          text={`${winRatio}%`}
          className="w-12 h-12"
          strokeWidth={15}
          styles={buildStyles({ strokeLinecap: "butt" })}
        />
      </div>
    </div>
  );
};

const QueueTypeSection = ({ queueType, rankData }:any) => (
  <div className="w-full lg:w-[332px] bg-third_light dark:bg-secondary lg:rounded-md">
    <div className="p-2 pb-0 flex text-dark dark:text-white borderHor text-sm">
      <span>{queueType}</span>
    </div>
    <div className="flex justify-between p-2">
      {rankData ? <RankDetails rankData={rankData} /> : <p>No {queueType.toLowerCase()} data.</p>}
    </div>
  </div>
);

export default function RankWidget({ summonerData }:any) {
  const { summonerRank } = summonerData ?? {};
  const [soloRank, flexRank] = summonerRank || [];

  const {language: lang} = useLanguage();
  const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

  return (
    <>
    {soloRank ? (
      <QueueTypeSection queueType={currLangData.widgets.rankSol} rankData={soloRank}/>
      ) : null}
    {flexRank ? (
      <QueueTypeSection queueType={currLangData.widgets.rankFlex} rankData={flexRank}/>
      ) : null}
    </>
  );
}
