import { Link, useParams } from "react-router-dom";
import useRunes from "../../cases/UseRunes";
import DamageBar from "../../components/DamageBar";
import ItemDisplay from "../../components/ItemDisplay";
import { spell } from "../../cases/MapsSpells";
import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";

export default function MatchOverview({summoners, summoner: summonerIndex, index, version, champions, damageStats, team}:any){

    const { getRuneStyleImageUrl } = useRunes();

    const {summoner: sumUrl ,region} = useParams();

    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

    return(
        <tbody>
        {summoners[index][team].sort((a:any, b:any) => a.placement - b.placement).map((summoner:any, indexx: number) => {
            return(
            <tr className={`text-center ${summoner?.win === true ? 'bg-Victory dark:bgl-Victory' : 'bg-Defeat dark:bgl-Defeat'}`} key={indexx}>
            <td className={`flex items-center pt-1 pb-1 pl-1 ${summonerIndex[index]?.gameMode === "CHERRY" ? ('w-[185px]'):('w-[155px]')} md:w-[unset] md:h-[52px]`}>

                <div className="overflow-hidden rounded-[8px] position: relative bg-primary p-[2px] w-[40px] h-[40px] flex items-start justify-center">
                    <Link target="_blank" to={`/champion/${champions[summoner?.championId]?.name}`} className="overflow-hidden rounded-md "><img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[summoner?.championId]?.name}.png`} alt={summoner?.championName} className="scale-[1.1]"/></Link>
                    <span className="text-white bg-[rgb(28,28,31,0.8)] position: absolute top-0 left-0 rounded-br-md text-[10px] w-[20px] text-center font-semibold">{summoner?.champLevel}</span>
                </div>

                <div className="flex">

                    <div className="flex flex-col gap-[2px] justify-center">
                        <img className="w-[18px] rounded-[6px] ml-[2px] mr-[2px]" src={summoner?.summoner1Id !== 0 ? (`https://ddragon.leagueoflegends.com/cdn/${version}/img/spell/${spell(summoner?.summoner1Id)}.png`):(`/noicon.jpg`)} alt={summoner?.summoner1Id}/>
                        <img className="w-[18px] rounded-[6px] ml-[2px] mr-[2px]" src={summoner?.summoner2Id !== 0 ? (`https://ddragon.leagueoflegends.com/cdn/${version}/img/spell/${spell(summoner?.summoner2Id)}.png`):(`/noicon.jpg`)} alt={summoner?.summoner2Id}/>
                    </div>

                    {summonerIndex[index]?.gameMode !== "CHERRY" ? (
                        <div className="flex flex-col gap-[2px] justify-center">
                            <img className="w-[18px] h-[18px] rounded-[6px] bg-black" src={`${getRuneStyleImageUrl(summoner?.perks.styles[0].selections[0].perk)}`} alt={summoner?.perks.styles[0].selections[0].perk}/>
                            <img className="w-[18px] h-[18px] rounded-[6px] bg-black p-px" src={`${getRuneStyleImageUrl(summoner?.perks.styles[1].style)}`} alt={summoner?.perks.styles[1].style}/>
                        </div>
                    ):(
                        <>
                        <div className="flex flex-col gap-[2px] justify-center">
                        {summoner?.playerAugment1 !== 0 ? (
                            <img className="w-[18px] h-[18px] rounded-[6px] bg-black" src={`https://cdn5.xdx.gg/augment48/${summoner?.playerAugment1}.webp`} alt={summoner?.playerAugment1}/>
                        ) : (
                            <div className="w-[18px] h-[18px] rounded-[6px] bg-[rgb(0,0,0,0.2)]"></div>
                        )}
                        {summoner?.playerAugment3 !== 0 ? (
                            <img className="w-[18px] h-[18px] rounded-[6px] bg-black" src={`https://cdn5.xdx.gg/augment48/${summoner?.playerAugment3}.webp`} alt={summoner?.playerAugment3}/>
                        ) : (
                            <div className="w-[18px] h-[18px] rounded-[6px] bg-[rgb(0,0,0,0.2)]"></div>
                        )}
                        </div>
                        <div className="flex flex-col gap-[2px] justify-center ml-[2px]">
                        {summoner?.playerAugment2 !== 0 ? (
                            <img className="w-[18px] h-[18px] rounded-[6px] bg-black" src={`https://cdn5.xdx.gg/augment48/${summoner?.playerAugment2}.webp`} alt={summoner?.playerAugment2}/>
                        ) : (
                            <div className="w-[18px] h-[18px] rounded-[6px] bg-[rgb(0,0,0,0.2)]"></div>
                        )}
                        {summoner?.playerAugment4 !== 0 ? (
                            <img className="w-[18px] h-[18px] rounded-[6px] bg-black" src={`https://cdn5.xdx.gg/augment48/${summoner?.playerAugment4}.webp`} alt={summoner?.playerAugment4}/>
                        ) : (
                            <div className="w-[18px] h-[18px] rounded-[6px] bg-[rgb(0,0,0,0.2)]"></div>
                        )}
                        </div>
                    
                        </>
                    )} 

                </div>

                <div className="flex flex-col text-xs items-start ml-2">
                    <Link className={`hover:underline dark:text-white text-black w-[60px] overflow-hidden text-ellipsis whitespace-nowrap ${sumUrl===summoner?.riotIdGameName ? ('font-bold'):(null)}`} to={`/summoner/${region}/${summoner?.riotIdGameName}/${summoner?.riotIdTagline}`}>{summoner?.riotIdGameName}<span className="text-text">#{summoner?.riotIdTagline}</span></Link>
                    <span className="text-[10px]">{currLangData.level} {summoner?.summonerLevel}</span>
                </div>

            </td>
            
            <td>
                <div className="flex flex-col">
                <span className="text-[10px] md:text-xs font-semibold"><span className="dark:text-white text-black">{summoner?.kills}</span>/<span className="text-val">{summoner?.deaths}</span>/<span className="dark:text-white text-black">{summoner?.assists}</span> <span className="text-val borderVer pl-1">{summoner?.kpRatio}%</span></span>
                <span className="text-[10px] md:text-xs">{summoner?.kda}:1 {currLangData.compare.kda}</span>
                </div>
            </td> 

            <td className="hidden md:table-cell">
                <DamageBar summoner={summoner} damageStats={damageStats} index={index} />
            </td>
            
            <td className="pl-1 pr-1"><span className="text-[10px] md:text-xs">{summoner.cs}</span></td>
            
            <td>
                <div className="flex justify-center">
                    <ItemDisplay
                        marginLeft="0px"
                        items={[
                            { id: summoner?.item0, order: "order-1" },
                            { id: summoner?.item1, order: "order-2" },
                            { id: summoner?.item2, order: "order-3" },
                            { id: summoner?.item6, order: "order-4", rounded: 'rounded-full' },  //ward
                            { id: summoner?.item3, order: "order-5" },
                            { id: summoner?.item4, order: "order-6" },
                            { id: summoner?.item5, order: "order-7" },
                        ]}
                        version={version}
                    />
                </div>
            </td>

            </tr>
            )
        })}
    </tbody>
    )
}