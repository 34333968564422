import { createContext, useContext, useState, useEffect } from 'react';

const FontFamilyContext = createContext<any>(null);

export const useFontFamily = () => useContext(FontFamilyContext);

export const FontFamilyProvider = ({ children }: any) => {
    const [fontFamily, setFontFamily] = useState(localStorage.getItem('fontFamily') || 'Poppins, sans-serif');

    const changeFontFamily = (newFont: string) => {
        localStorage.setItem('fontFamily', newFont);
        setFontFamily(newFont);
    };

    useEffect(() => {
        document.documentElement.style.fontFamily = fontFamily;
    }, [fontFamily]);

    return (
        <FontFamilyContext.Provider value={{ fontFamily, changeFontFamily }}>
            {children}
        </FontFamilyContext.Provider>
    );
};