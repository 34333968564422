import { useEffect, useState } from 'react';
import moment from 'moment';

const isUnixTimestamp = (timeValue: number) => {
  return timeValue > 1000000000;
};

export default function GameTimer({ startTime, gameLength }: { startTime?: number; gameLength?: number }) {
  const [currentTime, setCurrentTime] = useState(() => {
    if (startTime && isUnixTimestamp(startTime)) {
      const now = Math.floor(Date.now() / 1000);
      const initialTime = now - startTime;
      return Math.round(initialTime); 
    }
    return gameLength || 0;
  });

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return <span className='font-semibold'>{formatTime(currentTime)}</span>;
}

export function timeElapsedString(gameEndTimestamp: number, full = false, currLangData: any) {
  const endTime = moment.unix(gameEndTimestamp / 1000); 

  const now = moment();
  
  const diff = moment.duration(now.diff(endTime));

  const timeUnits:any = [
    { unit: 'year', singular: currLangData.time.year, plural: currLangData.time.years },
    { unit: 'month', singular: currLangData.time.month, plural: currLangData.time.months },
    { unit: 'week', singular: currLangData.time.week, plural: currLangData.time.weeks },
    { unit: 'day', singular: currLangData.time.day, plural: currLangData.time.days },
    { unit: 'hour', singular: currLangData.time.hour, plural: currLangData.time.hours },
    { unit: 'minute', singular: currLangData.time.minute, plural: currLangData.time.minutes },
    { unit: 'second', singular: currLangData.time.second, plural: currLangData.time.seconds },
  ];

  const unitsToShow = timeUnits.reduce((acc:any, { unit, singular, plural }:any) => {
    const unitValue = diff.get(unit);
    if (unitValue) {
      const unitString = `${unitValue} ${unitValue > 1 ? plural : singular}`;
      acc.push(unitString);
    }
    return acc;
  }, []);

  const displayString = full ? unitsToShow.join(', ') : unitsToShow[0];
  return displayString ? `${displayString} ${currLangData.time.ago}` : `${currLangData.time.now}`;
}

export function gameDuration(gameDuration:number){
  const min = Math.floor(gameDuration/60);
  const sec = gameDuration-(min*60);
  return `${String(min).padStart(2, '0')}m ${String(sec).padStart(2, '0')}s`;
};

export function unixTimeToMinutes(unixTime:number) {
  const date = new Date(unixTime);
  const minutes = date.getTime() / 1000 / 60;
  return Math.round(minutes)+" min";
}

export function formatPlacement(n: number): string {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}