export default function DetailsComponent({participantData, version, champions, highestValues, variable, divider, mode}:any){

    let arena;
    if (mode === "CHERRY"){
        arena = 16;
    } else{
        arena = 10;
    }

    function sortParticipantsByPlacement(participants:any) {
        const sortedByPlace:any = {};

        participants.forEach((participant:any) => {
            const place:any = participant.place;
            if (!sortedByPlace[place]) {
                sortedByPlace[place] = [];
            }
            sortedByPlace[place].push(participant);
        });

        const sortedArray = Object.keys(sortedByPlace).map(key => ({
            place: key,
            participant: sortedByPlace[key]
        }));

        sortedArray.sort((a, b) => parseInt(a.place) - parseInt(b.place));

        return sortedArray;
    }

    const sortedParticipants = sortParticipantsByPlacement(participantData);


    return(
    mode !== "CHERRY" ? (
    <div className="flex justify-between">
        <div className="flex flex-col gap-2">
            {participantData.slice(0,arena/2).map((user:any, index:number)=>{
                  const statValue = user[variable]; 
                  const highestStatValue = highestValues[divider];
            return( 
            <div key={index} className="flex items-center">
                <div className="flex items-center position: relative gap-1 group">
                    <div className={`p-[1px] ${statValue === 0 ? 'bg-[rgb(0,0,0,0.2)]':'bg-[#5383e8]'} rounded-[4px]`}><div className="w-6 h-6 rounded-[3px] overflow-hidden"><img className="scale-[1.1]" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[user.champ]?.name}.png`} alt={champions[user.champ]?.name}/></div></div>
                    <span className="bg-black px-1 rounded-md text-xs text-white position: absolute left-5 top-1 translate-x-2 hidden group-hover:block z-10">
                        {user.name}
                    </span>
                </div>
                <div className="flex flex-col position: relative overflow-hidden">
                    <span className="bg-[rgb(0,0,0,0.2)] h-[16px] md:w-[80px] w-[50px] rounded-tr-[6px] rounded-br-[6px]"></span>
                    <span className="bg-[#5383e8] h-[16px] position: absolute top-0 left-0 rounded-tr-[6px] rounded-br-[6px]" style={{width: `${statValue/highestStatValue * 100}%`}}></span>
                    <span className="text-[12px] font-bold ml-[2px] text-white position: absolute top-0 left-0 ">{statValue.toLocaleString()}</span>
                </div>
            </div>
            )
            })}
        </div>
        <div className="flex flex-col gap-2">
            {participantData.slice(arena/2,arena).map((user:any, index:number)=>{
                const statValue = user[variable]; 
                const highestStatValue = highestValues[divider];
            return( 
            <div key={index} className="flex items-center">
                <div className="flex flex-col position: relative overflow-hidden">
                    <span className="bg-[rgb(0,0,0,0.2)] h-[16px] md:w-[80px] w-[50px] rounded-tl-[6px] rounded-bl-[6px]"></span>
                    <span className="bg-[#e84057] h-[16px] position: absolute top-0 right-0 rounded-tl-[6px] rounded-bl-[6px]" style={{ width: `${statValue/highestStatValue * 100}%`}}></span>
                    <span className="text-[12px] font-bold mr-[2px] text-white text-right position: absolute top-0 right-0">{statValue.toLocaleString()}</span>
                </div>
                <div className="flex items-center position: relative gap-1 group">
                    <div className={`p-[1px] ${statValue === 0 ? 'bg-[rgb(0,0,0,0.2)]':'bg-val'} rounded-[4px]`}><div className="w-6 h-6 rounded-[3px] overflow-hidden"><img className="scale-[1.1]" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[user.champ]?.name}.png`} alt={champions[user.champ]?.name}/></div></div>
                    <span className="bg-black px-1 rounded-md text-xs text-white position: absolute right-9 top-1 translate-x-2 hidden group-hover:block z-10">
                        {user.name}
                    </span>
                </div>
            </div>
            )
            })}
        </div>
    </div>
    ):(
        <div className="flex flex-col gap-2">
            {sortedParticipants.map((user:any, index:number)=>{
                  const statValue = user.participant[0][variable]; 
                  const statValueA = user.participant[1][variable]; 
                  const highestStatValue = highestValues[divider];
            return( 
            <div key={index} className="flex items-center justify-between">
                <div className="flex items-center">
                <div className="flex items-center position: relative gap-1 group">
                    <div className={`p-[1px] ${statValue === 0 ? 'bg-[rgb(0,0,0,0.2)]':'bg-[#5383e8]'} rounded-[4px]`}><div className="w-6 h-6 rounded-[3px] overflow-hidden"><img className="scale-[1.1]" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[user.participant[0].champ]?.name}.png`} alt={champions[user.participant[0].champ]?.name}/></div></div>
                    <span className="bg-black px-1 rounded-md text-xs text-white position: absolute left-5 top-1 translate-x-2 hidden group-hover:block z-10">
                        {user.participant[0].name}
                    </span>
                </div>
                <div className="flex flex-col position: relative overflow-hidden">
                    <span className="bg-[rgb(0,0,0,0.2)] h-[16px] md:w-[80px] w-[50px] rounded-tr-[6px] rounded-br-[6px]"></span>
                    <span className={`${index%2===1 ? "bg-[#e84057]":"bg-[#5383e8]"} h-[16px] position: absolute top-0 left-0 rounded-tr-[6px] rounded-br-[6px]`} style={{width: `${statValue/highestStatValue * 100}%`}}></span>
                    <span className="text-[12px] font-bold ml-[2px] text-white position: absolute top-0 left-0 ">{statValue.toLocaleString()}</span>
                </div>
                </div>

                <div className="flex items-center">
                <div className="flex flex-col position: relative overflow-hidden">
                    <span className="bg-[rgb(0,0,0,0.2)] h-[16px] md:w-[80px] w-[50px] rounded-tl-[6px] rounded-bl-[6px]"></span>
                    <span className={`${index%2===1 ? "bg-[#e84057]":"bg-[#5383e8]"} h-[16px] position: absolute top-0 right-0 rounded-tl-[6px] rounded-bl-[6px]`} style={{ width: `${statValueA/highestStatValue * 100}%`}}></span>
                    <span className="text-[12px] font-bold mr-[2px] text-white text-right position: absolute top-0 right-0">{statValueA.toLocaleString()}</span>
                </div>
                <div className="flex items-center position: relative gap-1 group">
                    <div className={`p-[1px] ${statValueA === 0 ? 'bg-[rgb(0,0,0,0.2)]':'bg-val'} rounded-[4px]`}><div className="w-6 h-6 rounded-[3px] overflow-hidden"><img className="scale-[1.1]" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[user.participant[1].champ]?.name}.png`} alt={champions[user.participant[1].champ]?.name}/></div></div>
                    <span className="bg-black px-1 rounded-md text-xs text-white position: absolute right-9 top-1 translate-x-2 hidden group-hover:block z-10">
                        {user.participant[1].name}
                    </span>
                </div>
                </div>
            </div>
            )
            })}
        </div>
    )
    )
}

