import { useEffect, useState } from "react";
import SummonerInfo from "../summoners/SummonerInfo";
import { Route, Routes, useParams } from "react-router-dom";
import axios from "axios";
import SummonerSummary from "../summoners/SummonerSummary";
import Buttons from "../summoners/Buttons";
import SummonerMaestry from "../summoners/SummonerMaestry";
import SummonerLive from "../summoners/SummonerLive";
import SummonerCompare from "../summoners/SummonerCompare";
import { hosting } from "../cases/Hosting";

export default function SummonerPage() {
    const { region, summoner, tagLine } = useParams();
    const [summonerData, setSummonerData] = useState<any | null>(null);

    useEffect(() => {
    if (!region || !summoner) return;

    const fetchSummoner = async () => {
      try {
        const response = await axios.get(`${hosting}/summoner/${region}/${encodeURIComponent(summoner)}/${tagLine}`);
        const summonerData = response.data;
        setSummonerData(summonerData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSummoner();
    },[region, summoner, tagLine]);

//-----------------------------------------------------------------------

    const [matches, setMatches] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(()=>{
      if (!region || !summoner) return;
      setLoading(true);
      const fetchMatches = async () => {
      try{
        const response = await axios.get(`${hosting}/matches/${region}/${encodeURIComponent(summoner)}/${tagLine}`);
        const summonerMatches = response.data;
        setMatches(summonerMatches);
        setLoading(false);
      }catch(error){
        console.log(error);
      }
    };
    fetchMatches()
    },[region, summoner, tagLine])

//-----------------------------------------------------------------------

const [champions, setChampions] = useState<any>({});
const [maestryData, setMaestryData] = useState<any>([]);

useEffect(() => {
    const fetchChampionNames = async () => {
        try {
            const response = await axios.get(`${hosting}/maestry/${region}/${summoner}/${tagLine}`);
            setMaestryData(response.data);
        } catch (error) {
            console.error('Failed to fetch champion names:', error);
        }
    };
        fetchChampionNames();
}, [region, summoner, tagLine]);

useEffect(() => {
  const fetchChampions = async () => {
    try {
      const response = await axios.get(`${hosting}/api/fetchAllChampionNames`);
      const champs = response.data.reduce((acc:any, champ:any) => {
        acc[champ.champKey] = { name: champ.Name, displayName: champ.champName, position: champ.positionMain };
        return acc;
      }, {});
      setChampions(champs);
    } catch (error) {
      console.error('Failed to fetch champions:', error);
    }
  };

  fetchChampions();
}, []);

//------------------------------------------------------------

const [live, setLive] = useState<any>([]);
const [load, setLoad] = useState<boolean>(false);

useEffect(() => {
  const fetchData = async () => {
    setLoad(true);
    try {
      const liveResponse = await axios.get(`${hosting}/live/${region}/${summoner}/${tagLine}`);
      setLive(liveResponse.data);
      setLoad(false);
    } catch (error:any){
      if (error.response && error.response.status === 404) {
        setLive([]);
      } else {
        console.log(error);
      }
    }
  };
  fetchData();
}, [region, summoner, tagLine]);


  return (
    <>
      <SummonerInfo summonerData={summonerData} loading={loading}/>
      <Buttons live={live} load={load}/>

    <Routes>
      <>
        <Route path={`/`} element={<SummonerSummary matches={matches} summonerData={summonerData} champions={champions} setMatches={setMatches} loading={loading}/>}/>
        <Route path={`/maestry`} element={<SummonerMaestry champions={champions} maestryData={maestryData}/>}/>            
        <Route path={`/compare`} element={<SummonerCompare matches={matches} summonerData={summonerData} loading={loading}/> }/>
        <Route path={`/live`} element={<SummonerLive summonerData={summonerData} live={live} load={load}/>}/>
      </>
    </Routes>
    </>
  );
}