import { CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";

export const FavoriteItem = ({ fav, onLinkClick, removeFavorite } : any) => (
    <Link to={`/summoner/${fav.region}/${fav.summoner}/${fav.tagLine}`} className='flex items-center justify-between dark:hover:bg-secondary hover:bg-primary_light'>
        <div className='p-2 flex items-center justify-start' onClick={onLinkClick}>
        <div className='bg-pink text-white w-9 font-semibold p-1 rounded-md uppercase text-[10px] text-center mr-1'><span>{fav.region}</span></div>
            <span className='text-[14px] max-w-[85px] whitespace-nowrap text-ellipsis overflow-hidden'>{fav.summoner}</span>
            <span className='text-text text-[14px]'>#{fav.tagLine}</span>
        </div>
        <CgClose className='m-2 hover:scale-[1.2] text-text hover:text-pink w-5 h-5' onClick={(e) => { e.preventDefault(); removeFavorite(fav.summoner, fav.tagLine); }}/>
    </Link>
);