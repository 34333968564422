import { useEffect, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai"
import SummaryComponents from "./SummaryComponent";
import InformationWidget from "./SummonerWidget/InfomartionWidget";
import RankWidget from "./SummonerWidget/RankWidget";
import MostPlayedWidget from "./SummonerWidget/MostPlayedWidget";
import FriendsWidget from "./SummonerWidget/FriendsWidget";
import { ClipLoader } from "react-spinners";
import LoadMore from "./LoadMore";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useLayout } from "../contexts/LayoutContext";
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";
import { hosting } from "../cases/Hosting";

export default function SummonerSummary({matches, summonerData, champions, setMatches, loading}:any){
    const {region, summoner: summonerR, tagLine} = useParams()
    const match = matches?.matches; 
    const [loadMatches,setLoadMatches] = useState(false);
    const [numDisplayed, setNumDisplayed] = useState(20);

    const { matchesFirst } = useLayout();
    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

    const summoner = match?.map((matchData: any) => {
        const gameMode = matchData?.match.info.gameMode;
        const participant = matchData?.match.info.participants.find((p: any) => p.puuid === summonerData?.summonerInfo.puuid);
        if (!participant) {return null;}
      
        const { win, gameEndedInEarlySurrender: remake, kills, deaths, assists, teamId, totalMinionsKilled, neutralMinionsKilled, timePlayed } = participant;
        const kda = ((kills + assists) / Math.max(deaths, 1)).toFixed(1);
        const matchResult = remake ? "Remake" : win ? "Victory" : "Defeat";
        const teamKills = matchData.match.info.teams.find((team: { teamId: any; }) => team.teamId === teamId)?.objectives.champion.kills || 0;
        const kpRatio = teamKills > 0 ? ((kills + assists) / teamKills * 100).toFixed(0) : "0";
        const cs = totalMinionsKilled + neutralMinionsKilled;
        const min = Math.floor(timePlayed/60);
        const sec = timePlayed-(min*60);
        const time:any = (min * 60 + sec)/60; 
        const csM = (cs/time).toFixed(1);

        return {participant,result: matchResult,kills,deaths,assists,kda,teamId,kpRatio, cs,csM,gameMode,};
      }).filter(Boolean);

      useEffect(() => {
        const fetchData = async () => {
            setLoadMatches(true);
          try {
            const response = await axios.get(`${hosting}/matches/${region}/${summonerR}/${tagLine}`, {
              params: {start: 0,count: numDisplayed}
            });
            setMatches(response.data);
            setLoadMatches(false);
          } catch (error) {
            console.log(error);
          }
        };
      
        fetchData();
      }, [region, summonerR, tagLine, numDisplayed, setMatches]);

      return (
        <div className="w-full flex justify-center">
            <div className={`w-[1080px] flex ${matchesFirst ? 'lg:flex-row' : 'lg:flex-row-reverse'} flex-col-reverse`}>
                {!loading ? 
                    (match?.length !== 0 ? (
                        <>
                            <div className={`flex flex-col  ${matchesFirst ? 'lg:mr-2' : 'lg:ml-2'} gap-2 mt-2`}>
                                <SummaryComponents summoner={summoner} match={match} champions={champions}/>
                                <LoadMore setNumDisplayed={setNumDisplayed} loadMatches={loadMatches} numDisplayed={numDisplayed}/>
                            </div>           

                            <div className="mt-2 flex flex-col gap-2">
                                <InformationWidget numDisplayed={numDisplayed} match={match} summoner={summoner} champions={champions}/>
                                <RankWidget summonerData={summonerData}/>
                                <MostPlayedWidget summonerData={summonerData} match={match} champions={champions}/>
                                <FriendsWidget match={match} summonerData={summonerData}/>
                            </div>
                        </>
                    ) : (
                        <div className="w-full flex flex-col justify-center items-center p-16 h-[600px]">
                            <AiOutlineExclamationCircle className='w-[100px] h-[100px] text-text'/>
                            <p className="text-[25px] text-text">{currLangData.noMatch}</p>
                        </div>  
                    )
                  ) : (
                    <div className="w-full h-[600px] flex justify-center mt-[100px] "><ClipLoader color="#85417C"/></div>
                )}
            </div>
        </div>
    )
}