import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";

export default function SummonerStats({summoner,summoners, index}:any){

    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

    return(
        <>
        <span className="ml-2 font-semibold"><span className="dark:text-white text-black">{summoner[index]?.kills}</span> / <span className="text-val">{summoner[index]?.deaths}</span> / <span className="dark:text-white text-black">{summoner[index]?.assists}</span></span>
        <span className="ml-2">{summoner[index]?.kda !== (summoner[index]?.kills+summoner[index]?.assists).toFixed(1) ? (<span>{summoner[index]?.kda}:1 {currLangData.compare.kda}</span>) : (<span>{currLangData.compare.perfect}</span>)}</span>
        <span className="ml-2">P/Kill {summoners[index].gameType !== "CHERRY" ? (summoner[index]?.kpRatio):((((summoner[index]?.kills+summoner[index]?.assists)/summoners[index]?.teamBKills)*100).toFixed(0))}%</span>
        <span className="ml-2 md:block hidden">{summoners[index].gameType !== "CHERRY" ?(currLangData.compare.ward + ' ' + summoner[index]?.participant.visionWardsBoughtInGame):(null)}</span>
        <span className="ml-2 md:block hidden">CS {summoner[index]?.cs} ({summoner[index]?.csM})</span>
        </>
    )
}