import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useVersion } from '../contexts/VersionContext';

export default function SummonerMaestry({ maestryData, champions } : any) {
    const [firstChampion, ...rest] = maestryData;
    const two = rest.slice(0, 1);
    const three = rest.slice(1, 2);
    const theRest = rest.slice(2);
    const { version } = useVersion();

    const defaultChamp = champions[firstChampion?.championId];

    const [hoveredChampion, setHoveredChampion] = useState('');

    const getChampionData = (championId : any) => champions[championId] || { name: 'Loading...', displayName: 'Loading...' };

    const renderFirstChampionCard = (champion : any, index : number) => {
        const championData = getChampionData(champion.championId);
        return (
            <div key={index} className={`bg-primary z-10 p-1 rounded-[10px] w-[240px] mdMaes:w-[290px] mdMaes:h-[520px]`} onMouseEnter={() => setHoveredChampion(championData.name)}onMouseLeave={() => setHoveredChampion('')}>
                <div className='position: relative overflow-hidden rounded-md'>
                    <img className={`scale-[1.1] hover:scale-[1.2] transition-all overflow-hidden bg-no-repeat bg-cover`} src={`https://ddragon.leagueoflegends.com/cdn/img/champion/loading/${championData.name}_0.jpg`} alt={championData.displayName}/>
                    <div className="desc descTOP transition-all">
                    <Link to={`/champion/${championData.name}`}><span className='font-semibold text-[25px] hover:underline'>{championData.displayName}</span></Link>
                        <div className='flex justify-center items-center gap-1 pb-1'>
                            <div className='position: relative'>
                            <img className="w-[50px] h-[40px]" src={`/maestry/${Math.min(10,champion.championLevel)}.png`} alt={`Maestry ${champion.championLevel}`}/>
                                <div className='w-full flex justify-center'>
                                <span className='position: absolute bottom-0 h-3 text-center text-white dark:bg-[#b3864870] bg-[#b38648b0] leading-[12px] rounded-md text-[10px] w-8'>{champion.championLevel}</span>
                                </div>
                            </div>
                            <span className="text-[15px] text-text">{champion.championPoints.toLocaleString()} MP</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderChampionCard = (champion : any, index : number) => {
        const championData = getChampionData(champion.championId);
        return (
            <div key={index} className={`bg-primary z-10 p-1 rounded-[10px] w-[200px] mdMaes:w-[250px] mdMaes:h-[448px]`} onMouseEnter={() => setHoveredChampion(championData.name)}onMouseLeave={() => setHoveredChampion('')}>
                <div className='position: relative overflow-hidden rounded-md'>
                    <img className={`scale-[1.1] hover:scale-[1.2] transition-all overflow-hidden bg-no-repeat bg-cover`} src={`https://ddragon.leagueoflegends.com/cdn/img/champion/loading/${championData.name}_0.jpg`} alt={championData.displayName}/>
                    <div className="desc descTOP">
                        <Link to={`/champion/${championData.name}`}><span className='font-semibold text-[25px] hover:underline'>{championData.displayName}</span></Link>
                        <div className='flex justify-center items-center gap-1 pb-1'>
                        <div className='position: relative'>
                            <img className="w-[50px] h-[40px]" src={`/maestry/${Math.min(10,champion.championLevel)}.png`} alt={`Maestry ${champion.championLevel}`}/>
                                <div className='w-full flex justify-center'>
                                <span className='position: absolute bottom-0 h-3 text-center text-white dark:bg-[#b3864870] bg-[#b38648b0] leading-[12px] rounded-md text-[10px] w-8'>{champion.championLevel}</span>
                                </div>
                            </div>
                            <span className="text-[15px] text-text">{champion.championPoints.toLocaleString()} MP</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderList = (champion: any, index: number) => {
        const championData = champions[champion.championId] || { name: 'Loading...', displayName: 'Loading...' };
        return (
            <div key={index} className='flex items-center w-full mdMaes:w-[800px] borderHorBotCol pb-2 justify-between'>
                <div className="flex items-center gap-2">
                <span className="font-normal text-[20px] text-text w-10">{index+4}.</span>
                <Link to={`/champion/${championData.name}`} className='flex gap-5'>
                <div className="overflow-hidden rounded-full w-[48px] h-[48px] hover:shadow-[0_0_10px_3px_rgba(158,158,177,0.1)]"><img className=" w-[48px] h-[48px] object-cover scale-[1.1]" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${championData.name}.png`} alt={championData.displayName}/></div>
                <div className=" flex items-center w-max justify-between">
                    <span className='font-medium text-[18px] text-black dark:text-white hover:underline'>{championData.displayName}</span>
                </div>
                </Link>
                </div>
                <div className='flex justify-center items-center'>
                            <div className='position: relative'>
                            <img className="w-[50px] h-[40px]" src={`/maestry/${Math.min(10,champion.championLevel)}.png`} alt={`Maestry ${champion.championLevel}`}/>
                                <div className='w-full flex justify-center'>
                                <span className='position: absolute bottom-0 h-3 text-center text-white dark:bg-[#b3864870] bg-[#b38648b0] leading-[12px] rounded-md text-[10px] w-8'>{champion.championLevel}</span>
                                </div>
                            </div>
                        <span className="text-[15px] text-text w-[102px] text-end">{champion.championPoints.toLocaleString()} MP</span>
                    </div>
            </div>
        );
    };

    const renderListLow = (champion: any, index: number) => {
        const championData = champions[champion.championId] || { name: 'Loading...', displayName: 'Loading...' };
        return (
            <div key={index} className='flex items-center w-full mdMaes:w-[800px] borderHorBotCol pb-2 justify-between'>
                <div className="flex items-center gap-2">
                <span className="font-normal text-[20px] text-text_drk w-10">{index+1}.</span>
                <Link to={`/champion/${championData.name}`} className='flex gap-5'>
                <div className="overflow-hidden rounded-full w-[48px] h-[48px] hover:shadow-[0_0_10px_3px_rgba(158,158,177,0.1)]"><img className=" w-[48px] h-[48px] object-cover scale-[1.1]" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${championData.name}.png`} alt={championData.displayName}/></div>
                <div className=" flex items-center w-max justify-between">
                    <span className='font-medium text-[18px] text-black dark:text-white hover:underline'>{championData.displayName}</span>
                </div>
                </Link>
                </div>
                <div className='flex justify-center items-center'>
                            <div className='position: relative'>
                            <img className="w-[50px] h-[40px]" src={`/maestry/${Math.min(10,champion.championLevel)}.png`} alt={`Maestry ${champion.championLevel}`}/>
                                <div className='w-full flex justify-center'>
                                <span className='position: absolute bottom-0 h-3 text-center text-white dark:bg-[#b3864870] bg-[#b38648b0] leading-[12px] rounded-md text-[10px] w-8'>{champion.championLevel}</span>
                                </div>
                            </div>
                        <span className="text-[15px] text-text_drk w-[102px] text-end">{champion.championPoints.toLocaleString()} MP</span>
                    </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col items-center gap-2 mt-12 w-full">
            <div className="w-full lg:w-[1080px] dark:bg-secondary bg-third_light p-3 flex flex-col gap-5 items-center lg:rounded-[18px]">
                <div className="md:flex items-end justify-center gap-[5px] flex-wrap w-full position: relative overflow-hidden rounded-md p-5 hidden">
                    <img 
                        src={hoveredChampion ? `https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${hoveredChampion}_0.jpg` : `https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${defaultChamp?.name}_0.jpg`} 
                        alt={hoveredChampion || `${defaultChamp?.name}`} 
                        className="position: absolute bottom-0 blur-[8px] w-full h-full object-cover bg-primary"
                    />
                    {two.map((champion : any, index : number) => renderChampionCard(champion, index))}
                    {firstChampion && renderFirstChampionCard(firstChampion, 0)}
                    {three.map((champion: any, index: number) => renderChampionCard(champion, index))}
                </div>

                <div className="hidden md:flex flex-col gap-[10px] w-full flex-wrap justify-center items-center lg:justify-between">
                    {theRest.map(renderList)}
                </div>

                <div className='md:hidden flex  flex-col gap-[10px] w-full flex-wrap justify-center items-center lg:justify-between'>
                    {maestryData.map(renderListLow)}
                </div>
            </div>
        </div>
    );
}
