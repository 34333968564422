import { useVersion } from "../contexts/VersionContext";
import MatchInformation from "./SummonerComponentItems/MatchInformation";
import ChampionInformation from "./SummonerComponentItems/ChampionInformation";
import SummonerBadges from "./SummonerComponentItems/SummonerBadges";
import SummonerStats from "./SummonerComponentItems/SummonerStats";
import SummonersList from "./SummonerComponentItems/SummonersList";
import { useState } from "react";
import ExtendButton from "./SummonerComponentItems/ExtendButton";
import MatchOverview from "./ExtendedMatch/MatchOverview";
import BuildSpellsRunes from "./ExtendedMatch/BuildSpellsRunes";
import MatchInformationMobile from "./SummonerComponentMobile/MatchInformationMobile";
import DetailsOverview from "./ExtendedMatch/DetailsOverview";
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";

export default function SummaryComponents({match, summoner, champions, numDisplayed}:any){
    const {version} = useVersion();

    const [buttonIndex, setButtonIndex] = useState(-1);
    const [activeSection, setActiveSection] = useState("overview");

    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

    const handleButtonClick = (section:any) => {
      setActiveSection(section);
    };

    function calculateDamageStats(matches: any) {
        let matchMaxDamageDealtArray: any[] = [];
        let matchMaxDamageTakenArray: any[] = [];
      
        matches?.forEach((match: any) => {
          const maxDamageDealt = match.match.info.participants.reduce((max: any, participant: any) => {
            const damageDealt = participant.totalDamageDealtToChampions;
            return damageDealt > max ? damageDealt : max;
          }, 0);
          matchMaxDamageDealtArray.push(maxDamageDealt);
      
          const maxDamageTaken = match.match.info.participants.reduce((max: any, participant: any) => {
            const damageTaken = participant.totalDamageTaken;
            return damageTaken > max ? damageTaken : max;
          }, 0);
          matchMaxDamageTakenArray.push(maxDamageTaken);
        });
      
        return { 
          matchMaxDamageDealtArray,
          matchMaxDamageTakenArray
        };
      }

    const damageStats = calculateDamageStats(match);

    const summoners = match?.map((matchData: any) => {
        const gameType = matchData.match.info.gameMode;
        const participants = matchData.match.info.participants;
        const length = participants.length;
        if (!participants) { return null; }

        const teamB = participants.filter((p: any) => p.teamId === 100);
        const teamR = participants.filter((p: any) => p.teamId === 200);
  
        const team1 = participants.filter((p: any) => p.placement === 1);
        const team2 = participants.filter((p: any) => p.placement === 2);
        const team3 = participants.filter((p: any) => p.placement === 3);
        const team4 = participants.filter((p: any) => p.placement === 4);
        const team5 = participants.filter((p: any) => p.placement === 5);
        const team6 = participants.filter((p: any) => p.placement === 6);
        const team7 = participants.filter((p: any) => p.placement === 7);
        const team8 = participants.filter((p: any) => p.placement === 8);
        const v2teams = [team1,team2,team3,team4,team5,team6,team7,team8];

        const teamBKills = matchData.match.info.teams.find((team: { teamId: any; }) => team.teamId === 100)?.objectives.champion.kills || 0;
        const teamRKills = matchData.match.info.teams.find((team: { teamId: any; }) => team.teamId === 200)?.objectives.champion.kills || 0;

        const teamBBans = matchData.match.info.teams.find((team: { teamId: any; }) => team.teamId === 100)?.bans || 0;
        const teamRBans = matchData.match.info.teams.find((team: { teamId: any; }) => team.teamId === 200)?.bans || 0;

        const team100Win = matchData.match.info.teams.find((team: { teamId: number; }) => team.teamId === 100)?.win;
        const team200Win = matchData.match.info.teams.find((team: { teamId: number; }) => team.teamId === 200)?.win;

        const teamWinStatus = team100Win ? 'Victory' : 'Defeat';
        const teamLossStatus = team200Win ? 'Victory' : 'Defeat';

        const calculateKPRatio = (team: any[], teamKills: number) => team.map(member => ({
            ...member,
            kpRatio: teamKills > 0 ? (((member.kills + member.assists) / teamKills) * 100).toFixed(0) : "0",
            kda: ((member.kills + member.assists) / Math.max(member.deaths, 1)).toFixed(1),
            cs: member.totalMinionsKilled + member.neutralMinionsKilled,
        }));
    
        const teamBWithKPRatio = calculateKPRatio(teamB, teamBKills);
        const teamRWithKPRatio = calculateKPRatio(teamR, teamRKills);

        const team1WithKPRatio = calculateKPRatio(team1, teamBKills);
        const team2WithKPRatio = calculateKPRatio(team2, teamBKills);
        const team3WithKPRatio = calculateKPRatio(team3, teamBKills);
        const team4WithKPRatio = calculateKPRatio(team4, teamBKills);
        const team5WithKPRatio = calculateKPRatio(team5, teamBKills);
        const team6WithKPRatio = calculateKPRatio(team6, teamBKills);
        const team7WithKPRatio = calculateKPRatio(team7, teamBKills);
        const team8WithKPRatio = calculateKPRatio(team8, teamBKills);
    
        const team1Bans = teamBBans.slice(0,2);
        const team2Bans = teamBBans.slice(2,4);
        const team3Bans = teamBBans.slice(4,6);
        const team4Bans = teamBBans.slice(6,8);
        const team5Bans = teamBBans?.slice(8,10);
        const team6Bans = teamBBans?.slice(10,12);
        const team7Bans = teamBBans?.slice(12,14);
        const team8Bans = teamBBans?.slice(14,16);

        return {
            participants,
            length,
            teamBKills,
            teamWinStatus,
            teamLossStatus,
            teamB: teamBWithKPRatio,teamBBans,
            teamR: teamRWithKPRatio,teamRBans,
            gameType,
            v2teams,
            team1: team1WithKPRatio, team1Bans,
            team2: team2WithKPRatio, team2Bans,
            team3: team3WithKPRatio, team3Bans,
            team4: team4WithKPRatio, team4Bans,
            team5: team5WithKPRatio, team5Bans,
            team6: team6WithKPRatio, team6Bans,
            team7: team7WithKPRatio, team7Bans,
            team8: team8WithKPRatio, team8Bans,
        };
    });

    

    function renderTeamSection(teamName:any, teamWinStatus:any,color:any, team:any, index:number, id:string) {
        return (
            <>
                <thead>
                    <tr>
                        <th className="dark:text-white text-black text-left pl-2">{id} {teamName} <span className={`text-${color} text-[10px]`}> ({teamWinStatus})</span></th>
                        <th className="dark:text-white text-black">{currLangData.compare.kda}</th>
                        <th className="dark:text-white text-black md:table-cell hidden">{currLangData.compare.dmg}</th>
                        <th className="dark:text-white text-black">CS</th>
                        <th className="dark:text-white text-black">{currLangData.compare.items}</th>
                    </tr>
                </thead>
                <MatchOverview summoner={summoner} summoners={summoners} index={index} version={version} champions={champions} damageStats={damageStats} team={team}/>
            </>
        );
    }

    function renderGameTable(gameMode:any, currLangData:any, summoners:any, index:number) {
        if (gameMode !== "CHERRY") {
            return (
                <table className="w-full lg:w-[740px]">
                    {renderTeamSection(currLangData.compare.teamB, currLangData.compare[summoners[index]?.teamWinStatus], summoners[index]?.teamWinStatus, 'teamB', index, '')}
                    {renderTeamSection(currLangData.compare.teamR, currLangData.compare[summoners[index]?.teamLossStatus], summoners[index]?.teamLossStatus, 'teamR', index, '')}
                </table>
            );
        } else if (summoners[index].length <= 8) {
            return (
                <table className="w-full lg:w-[740px]">
                    {["Krug", "Minion"].map((team, teamIndex) => 
                        renderTeamSection(`${currLangData.compare.team} ${team}`, currLangData.compare[summoners[index]?.teamWinStatus], summoners[index]?.teamWinStatus, `team${teamIndex+1}`, index, `${teamIndex+1}.`))
                    }
                    {["Wolf", "Poro"].map((team, teamIndex) => 
                        renderTeamSection(`${currLangData.compare.team} ${team}`, currLangData.compare[summoners[index]?.teamLossStatus], summoners[index]?.teamLossStatus, `team${teamIndex+3}`, index, `${teamIndex+3}.`))
                    }
                </table>
            );
        } else {
            return (
                <table className="w-full lg:w-[740px]">
                    {["Krug", "Minion", "Wolf", "Poro"].map((team, teamIndex) => 
                        renderTeamSection(`${currLangData.compare.team} ${team}`, currLangData.compare[summoners[index]?.teamWinStatus], summoners[index]?.teamWinStatus, `team${teamIndex+1}`, index, `${teamIndex+1}.`))
                    }
                    {["Scuttle", "Gromp", "Sentinel", "Raptor"].map((team, teamIndex) => 
                        renderTeamSection(`${currLangData.compare.team} ${team}`, currLangData.compare[summoners[index]?.teamLossStatus], summoners[index]?.teamLossStatus, `team${teamIndex+5}`, index, `${teamIndex+5}.`))
                    }
                </table>
            );
        }
    }

    return(
    match?.slice(0, numDisplayed).map((match:any, index:number) => (
    <div key={index} className={`w-full lg:w-[740px] flex flex-col gap-2`}>
            <>
        <div className={`text-text flex-col lg:h-[125px] w-full lg:w-[740px] lg:rounded-md justify-between overflow-hidden ${
                        summoner[index]?.result === 'Victory' ? 'bg-Victory dark:bgl-Victory' :
                        summoner[index]?.result === 'Defeat' ? 'bg-Defeat dark:bgl-Defeat' :
                        'bg-Remake dark:bgl-Remake'}`}>
            <div className="lg:hidden flex justify-between p-1">
                <MatchInformationMobile summoner={summoner} match={match} index={index} setButtonIndex={setButtonIndex} buttonIndex={buttonIndex}/>
            </div>

            <div className="flex justify-between pl-1 pr-1 lg:pl-0 lg:pr-0">

                <div className="hidden lg:flex flex-col justify-between w-[120px] p-2 pr-0">
                    <MatchInformation summoner={summoner} match={match} index={index}/>
                </div>

                <div className="flex flex-row lg:flex-col w-[335px] lg:w-[248px] overflow-hidden pt-2 pb-2 lg:items-start items-center">
                    <div className="flex items-center justify-center"> 
                        <ChampionInformation summoner={summoner} index={index} version={version} champions={champions}/>
                    </div>

                    <div className="lg:w-[248px] w-[130px] overflow-x-scroll content-center lg:block flex lg:justify-start justify-center ">
                        <div className="flex flex-col lg:flex-row w-max items-center h-[48px] lg:h-[39px] gap-1">
                            <SummonerBadges summoner={summoner} index={index}/>    
                        </div>
                    </div>
                </div>

                <div className="lg:borderVer ml-0 lg:ml-2 flex flex-col text-xs leading-[1.5] md:text-center text-right lg:text-left justify-center w-22 lg:w-[110px] mt-2 mb-2 text-text_drk">
                    <SummonerStats summoner={summoner} index={index} summoners={summoners}/>
                </div>

                <div className="md:flex justify-end ml-2 pt-2 pb-2 gap-1 hidden w-[210px]">
                    <SummonersList version={version} summoners={summoners} index={index} champions={champions}/>
                </div>

                <div className="hidden lg:flex">
                    <ExtendButton summoner={summoner} index={index} setButtonIndex={setButtonIndex} buttonIndex={buttonIndex}/>
                </div>
            </div>

        </div>
            {buttonIndex === index && (
                <div className={`w-full lg:w-[740px] text-text flex flex-col gap-2`}>
                    
                    <div className={`flex justify-evenly items-center p-2 pt-1 pb-1 lg:rounded-md dark:text-white text-black dark:bg-secondary bg-third_light`}>
                            <button className={`pl-2 pr-2 pt-1 pb-1 text-sm rounded-md hover:bg-primary_light dark:hover:bg-primary ${activeSection === "overview" ? `dark:bg-primary bg-primary_light` : ''}`} onClick={() => handleButtonClick("overview")}>{currLangData.summonerProfile.buttons.overview}</button>
                    
                            <button className={`pl-2 pr-2 pt-1 pb-1 text-sm rounded-md hover:bg-primary_light dark:hover:bg-primary ${activeSection === "build" ? `dark:bg-primary bg-primary_light` : ''}`} onClick={() => handleButtonClick("build")}>{currLangData.summonerProfile.buttons.build}</button>
                       
                            <button className={`pl-2 pr-2 pt-1 pb-1 text-sm rounded-md hover:bg-primary_light dark:hover:bg-primary ${activeSection === "details" ? `dark:bg-primary bg-primary_light` : ''}`} onClick={() => handleButtonClick("details")}>{currLangData.summonerProfile.buttons.details}</button>

                    </div>

                    {activeSection === "overview" && (
                        <div className={`lg:rounded-md overflow-hidden bg-third_light dark:bg-secondary`}>
                            {match && renderGameTable(match.match.info.gameMode, currLangData, summoners, index)}
                        </div>
                    )}
                    {activeSection === "build" && ( 
                    <div className={`p-2 lg:rounded-md bg-third_light dark:bg-secondary`}>
                        <BuildSpellsRunes summoner={summoner} match={match} index={index} version={version} champions={champions}/>
                    </div>
                    )}
                     {activeSection === "details" && ( 
                    <div className={`p-2 lg:rounded-md bg-third_light dark:bg-secondary`}>
                        <DetailsOverview match={match} version={version} champions={champions}/>
                    </div>
                    )}
                
                </div>
            )}
            </>
    </div>
        ))
    )
}