import { useCallback, useEffect, useState } from "react";
import RegionList from "../navBar/RegionList";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import DivisionList from "./DivisionList";
import axios from "axios";
import { useVersion } from "../contexts/VersionContext";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { hosting } from "../cases/Hosting";

export default function Leaderboard(){
    const {version} = useVersion();
    const [leaderboard, setLeaderboard] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedRegion, setSelectedRegion] = useState('EUNE');
    const [showRegions, setShowRegions] = useState(false);
    const toggleRegions = () => setShowRegions(!showRegions);
    const handleRegionSelect = (region: string) => {
        setSelectedRegion(region);
        setShowRegions(false);
    };

    const [selectedDivision, setSelectedDivision] = useState('CHALLENGER');
    const [selectedTier, setSelectedTier] = useState('I');
    const [showDivision, setShowDivision] = useState(false);
    const toggleDivision = () => setShowDivision(!showDivision);
    const handleDivisionSelect = (division: string, tier: string) => {
        setSelectedDivision(division);
        setSelectedTier(tier);
        setShowDivision(false);
    };

    const handleGoClick = useCallback(async()  => {
        setLoading(true);
        try {
          const response = await axios.get(`${hosting}/leaderboard/${selectedRegion.toLowerCase()}/${selectedDivision}/${selectedTier}`);
          setLeaderboard(response.data);
          setLoading(false);
        } catch (error: any) {
          if (error.response?.data?.error) {
            console.log('lipa');
          } else {
            console.log(error);
          }
        }
      }, [selectedRegion, selectedDivision, selectedTier]);
    
      useEffect(() => {
        handleGoClick();
      }, [selectedRegion, selectedDivision, handleGoClick]);

      const skeletonCount = 19;

    return(
        <div className="w-full flex justify-center mt-[50px] overflow-hidden">
            <div className="w-full lg:w-[1080px] dark:bg-secondary bg-third_light flex flex-col items-center lg:rounded-md gap-3 overflow-hidden">
                <div className="position: relative flex gap-2 mt-3 pb-0">

                    <button onClick={toggleRegions} className="text-black w-[210px] justify-between rounded-md flex items-center bg-inputColor px-2 h-9 font-semibold text-sm">
                        {selectedRegion.toUpperCase()} {showRegions ? <MdArrowDropUp fontSize={20} /> : <MdArrowDropDown fontSize={20} />}
                    </button>
                    {showRegions && <RegionList onSelect={handleRegionSelect} />}

                    <button onClick={toggleDivision} className="text-black w-[150px] justify-between rounded-md flex items-center bg-inputColor px-2 h-9 font-semibold text-sm">
                        {selectedDivision.toUpperCase()} 
                        {selectedDivision === "CHALLENGER" || selectedDivision === "GRANDMASTER" || selectedDivision === "MASTER" ? null : ` ${selectedTier}`}
                        {showDivision ? <MdArrowDropUp fontSize={20} /> : <MdArrowDropDown fontSize={20} />}
                    </button> 
                    {showDivision && <DivisionList onSelect={handleDivisionSelect} />}

                </div>
                {!loading ? (
                <div className="flex flex-col w-full ">
                    {leaderboard.map((summoner:any, index:number)=>(
                        <div key={index} className="border-t-[1px] border-[rgb(0,0,0,0,90%)] dark:border-[rgb(255,255,255,10%)] p-2 flex justify-between items-center">
                            <div className="flex items-center">
                            <span className="w-6 md:w-9 text-text md:text-base text-sm">{index+1}.</span>
                            
                            <Link to={`/summoner/${selectedRegion.toLowerCase()}/${summoner.name.gameName}/${summoner.name.tagLine}`} className="flex items-center gap-1 hover:underline text-text">
                            <img className="w-8 h-8 rounded-md" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${summoner.summonerDetails.profileIconId}.png`} alt={summoner.summonerDetails.profileIconId}/>
                            <span className="dark:text-white text-black w-[100px] md:w-[220px] text-sm md:text-base text-ellipsis whitespace-nowrap overflow-hidden">{summoner.name.gameName}<span className="text-text">#{summoner.name.tagLine}</span></span>
                            </Link>
                            </div>

                            <div className="flex items-center gap-1">
                                <img className="w-8 h-8" src={`/tiers/${summoner.tier}.png`} alt={summoner.tier}/>
                                <span className="dark:text-white text-black text-xs">{summoner.tier}</span>          
                                {(summoner.tier !== "CHALLENGER" && summoner.tier !== "GRANDMASTER" && summoner.tier !== "MASTER") ? (<span className="text-text text-xs">{summoner.rank}</span>):(null)}
                            </div>

                            <div className="flex flex-col w-[100px]">
                                <span className="dark:text-white text-black text-sm text-end">{summoner.leaguePoints} LP</span>
                                <span className="text-text text-xs text-end"><span className="text-Victory">{summoner.wins}</span> / <span className="text-val">{summoner.losses}</span> <span className="text-text">({(summoner.wins/(summoner.wins+summoner.losses)*100).toFixed(0)}%)</span></span>
                            </div>
                        </div>
                    ))}
                </div>
                ) : (<div className="h-[900px] flex flex-col w-full">
                    {Array.from({ length: skeletonCount }, (_, index) => (
                        <div key={index} className="border-t-[1px] border-[rgb(0,0,0,0.4)] pt-2 pb-2 dark:border-[rgb(255,255,255,0.1)] flex justify-between items-center">
                        <Skeleton 
                            baseColor="#282830" 
                            height={30}
                            highlightColor="#1C1C1F"
                            borderRadius={0} 
                            containerClassName="w-[200px] flex-1 hidden dark:block"
                        />
                        <Skeleton 
                            baseColor="#FFFFFF" 
                            height={30}
                            highlightColor="#EBEEF1" 
                            borderRadius={0}
                            containerClassName="w-[200px] flex-1 block dark:hidden"
                        />
                        </div>
                    ))}
                    </div>)}
            </div>
        </div>
    )
}