import { Route, Routes } from "react-router-dom";
import Champions from "./champions/Champions";
import Champion from "./champions/champion/Champion";
import SummonerPage from "./pages/SummonerPage";
import LiveGames from "./components/LiveGames";
import Changelog from "./components/Changelog";
import Leaderboard from "./components/Leaderboard";
import MainPage from "./pages/MainPage";
import PrivPol from "./footer/PrivPol";
import Tos from "./footer/Tos";
import Faq from "./footer/Faq";

export default function App() {
  return (
      <Routes>
        <Route path="/" element={<MainPage/>}/>
        <Route path="/champions" element={<Champions/>}/>
        <Route path="/champion/:id" element={<Champion/>}/>
        <Route path="/live-games" element={<LiveGames/>}/>
        <Route path="leaderboard" element={<Leaderboard/>}/>
        <Route path="/summoner/:region/:summoner/:tagLine/*" element={<SummonerPage/>} />
        
        <Route path="faq" element={<Faq/>}/>
        <Route path="/tos" element={<Tos/>}/>
        <Route path="/privacy-policy" element={<PrivPol/>}/>
        <Route path="/changelog" element={<Changelog/>}/>
      </Routes>
  );
}