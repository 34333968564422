export default function DamageBar({summoner, damageStats, index}:any){

    return(
        <div className="flex items-center flex-col ">
        <div className="position: relative w-[100px] h-[15px] overflow-hidden rounded-t-md bg-[rgb(0,0,0,20%)]">
            <span className="position: absolute left-2 bottom-0 text-[10px] z-10 text-white">{(summoner?.totalDamageDealtToChampions).toLocaleString()}</span>
            <div className={`bg-val position: absolute left-0 h-[15px]`} style={{ width: `${((summoner?.totalDamageDealtToChampions/damageStats.matchMaxDamageDealtArray[index])*100).toFixed(0)}px` }}></div>
        </div>
        <div className="position: relative w-[100px] h-[15px] overflow-hidden rounded-b-md bg-[rgb(0,0,0,20%)]">
            <span className="position: absolute left-2 bottom-0 text-[10px] z-10 text-white">{(summoner?.totalDamageTaken).toLocaleString()}</span>
            <div className={`bg-[rgb(141,141,141,0.5)] position: absolute left-0 h-[15px]`} style={{ width: `${((summoner?.totalDamageTaken/damageStats.matchMaxDamageTakenArray[index])*100).toFixed(0)}px` }}></div>
        </div>
    </div>
    )
}