interface RegionListProps {
    onSelect: (regionId: string) => void;
  }

export default function RegionList({ onSelect }: RegionListProps) {

    const regions = [
        { id: 'eune', name: 'Europe Nordic & East', imgSrc: '/regionIcons/EUNE.svg' },
        { id: 'euw', name: 'Europe West', imgSrc: '/regionIcons/EUW.svg' },
        { id: 'na', name: 'North America', imgSrc: '/regionIcons/NA.svg' },
        { id: 'oce', name: 'Oceania', imgSrc: '/regionIcons/OCE.svg' },
        { id: 'kr', name: 'Korea', imgSrc: '/regionIcons/KR.svg' },
        { id: 'jp', name: 'Japan', imgSrc: '/regionIcons/JP.svg' },
        { id: 'br', name: 'Brazil', imgSrc: '/regionIcons/BR.svg' },
        { id: 'las', name: 'Latin America South', imgSrc: '/regionIcons/LAS.svg' },
        { id: 'lan', name: 'Latin America North', imgSrc: '/regionIcons/LAN.svg' },
        { id: 'ru', name: 'Russia', imgSrc: '/regionIcons/RU.svg' },
        { id: 'tr', name: 'Turkey', imgSrc: '/regionIcons/TR.svg' },
        { id: 'sg', name: 'Singapore', imgSrc: '/regionIcons/SG.svg' },
        { id: 'ph', name: 'Philippines', imgSrc: '/regionIcons/PH.svg' },
        { id: 'tw', name: 'Taiwan', imgSrc: '/regionIcons/TW.svg' },
        { id: 'vn', name: 'Vietnam', imgSrc: '/regionIcons/VN.svg' },
        { id: 'th', name: 'Thailand', imgSrc: '/regionIcons/TH.svg' },
    ];

    return (
        <div className="max-h-[250px] overflow-y-auto position: absolute top-[37px] bg-white w-[210px] text-black rounded-md shadow-[0_8px_12px_rgba(0,0,0,0.19)] z-30">
            {regions.map(region => (
                <button key={region.id} onClick={() => onSelect(region.id)} className="hover:bg-[#F7F7F9] flex w-full p-1 gap-2 text-[15px]">
                    <img src={region.imgSrc} alt={region.name}/>
                    <span>{region.name}</span>
                </button>
            ))}
        </div>
    );
}