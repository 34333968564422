import { Link } from "react-router-dom";
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";

export default function Footer() {
  const {language: lang} = useLanguage();
  const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  const footerLinks = [
    {
      title: "RAFTE.CH",
      links: [
        { name: `${currLangData.footer.about}`, path: "/about" },
        { name: `${currLangData.footer.changelog}`, path: "/changelog" },
        { name: `${currLangData.footer.company}`, path: "/company" }
      ]
    },
    {
      title: `${currLangData.footer.res}`,
      links: [
        { name: `${currLangData.footer.privPol}`, path: "/privacy-policy" },
        { name: `${currLangData.footer.ToS}`, path: "/tos" }
      ]
    },
    {
      title: `${currLangData.footer.more}`,
      links: [
        { name: `${currLangData.footer.contact}`, path: "/contact" },
        { name: `${currLangData.footer.advert}`, path: "/advertise" },
        { name: `${currLangData.footer.faq}`, path: "/faq" }
      ]
    }
  ];

  const year = new Date().getFullYear();

  return (
    <div className="pt-[45px]">
      <div className="footer">
        <div className="flex content-center items-center flex-col">

          <div className="flex ml-3 mr-3 md:mr-0 md:ml-0 gap-[30px]">
            {footerLinks.map((section) => (
              <ul key={section.title}>
                <li className="dark:text-white text-black font-semibold ">{section.title}</li>
                {section.links.map((link) => (
                  <li key={link.name}>
                    <Link to={link.path} className="text-text text-[12px] hover:underline" onClick={scrollToTop}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>

          <span className="borderHorTop mt-[30px] text-center p-[10px] text-text">
            <small>© 2021-{year} {currLangData.footer.info}</small>
          </span>
        </div>
      </div>
    </div>
  );
}
