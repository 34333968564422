import axios from "axios";
import { useParams } from "react-router-dom"; 
import { useEffect, useState, useRef } from "react";
import { useVersion } from "../../contexts/VersionContext";
import ChampionOverview from "./ChampionOverview";
import ChampionVideo from "./ChampionVideo";
import ChampionStats from "./ChampionStats";
import ChampionSkins from "./ChampionSkins";
import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";
interface ChampionData {
    name: string;
    title: string;
}

export default function Champion() {
    const {language: lng} = useLanguage();
    const currLangData: any = (languageData as any)[lng] || (languageData as any).en;
  
    const [champion, setChampion] = useState<ChampionData | null>(null);

    let lang:any;
    if(lng === "pl"){
        lang = "pl_PL";
    }else if(lng === "en"){
        lang = "en_US"
    }else if(lng === "es"){
        lang = "es_ES"
    }else(
        lang = "de_DE"
    );

    const { version } = useVersion();
    const { id } = useParams();
    const [background, setBackground] = useState('');
    const [borderWidths, setBorderWidths] = useState('50px');

    const containerRef = useRef<HTMLDivElement>(null);
    const h1Ref = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        if (!version) return;
        if (!id) return;

        const fetchChampion = async () => {
            try {
                const response = await axios.get(`https://ddragon.leagueoflegends.com/cdn/${version}/data/${lang}/champion/${id}.json`);
                const championData = response.data.data[id as string];
                setChampion(championData);

                setBackground(`https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${id}_0.jpg`);
            } catch (error) {
                console.log(error);
            }
        };
        fetchChampion();
    }, [version, id, lang]);

    useEffect(() => {
        const calculateBorderWidths = () => {
            if (h1Ref.current && containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth; 
                const h1Width = h1Ref.current.offsetWidth;
                let calculatedWidth;

                if (containerWidth === 1440) {
                    calculatedWidth = ((containerWidth - h1Width)/2)-200;
                    }else{
                    calculatedWidth = (containerWidth - h1Width-80)/2;}

                calculatedWidth = Math.max(calculatedWidth, 20);
            
                setBorderWidths(`${calculatedWidth}px`);
            }
        };

        calculateBorderWidths();
        window.addEventListener('resize', calculateBorderWidths);

        return () => window.removeEventListener('resize', calculateBorderWidths);
    });

    if (!champion) return <div>Loading...</div>;

    return (
        <>
            <div className="max-h-[900px] flex items-start justify-center position: relative overflow-hidden dark:bg-primary bg-primary">
                <img src={background} alt={`${champion.name} Splash Art`} className="blur-[10px] w-full"/>

                    <div ref={containerRef} className="flex justify-center items-center w-full rt:w-[1440px] position: absolute overflow-hidden">
                        <img src={background} alt={`${champion.name} Splash Art`} className="rt:w-full w-[calc(100%-60px)] rounded-t-md mt-[20px]" />
                        <div className="position: absolute left-0 rt:max-w-[1440px] w-full bottom-[20px] rt:bottom-[65px] px-[10px] py-[5px] flex items-center flex-col z-10 ">
                            <h1 ref={h1Ref} className=" text-[calc(30px+1.5vw)] rt:text-[100px] font-bold leading-[0.6] italic championText uppercase w-fit text-white">{champion.name}</h1>
                            <span className=" text-[calc(15px+0.5vw)] rt:text-[30px] font-base uppercase championText text-white">{champion.title}</span>
                        </div>
                    </div>

                <div className="position: absolute content-[' '] w-[1440px] rt:w-full bottom-0 bg-[linear-gradient(transparent,#EBEEF1_70%)] dark:bg-[linear-gradient(transparent,#1C1C1F_70%)] h-[100px] rt:h-[200px]"></div>

                    <div className="position: absolute bottom-[-30px] w-[calc(100%-60px)] rt:w-[1080px] border-x-[1px] h-[110px] rt:h-[200px] z-10 flex justify-between border-text">
                        <div className="border-t-[1px] border-text" style={{width: borderWidths}}></div>
                        <div className="border-t-[1px] border-text" style={{width: borderWidths}}></div>
                    </div>

            </div>
            <ChampionOverview champion={champion} currLangData={currLangData}/>
            <ChampionVideo champion={champion} version={version} currLangData={currLangData}/>
            <ChampionStats champion={champion} currLangData={currLangData}/>
            <ChampionSkins champion={champion}/>
        </>
    );
}
