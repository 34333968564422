import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useVersion } from "../contexts/VersionContext";
import { gameDuration, timeElapsedString } from "../cases/GameTimer";
import { ClipLoader } from "react-spinners";
import { hosting } from "../cases/Hosting";

export default function CreatorProfile(){
    const {version} = useVersion();
    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;
    
    const [summonerData, setSummonerData] = useState<any | null>(null);
    const [matches, setMatches] = useState<any>([]);
    const [champions, setChampions] = useState<any>({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {

        const fetchData = async () => {
          try {
            setLoading(true);
            const response = await axios.get(`${hosting}/summoner/eune/Ëren%20Yaeger/EUNE`);
            const match = await axios.get(`${hosting}/matches/eune/Ëren%20Yaeger/EUNE`);
            const champions = await axios.get(`${hosting}/api/fetchAllChampionNames`);
            const summonerData = response.data;
            const summonerMatches = match.data;
            
            const champs = champions.data.reduce((acc:any, champ:any) => {
                acc[champ.champKey] = { name: champ.Name, displayName: champ.champName, position: champ.positionMain };
                return acc;
              }, {});
            setChampions(champs);

            setSummonerData(summonerData);
            setMatches(summonerMatches);
            setLoading(false);
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchData();
        }, []);

        const summoner = matches?.matches?.slice(0,5).map((matchData: any) => {
        const gameDur = matchData?.match.info.gameDuration;
        const gameEnd = matchData?.match.info.gameEndTimestamp;
        const participant = matchData?.match.info.participants.find((p: any) => p.puuid === summonerData?.summonerInfo.puuid);
        if (!participant) {return null;}
        const win = participant.win;
        const remake = participant.gameEndedInEarlySurrender;
        const matchResult = remake ? "Remake" : win ? "Victory" : "Defeat";
        const champion = participant.championId;
        const champLvl = participant.champLevel;
        const kills = participant.kills;
        const assists = participant.assists;
        const deaths = participant.deaths;
        const kda = ((kills + assists) / Math.max(deaths, 1)).toFixed(1);
        return({matchResult,champion,kills,assists,deaths,gameDur,gameEnd, champLvl, kda})
        });


    return(
        <div className={`md:w-[660px] w-full h-[315px] md:h-[160px] gap-5 md:gap-0 bg-primary_l_trans dark:bg-primary_trans p-2 rounded-none md:rounded-md shadow-md flex flex-col md:flex-row items-center BoxShadow ${loading ? "justify-center":"justify-between"}`}>
        {loading ? (<ClipLoader color="#85417C"/>):(
            <>
            <div className="flex items-start">
            <div className="position: relative bg-primary_light dark:bg-primary p-[5px] rounded-[11px] h-[130px] w-[110px] flex justify-center shadow-[0_0_20px_-10px_rgba(0,0,0,0.4)] dark:shadow-[0_0_20px_-10px_rgba(255,255,255,0.3)]">
                <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${summonerData?.summonerInfo.profileIconId}.png`} alt={summonerData?.summonerInfo.profileIconId} className="w-[100px] h-[100px] rounded-md"/>
                <div className=" position: absolute bottom-[3px] left-[0px] flex justify-center w-full">
                    <span className="flex justify-center bg-primary_light dark:bg-primary text-[12px] rounded-md dark:text-text text-black">{currLangData.level} {summonerData?.summonerInfo.summonerLevel}</span>
                </div>
            </div>

            <div className="flex flex-col ml-3 h-[130px] leading-8">
                <span className="font-bold dark:text-white text-black text-[26px] max-w-[200px] text-ellipsis whitespace-nowrap overflow-hidden">{summonerData?.gameName}</span>
                <span className="text-text_light dark:text-text italic text-sm" dangerouslySetInnerHTML={{__html: currLangData.main.creator}}></span>

                <span className='text-text_light dark:text-text text-[14px] flex items-center gap-1 h-7 uppercase'>
                        {summonerData?.summonerRank[0]?.tier === undefined ? (null) : (<img className="w-7 h-7" src={`/tiers/${summonerData?.summonerRank[0]?.tier}.png`} alt={summonerData?.summonerRank[0]?.tier}/>)}
                        {summonerData?.summonerRank[0]?.tier ? (
                            ["GRANDMASTER", "CHALLENGER", "MASTER"].includes(summonerData?.summonerRank[0].tier) ?
                            `${summonerData.summonerRank[0].tier} (${summonerData?.summonerRank[0].leaguePoints} LP)` :
                            `${currLangData.ranks[(summonerData?.summonerRank[0].tier).toLowerCase()]} ${summonerData?.summonerRank[0].rank} (${summonerData?.summonerRank[0].leaguePoints} LP)`
                        ) : `${currLangData.ranks.ur}`}
                </span>

                <Link className="bg-pink px-3 py-2 text-white w-fit text-sm rounded-md mt-[14px]" to={`/summoner/eune/${summonerData?.gameName}/${summonerData?.tagLine}`}>{currLangData.main.vp}</Link>
            </div>
        </div>

            <div className="h-[145px]  overflow-y-scroll rounded-md">
                {summoner?.map((match:any, index:number)=>(
                    <div key={index} className={`first:mt-0 mt-1 w-[270px] h-[60px] rounded-md p-1 flex items-center justify-between  ${match?.matchResult === 'Victory' ? 'bg-Victory dark:bgl-Victory' : match?.matchResult === 'Defeat' ? 'bg-Defeat dark:bgl-Defeat' : 'bg-Remake dark:bgl-Remake'} `}>
                        
                        <div className="flex items-center gap-2">
                        <div className="overflow-hidden rounded-[8px] w-[42px] h-[42px] position: relative bg-primary p-[2px] flex items-start justify-center">
                            <Link to={`/champion/${champions[match?.champion]?.name}`} target="_blank" className="overflow-hidden rounded-md"><img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[match?.champion]?.name}.png`} alt={champions[match?.champion]?.name} className="scale-[1.1]"/></Link>
                            <span className="text-white bg-[rgb(28,28,31,0.8)] position: absolute top-0 left-0 rounded-br-md text-[12px] w-[25px] text-center font-semibold">{match?.champLvl}</span>
                        </div>

                        <div className="flex flex-col justify-between items-start text-xs text-white w-[110px]">
                            <span className="text-black dark:text-white">{gameDuration(match.gameDur)}</span>
                            <span className={`text-${match?.matchResult} text-[12px]`}>{currLangData.compare[match?.matchResult]}</span>
                            <span className="text-text_light dark:text-text">{timeElapsedString(match.gameEnd, false, currLangData)}</span>
                        </div>
                        </div>

                        <div className="flex flex-col leading-[15px] text-xs text-text_light dark:text-text text-center w-[75px]">
                            <span className="ml-2 font-semibold"><span className="dark:text-white text-black">{match?.kills}</span> / <span className="text-val">{match?.deaths}</span> / <span className="dark:text-white text-black">{match?.assists}</span></span>
                            <span className="ml-2">{match?.kda !== (match?.kills+match?.assists).toFixed(1) ? (<span>{match?.kda}:1 {currLangData.compare.kda}</span>) : (<span>{currLangData.compare.perfect}</span>)}</span>
                        </div>
                    </div>
                 ))} 
            </div>
            </>
            )}
        </div>     
    )
}