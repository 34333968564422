import { useEffect, useRef, useState } from "react";
import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";
interface Skin {
    num: string;
    name: string;
  }
  

export default function ChampionSkins({ champion }: { champion: any }){
    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;
  
    const scrollableContentRef = useRef<HTMLDivElement>(null);
    const [selectedSkin, setSelectedSkin] = useState("");

    useEffect(() => {
      const defaultSkin = champion.skins[0].num;
      setSelectedSkin(defaultSkin);
    }, [champion]);
  
    const handleSkinSelection = (skinId: any) => {
        setSelectedSkin(skinId);
      
        if (scrollableContentRef.current) {
            const selectedButton = scrollableContentRef.current.querySelector(`button[data-skin="${skinId}"]`);
        
            if (selectedButton instanceof HTMLElement) {
                const containerWidth = scrollableContentRef.current.offsetWidth;
                const buttonWidth = selectedButton.offsetWidth;
                const buttonOffsetLeft = selectedButton.offsetLeft;
        
                scrollableContentRef.current.scrollTo({
                    left: buttonOffsetLeft - (containerWidth - buttonWidth) / 2,
                    behavior: "smooth",
                });
            } 
        }        
      };

return(
    <div className="w-full flex justify-center my-10 position: relative overflow-hidden bg-primary items-center">
    <img src={`https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${champion.id}_${selectedSkin}.jpg`} alt={champion.id} className="position: absolute w-full blur-[8px] "/>
    <div className="w-full flex flex-col items-center rt:w-[900px] position: relative overflow-hidden shadow-[0_0_10px_#1C1C1F]">
        {champion.skins.map((skin: Skin)=>(
        <img style={{ display: selectedSkin === skin.num ? "block" : "none" }} className={`w-[calc(100%-30px)] rt:h-[550px] rt:w-[1100px] rounded-b-md md:rounded-none ${selectedSkin === skin.num ? "opacity-100" : "opacity-0"} `} key={skin.num} src={`https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${champion.id}_${skin.num}.jpg`} alt={champion.id} />
        ))}
        <div className="rt:w-full md:w-[calc(100%-30px)] overflow-x-scroll md:absolute bottom-0 h-[130px] md:bg-[rgba(0,0,0,0.3)] unset w-[calc(100%-10px)]"  ref={scrollableContentRef}>
            <div className="flex flex-row w-fit overflow-auto md:gap-[30px] gap-[10px]">
            {champion.skins.map((skin: Skin)=>(

                    <button
                    onClick={() => handleSkinSelection(skin.num)}
                    data-skin={skin.num}
                    key={skin.num}
                    className="h-max appearance-none bg-transparent py-[10px] text-white uppercase text-[17px]">

                    <div className="w-[150px] flex flex-col items-center">
                        <div className="overflow-hidden rounded-md">
                        <img src={`https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${champion.id}_${skin.num}.jpg`} alt={champion.id} className={` object-cover shadow-[0_2px_10px_black] ease-in duration-100 scale-[1.1] hover:scale-[1] ${selectedSkin === skin.num ? "w-[60px] h-[60px] md:w-[70px] md:h-[70px] scale-[1]" : "w-[50px] h-[50px] md:w-[60px] md:h-[60px]"}`}/>
                        </div>
                        <span className="text-[12px] font-semibold skinText">{skin.name === "default" ? (currLangData.champion.default):(skin.name)}</span>
                    
                    </div>
                </button>

            ))}
            </div>
        </div>
    </div>
    </div>
)

}