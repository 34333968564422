import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";
export default function ChampionTooltips({champion}: any) {
  const {language: lang} = useLanguage();
  const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

  const renderTips = (tips: string[], prefix: string) => {
    if (tips.length === 0) {
      return null;
    }

    return (
      <>
        <span className="text-[20px] dark:text-white text-black">{prefix} {champion.name}</span>
        {tips.map((tip, index) => (
          <p key={index} className="text-text"><span className="dark:text-white text-black">-</span> {tip}</p>
        ))}
      </>
    );
  };

  return (
    (champion.allytips.length > 0 || champion.enemytips.length > 0) ? (
      <div className="p-10 rt:p-0 max-w-[600px]">
        {renderTips(champion.allytips, `${currLangData.champion.playinAs}`)}
        {renderTips(champion.enemytips, `${currLangData.champion.playinAg}`)}
      </div>
    ) : null
  );
}
