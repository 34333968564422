export function title(titId:string){
    switch(titId){
      default: return "Work In Progress";
      case "": return "";
      case "1": return "Apprentice";
      case "40210604": return "Pentakiller";
      case "20230204": return "Prodigy";
      case "50400005": return "Treasurer";
      case "20330103": return "Lone Wolf";
      case "40130404": return "Mid Diff";
      case "20210104": return "Spitfire";
      case "20330005": return "Maverick";
      case "10320303": return "Flashy";
      case "20310203": return "Fancy Feet";
      case "20330204": return "Lucky";
      case "30220204": return "Lumberjack";
      case "10310005": return "Stylish";
      case "40210903": return "Bloodthirsty";
      case "51001104": return "Fashionista";
      case "40210005": return "Executioner";
      case "40240802": return "Bounty Hunter";
      case "20320104": return "Absolute Unit";
      case "10320704": return "Ace";
      case "30110203": return "Early Bird";
      case "30120005": return "Courageous";
      case "30110102": return "Epic";
      case "30120104": return "Comeback Kid";
      case "20420202": return "Ward Warden";
      case "30230103": return "Fanatic";
      case "30120203": return "Uninhibited";
      case "10320103": return "Alcove Gamer";
      case "50500005": return "Collector";
      case "50400204": return "Iconic";
      case "10120601": return "Poroyalty";
      case "40240603": return "Mythic";
      case "30130503": return "Jitterbug";
      case "10320503": return "Survivor";
    }
  }