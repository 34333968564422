import { useState, useEffect } from 'react';
import axios from 'axios';
import { mapType, spell } from "../cases/MapsSpells";
import GameTimer from "../cases/GameTimer";
import { Link, useParams } from "react-router-dom";
import { useVersion } from "../contexts/VersionContext";
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import useRunes from '../cases/UseRunes';
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";
import { hosting } from '../cases/Hosting';

export default function SummonerLive({ live, summonerData, load} : any) {
  const { version } = useVersion();
  const { region } = useParams();
  const [champions, setChampions] = useState<any>({});
  const { getRuneStyleImageUrl } = useRunes();

  const {language: lang} = useLanguage();
  const currLangData: any = (languageData as any)[lang] || (languageData as any).en;


  useEffect(() => {
    const fetchChampions = async () => {
      try {
        const response = await axios.get(`${hosting}/api/fetchAllChampionNames`);
        const champs = response.data.reduce((acc:any, champ:any) => {
          acc[champ.champKey] = { name: champ.Name, displayName: champ.champName, position: champ.positionMain };
          return acc;
        }, {});
        setChampions(champs);
      } catch (error) {
        console.error('Failed to fetch champions:', error);
      }
    };

    fetchChampions();
  }, []);

  const buildParticipantDataWithRank = (participant: any, rank: any, bans: any) => {
    const champKey = participant.championId.toString();
    const championData = champions[champKey];
    return {...participant,rank,championData,bans};
  };

  const positionOrder = ['Top', 'Jungle', 'Mid', 'Bottom', 'Support'];

  const liveGameData = live?.participants?.map((participant:any, index:number) => {
    return buildParticipantDataWithRank(participant, live?.participants[index]?.rank, live?.bannedChampions[index]);
  }).filter((participant: { championData: any; }) => participant.championData) 
    .sort((a:any, b:any) => {
      if (a.teamId !== b.teamId) return a.teamId - b.teamId;

      const posA = positionOrder.indexOf(champions[a.championId.toString()]?.position.trim());
      const posB = positionOrder.indexOf(champions[b.championId.toString()]?.position.trim());

      return posA - posB;
    });
    

  return(
    load ? (        <div className="w-full flex flex-col justify-center items-center p-10 h-[800px]">
                        <AiOutlineExclamationCircle className='w-[60px] h-[60px] md:w-[100px] md:h-[100px] text-text'/>
                        <p className='text-[15px] md:text-[25px] text-text'>{summonerData?.gameName}#{summonerData?.tagLine} {currLangData.offline}</p>
                    </div>)
                     :
        <div className="w-full flex justify-center">
        <div className="w-[1080px] bg-secondary mt-[50px] lg:rounded-md p-3 pb-0 text-white">
            <div className="w-full flex justify-between borderHor">
              <span>{mapType(live?.gameQueueConfigId)}</span>
              <GameTimer startTime={live?.gameStartTime / 1000} />
            </div>
            {liveGameData?.map((data: any, index: number) => (
            <div key={index} className={`flex pt-1 pb-2 gap-2 borderHor${data.teamId} justify-between smLive:flex-row flex-col`}>
                <div className='flex gap-1 items-center'>

                <Link target="_blank" to={`/champion/${data.championData.name}`} className='rounded-md overflow-hidden md:w-12 md:h-12 w-10 h-10'>
                  <img className="scale-[1.1]" alt={data.championData.name} src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${data.championData.name}.png`}/>
                </Link>

                <div className='md:w-[48px] md:h-[48px] w-[40px] h-[40px] rounded-md flex flex-row flex-wrap justify-center items-start gap-px'>

                    <div className='md:w-[23px] md:h-[23px] w-[19px] h-[19px] rounded-md overflow-hidden'>
                      <img alt={data.spell1Id} src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/spell/${spell(data.spell1Id)}.png`}/>
                    </div>

                    <Link to={`/summoner/${region}/${data.riotId.split('#')[0]}/${data.riotId.split('#')[1]}`} className='rounded-md overflow-hidden md:w-[23px] w-[19px] bg-[#00000080]'>
                      <img className="scale-[1.1]" alt={data.profileIconId} src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${data.profileIconId}.png`}/>
                      </Link>
                    
                    <div className='md:w-[23px] md:h-[23px] w-[19px] h-[19px] rounded-md overflow-hidden'>
                      <img alt={data.spell2Id} src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/spell/${spell(data.spell2Id)}.png`}/>
                    </div>
                
                    {champions[data.bans?.championId.toString()]?.name ? (                    
                    <Link target="_blank" to={`/champion/${champions[data.bans?.championId.toString()]?.name}`} className='rounded-md overflow-hidden md:w-[23px] w-[19px] bg-[#00000080] border-val border-[1px]'>
                        <img className="scale-[1.1] grayscale" alt={champions[data.bans?.championId.toString()]?.name} src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[data.bans?.championId.toString()]?.name}.png`}/>
                    </Link>):(
                      <div className='rounded-md overflow-hidden md:w-[23px] w-[19px] bg-[#00000080] border-val border-[1px]'>
                        <img className="scale-[1.1] grayscale" alt={`Not banned`} src={`/noicon.jpg`}/>
                      </div>
                    )}
                </div>

                <div className=' flex flex-col justify-center h-[48px]'>
                  <Link to={`/summoner/${region}/${data.riotId.split('#')[0]}/${data.riotId.split('#')[1]}`} className={`w-[130px] h-[24px] text-[15px] overflow-hidden block text-ellipsis whitespace-nowrap ${data.puuid !== summonerData?.summonerInfo.puuid ? (null) : ('font-bold')}`}>{data.riotId.split('#')[0]}</Link>

                    <span className='text-text text-[12px] flex items-center gap-1 h-6 uppercase'>
                    {data.rank?.tier === undefined ? (null) : (<img className="w-6 h-6" src={`/tiers/${data.rank?.tier}.png`} alt={data.rank?.tier}/>)}
                    {data.rank?.tier ? (
                        ["GRANDMASTER", "CHALLENGER", "MASTER"].includes(data.rank.tier) ?
                        `${data.rank.tier} (${data.rank.leaguePoints} LP)` :
                        `${currLangData.ranks[(data.rank.tier).toLowerCase()]} ${data.rank.rank} (${data.rank.leaguePoints} LP)`
                    ) : `${currLangData.ranks.ur}`}
                    </span>
                </div>

                </div>

                <div className='flex items-center gap-1'>
                    <img alt={data.perks.perkIds[0]} className='w-[35px] h-[35px] rounded-md bg-[#00000030]' src={getRuneStyleImageUrl(data.perks.perkIds[0])}/>
                    <img alt={data.perks.perkIds[1]} className='w-[26px] h-[26px] rounded-full' src={getRuneStyleImageUrl(data.perks.perkIds[1])}/>
                    <img alt={data.perks.perkIds[2]} className='w-[26px] h-[26px] rounded-full' src={getRuneStyleImageUrl(data.perks.perkIds[2])}/>
                    <img alt={data.perks.perkIds[3]} className='w-[26px] h-[26px] rounded-full' src={getRuneStyleImageUrl(data.perks.perkIds[3])}/>

                    <img alt={data.perks.perkSubStyle} className='w-[26px] h-[26px] rounded-md bg-[#00000030] p-px' src={getRuneStyleImageUrl(data.perks.perkSubStyle)}/> 
                    <img alt={data.perks.perkIds[4]} className='w-[26px] h-[26px] rounded-full' src={getRuneStyleImageUrl(data.perks.perkIds[4])}/>
                    <img alt={data.perks.perkIds[5]} className='w-[26px] h-[26px] rounded-full' src={getRuneStyleImageUrl(data.perks.perkIds[5])}/>
                </div>
                
            </div>
            ))}
        </div>
        </div>



    )

  
}

