import {ImArrowRight2} from "react-icons/im";
import { unixTimeToMinutes } from "../../cases/GameTimer";
import { useEffect, useState } from "react";
import axios from "axios";
import useRunes from "../../cases/UseRunes";
import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";


export default function BuildSpellsRunes({summoner, match, index, version, champions}:any){
    const [champion, setChampion] = useState<any>([]);
    const { getRuneStyleImageUrl } = useRunes();

    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;
  

    const spells = ['Q', 'W', 'E', 'R'];

    const matchEvents:any = {
        itemPurchased: [],
        skillLevelUp: [],
        champion: [],
      };
    
    const itemPurchasesDict:any = [];
    const runesHolder:any = [];

    useEffect(() => {
        if (!version) return;
        if (!matchEvents.champion[0]) return;
    
        const fetchChampion = async () => {
            try {
                const response = await axios.get(`https://ddragon.leagueoflegends.com/cdn/${version}/data/en_US/champion/${matchEvents.champion[0]}.json`);
                const championData = response.data.data[matchEvents.champion[0] as string];
                setChampion(championData);
            } catch (error) {
                console.log(error);
            }
        };
        fetchChampion();
    },[matchEvents.champion, version]);

    const participant = match?.timeline.info.participants.find(
        (p:any) => p.puuid === summoner[index]?.participant.puuid
      );

      if (participant) {
        const partNum = participant.participantId;
        let prevTimeStamp = null;
      
        for (let i = 0; i < match.timeline.info.frames.length; i++) {
          const frame = match.timeline.info.frames[i];
          for (let j = 0; j < frame.events.length; j++) {
            const event = frame.events[j];
            if (event.participantId === partNum) {
              const item = event.itemId;
              const type = event.type;
              const skill = event.skillSlot;
              const level = event.levelUpType;
              const timeStamp = event.timestamp;
      
              if (type === "SKILL_LEVEL_UP" && level === "NORMAL" ) {
                matchEvents.skillLevelUp.push({
                  key: matchEvents.skillLevelUp.length,
                  values: [skill],
                });
              } else if (type === "ITEM_PURCHASED") {
                if (
                  itemPurchasesDict[timeStamp] &&
                  itemPurchasesDict[timeStamp].includes(item)
                ) {
                  continue;
                }
      
                if (
                  prevTimeStamp &&
                  Math.abs(timeStamp - prevTimeStamp) <=  60 * 1000
                ) {
                  if (itemPurchasesDict[prevTimeStamp]) {
                    itemPurchasesDict[prevTimeStamp].push(item);
                  }
                } else {
                  itemPurchasesDict[timeStamp] = [timeStamp,item];
                  matchEvents.itemPurchased.push({
                    key: matchEvents.itemPurchased.length,
                    values: [timeStamp, item],
                  });
                }
      
                prevTimeStamp = timeStamp;
              }
            }
          }
        }
      }


      const part = match?.match.info.participants.find(
        (p:any) => p.puuid === summoner[index]?.participant.puuid
      );
    
      if(part){
        const perk1 = part.perks.styles[0].selections[0].perk;
        const perk2 =  part.perks.styles[0].selections[1].perk;
        const perk3 =  part.perks.styles[0].selections[2].perk;
        const perk4 =  part.perks.styles[0].selections[3].perk;
        const perk5 =  part.perks.styles[1].selections[0].perk;
        const perk6 =  part.perks.styles[1].selections[1].perk;
        const perkStyle = part.perks.styles[0].style;
        const perkSubStyle =  part.perks.styles[1].style;
    
        const championId = part.championId;
        const championName = champions[championId]?.name;


        const array = [perk1,perk2,perk3,perk4,perk5,perk6, perkStyle,perkSubStyle];

        runesHolder.push(array);

        matchEvents.champion.push(championName, championId);
      }

      const skillLevelUpEvents = matchEvents?.skillLevelUp.map((event:any) => {
        return {
          skillSlot: event.values[0],
        };
      });

    return(
        <div>
            <div>
                <div className="borderHor mb-2"><span className="text-sm">{currLangData.compare.buildO}</span></div>
                <div className="flex justify-center">
                    <div className="flex flex-wrap justify-center lg:w-11/12">
                        {itemPurchasesDict.map((item:any, index:number) => (
                        <div key={index} className="flex-col mb-2 itemsHolder">
                            <div className="flex items-center ">
                            <div className="w-5 h-5 dark:bg-primary bg-primary_light flex justify-center items-center ItemsArrow"><ImArrowRight2 className="text-text"/></div>
                            <div className="text-center p-1 pb-0 dark:bg-primary bg-primary_light rounded-[10px]">
                            <div className="flex justify-center gap-1">
                                {item.slice(1).map((itemId:number, index: number) => (
                                    <img key={index} className="w-8 h-8 rounded-md" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/item/${itemId}.png`} alt={`${itemId}`}/>
                                ))}
                            </div>
                                <span className="text-xs dark:text-text text-black">{unixTimeToMinutes(item[0])}</span>
                            </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
            <div>
                <div className="borderHor mb-2"><span className="text-sm">{currLangData.compare.spellO}</span></div>
                <div className="flex justify-center">
                    <div className="lg:w-11/12 flex gap-2 flex-wrap justify-center">
                        {skillLevelUpEvents.map((skill:any,index:number) => {
                            const spellImgUrl = `https://ddragon.leagueoflegends.com/cdn/${version}/img/spell/${champion?.spells?.[skill?.skillSlot-1]?.image?.full}`;
                            return(
                            <div key={index} className={`position: relative ${(skill?.skillSlot === 4) ? 'bg-pink' : 'dark:bg-primary bg-primary_light'} p-1 rounded-[10px]`}>
                                <img src={spellImgUrl} className="w-10 h-10 rounded-md" alt={'spell'}/> 
                                <div className={`position: absolute top-[-5px] left-[-5px] ${(skill?.skillSlot === 4) ? 'bg-pink' : 'dark:bg-primary bg-primary_light'} w-4 h-4 rounded-full flex justify-center items-center `}>
                                    <span className={`text-[10px] ${(skill?.skillSlot === 4) ? 'text-white' : 'dark:text-white text-black'}`}>{spells[skill.skillSlot-1]}</span>
                                </div>
                                <div className={`position: absolute bottom-[-5px] right-[-5px] ${(skill?.skillSlot === 4) ? 'bg-pink' : 'dark:bg-primary bg-primary_light'} w-4 h-4 rounded-full flex justify-center items-center`}>
                                    <span className={`text-[10px] ${(skill?.skillSlot === 4) ? 'text-white' : 'dark:text-white text-black'}`}>{index+1}</span>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {summoner[index].gameMode !== "CHERRY" ? (
            <div>
                <div className="borderHor mb-2"><span className="text-sm">{currLangData.compare.runes}</span></div>
                <div className="flex justify-center">
                    <div className="lg:w-11/12 flex gap-2 flex-wrap justify-center">
                        <div className='flex items-center gap-1'>
                            <img alt={runesHolder[0][0]} className='w-[50px] h-[50px] rounded-md bg-[#00000030]' src={getRuneStyleImageUrl(runesHolder[0][0])}/>
                            <img alt={runesHolder[0][1]} className='w-[36px] h-[36px] rounded-full' src={getRuneStyleImageUrl(runesHolder[0][1])}/>
                            <img alt={runesHolder[0][2]} className='w-[36px] h-[36px] rounded-full' src={getRuneStyleImageUrl(runesHolder[0][2])}/>
                            <img alt={runesHolder[0][3]} className='w-[36px] h-[36px] rounded-full' src={getRuneStyleImageUrl(runesHolder[0][3])}/>

                            <img alt={runesHolder[0][7]} className='w-[36px] h-[36px] rounded-md bg-[#00000030] p-px' src={getRuneStyleImageUrl(runesHolder[0][7])}/> 
                            <img alt={runesHolder[0][4]} className='w-[36px] h-[36px] rounded-full' src={getRuneStyleImageUrl(runesHolder[0][4])}/>
                            <img alt={runesHolder[0][5]} className='w-[36px] h-[36px] rounded-full' src={getRuneStyleImageUrl(runesHolder[0][5])}/>
                        </div>
                    </div>
                </div>
            </div>
            ) : (
              <div>
                <div className="borderHor mb-2"><span className="text-sm">{currLangData.compare.augs}</span></div>
                <div className="flex justify-center">
                    <div className="lg:w-11/12 flex gap-2 flex-wrap justify-center">
                        <div className='flex items-center gap-1'>
                        {summoner[index].participant?.playerAugment1 !== 0 ? (
                            <img className="w-[48px] h-[48px] rounded-md bg-black" src={`https://cdn5.xdx.gg/augment48/${summoner[index].participant?.playerAugment1}.webp`} alt={'player augment 1'}/>
                        ) : (
                            <div className="w-[48px] h-[48px] rounded-md bg-[#00000030]"></div>
                        )}
                        {summoner[index].participant?.playerAugment2 !== 0 ? (
                            <img className="w-[48px] h-[48px] rounded-md bg-black" src={`https://cdn5.xdx.gg/augment48/${summoner[index].participant?.playerAugment2}.webp`} alt={'player augment 2'}/>
                        ) : (
                            <div className="w-[48px] h-[48px] rounded-md bg-[#00000030]"></div>
                        )}
                        {summoner[index].participant?.playerAugment3 !== 0 ? (
                            <img className="w-[48px] h-[48px] rounded-md bg-black" src={`https://cdn5.xdx.gg/augment48/${summoner[index].participant?.playerAugment3}.webp`} alt={'player augment 3'}/>
                        ) : (
                            <div className="w-[48px] h-[48px] rounded-md bg-[#00000030]"></div>
                        )}
                        {summoner[index].participant?.playerAugment4 !== 0 ? (
                            <img className="w-[48px] h-[48px] rounded-md bg-black" src={`https://cdn5.xdx.gg/augment48/${summoner[index].participant?.playerAugment4}.webp`} alt={'player augment 4'}/>
                        ) : (
                            <div className="w-[48px] h-[48px] rounded-md bg-[#00000030]"></div>
                        )}
                            
                        </div>
                    </div>
                </div>
            </div>
            ) }
        </div>
    )
}