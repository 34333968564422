import { createContext, useContext, useState } from 'react';

const LanguageContext = createContext<any>(null);

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }:any) => {
    const [language, setLanguage] = useState(() => {
        const storedLang = localStorage.getItem('language');
        if (storedLang) {
            return storedLang;
        } else {
            localStorage.setItem('language', 'en');
            return 'en';
        }
    });

    const toggleLanguage = (value:any) => {
        localStorage.setItem('language', value);
        setLanguage(value);
    };

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};