import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './globals.css';
import { VersionProvider } from './contexts/VersionContext';
import NavBar from './navBar/NavBar';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemesContext';
import Footer from './components/Footer';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-circular-progressbar/dist/styles.css';
import { LayoutProvider } from './contexts/LayoutContext';
import { ExpandProvider } from './contexts/ExpandContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { FontFamilyProvider } from './contexts/FontContext';
import { CookieConsentProvider } from './contexts/CookieContext';
import CookiePopup from './components/CookiePopup';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
  <BrowserRouter>
    <ThemeProvider>

    <LanguageProvider>
      <LayoutProvider>
        <ExpandProvider>
          <VersionProvider>
            <FontFamilyProvider>
              <CookieConsentProvider>

                <NavBar/>
                <App />
                <CookiePopup/>
                <Footer/>
            
              </CookieConsentProvider>
            </FontFamilyProvider>
          </VersionProvider>
        </ExpandProvider>
      </LayoutProvider>
    </LanguageProvider>

    </ThemeProvider>
  </BrowserRouter>
  </React.StrictMode>
);

