import {RiArrowDownSLine} from "react-icons/ri";

export default function ExtendButton({summoner,index,setButtonIndex, buttonIndex}:any){

    const toggleInfo = (index:number) => {
        setButtonIndex(buttonIndex === index ? -1 : index);
    };

    return(
        <div>
            <button onClick={() => toggleInfo(index)} className={`h-[125px] w-8 flex justify-center items-end ${
                                                                 summoner[index]?.result === 'Victory' ? 'button-Victory dark:buttonl-Victory' :
                                                                 summoner[index]?.result === 'Defeat' ? 'button-Defeat dark:buttonl-Defeat' :
                                                                 'button-Remake dark:buttonl-Remake'}`}>
                <RiArrowDownSLine className={`w-6 h-6 ${buttonIndex === index ? 'rotate-180' : null} text-${summoner[index]?.result}`}/>
            </button>
        </div>
    )
}