import { Link, useParams } from "react-router-dom";
import { formatPlacement } from "../../cases/GameTimer";

export default function SummonersList({version, summoners, index, champions}:any){
    const {region, summoner: sum} = useParams();
    return(
        summoners[index]?.gameType !== "CHERRY" ? (
        <>
        <div className="w-[80px] flex flex-col justify-between">
            {summoners[index]?.teamB?.map((summoner:any, index: number) => {
                return(
                    <Link key={index} to={`/summoner/${region}/${summoner.riotIdGameName}/${summoner.riotIdTagline}`} className="text-xs flex hover:underline">
                        <img className="w-4 rounded-md mr-1" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[summoner.championId]?.name}.png`} alt={champions[summoner.championId]?.name}/>
                        <span className={`text-ellipsis whitespace-nowrap overflow-hidden  ${summoner.riotIdGameName === sum ? ('text-black dark:text-white'):('text-text_drk')}`}>{summoner.riotIdGameName}</span>
                    </Link>
                )
            })}
        </div>
        <div className="w-[80px] flex flex-col justify-between">
            {summoners[index]?.teamR?.map((summoner:any, index: number) => {
                return(
                    <Link key={index} to={`/summoner/${region}/${summoner.riotIdGameName}/${summoner.riotIdTagline}`} className="text-xs flex hover:underline">
                        <img className="w-4 rounded-md mr-1" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[summoner.championId]?.name}.png`} alt={champions[summoner.championId]?.name}/>
                        <span className={`text-ellipsis whitespace-nowrap overflow-hidden ${summoner.riotIdGameName === sum ? ('text-black dark:text-white'):('text-text_drk')}`}>{summoner.riotIdGameName}</span>
                    </Link>
                )
            })}
        </div>
        </>
        ):(
        <div className="w-[230px] flex flex-col gap-1 justify-evenly">
            {summoners[index]?.v2teams?.slice(0,4).map((summoner:any, index: number) => {
                return(
                    <div key={index} className="flex justify-end items-center gap-1">
                    <div className="text-[10px] h-[16px] w-[28px] flex justify-center items-center bg-third_light dark:text-text text-black dark:bg-primary rounded-md italic">{formatPlacement(index+1)}</div>
                    <Link to={`/summoner/${region}/${summoner[0].riotIdGameName}/${summoner[0].riotIdTagline}`} className="text-xs flex items-center hover:underline w-[80px]">
                        <img className="w-4 rounded-md mr-1" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[summoner[0].championId]?.name}.png`} alt={champions[summoner[0].championId]?.name}/>
                        <span className={`text-ellipsis whitespace-nowrap overflow-hidden  ${summoner[0].riotIdGameName === sum ? ('text-black dark:text-white'):('text-text_drk')}`}>{summoner[0].riotIdGameName}</span>
                    </Link>
                    <Link to={`/summoner/${region}/${summoner[1].riotIdGameName}/${summoner[1].riotIdTagline}`} className="text-xs flex items-center hover:underline w-[80px]">
                        <img className="w-4 rounded-md mr-1" src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[summoner[1].championId]?.name}.png`} alt={champions[summoner[1].championId]?.name}/>
                        <span className={`text-ellipsis whitespace-nowrap overflow-hidden  ${summoner[1].riotIdGameName === sum ? ('text-black dark:text-white'):('text-text_drk')}`}>{summoner[1].riotIdGameName}</span>
                    </Link>
                    </div>
                )
            })}
        </div>
        )
    )
}