import { gameDuration, timeElapsedString } from "../../cases/GameTimer";
import { mapType } from "../../cases/MapsSpells";
import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";

export default function MatchInformation({summoner,match,index}:any){
    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;
    
    return(
        <>
        <div className="flex flex-col">
            <span className="text-text_drk dark:text-white font-semibold text-[12px]">{mapType(match.match.info.queueId)}</span>
            <span className="text-[12px]">{timeElapsedString(match.match.info.gameEndTimestamp, false, currLangData)}</span>
        </div>
        <div className="flex flex-col font-semibold">
            <span className={`text-${summoner[index]?.result} text-[12px]`}>{currLangData.compare[summoner[index]?.result]}</span>
            <span className="text-[12px]">{gameDuration(match.match.info.gameDuration)}</span>
        </div>
        </>
    )
}