import { useEffect, useState } from "react";
import { useVersion } from "../contexts/VersionContext";
import axios from "axios";
import { hosting } from "../cases/Hosting";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";

export default function Rotation(){
    const {version} = useVersion();
    const [champions, setChampions] = useState<any>([]);
    const [rotation, setRotation] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

    useEffect(() => {
        if (!version) return;
    
        const fetchChamps = async () => {
            try {
                const response = await axios.get(`${hosting}/api/fetchAllChampionNames`);
                const champs = response.data.reduce((acc:any, champ:any) => {
                acc[champ.champKey] = { name: champ.Name, displayName: champ.champName, position: champ.positionMain };
                return acc;
                }, {});
                setChampions(champs);
                const rotation = await axios.get(`${hosting}/rotation`);
                setRotation(rotation.data)
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchChamps();
    }, [version]);

    return(
        <div className={`${loading?('flex justify-center items-center'):('')} w-full md:w-[420px] h-[100px] bg-primary_l_trans dark:bg-primary_trans p-2 rounded-none md:rounded-md shadow-md BoxShadow`}>
        {loading ? (<ClipLoader color="#85417C"/>):(
        <>
            <div className="dark:text-white text-black text-center mb-2">{currLangData.main.rot}</div>
            <div className="flex overflow-x-scroll gap-2">
                {rotation?.freeChampionIds?.map((champion:any, index:number)=>(
                    <div key={index} className="flex">

                    <Link to={`/champion/${champions[champion].name}`} className='rounded-md overflow-hidden md:w-12 md:h-12 w-10 h-10'>
                    <img className="scale-[1.1]" alt={champions[champion].name} src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champions[champion].name}.png`}/>
                    </Link>
                        
                    </div>
                ))}
            </div>
        </>
        )}

        </div>                  
    )
}