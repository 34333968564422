import { Link } from 'react-router-dom';import { useEffect, useRef, useState } from 'react';
import { useTheme } from '../contexts/ThemesContext';
import NavButtons from '../components/NavButtons';
import UserSettings from './UserSettings';
import SearchBar from './SearchBar';
//---------------------ICONS------------------------------
import { MdArrowDropDown, MdArrowDropUp, MdTheaters  } from 'react-icons/md';
import { FaRankingStar, FaApple, FaScissors  } from "react-icons/fa6";
import { IoMdSettings ,IoLogoGameControllerB } from "react-icons/io";
import { GrSystem, GrPaint, GrArticle  } from "react-icons/gr";
import { IoSunnySharp, IoMoonSharp } from 'react-icons/io5';
import { FaFileCsv,FaShoppingBag  } from "react-icons/fa";
import { SiLeagueoflegends } from "react-icons/si";
import { RxOpenInNewWindow } from "react-icons/rx";


export default function NavBar() {

  const [showLinks, setShowLinks] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const linksRef = useRef<HTMLDivElement>(null);
  const settingsBar = useRef<HTMLDivElement>(null);

  const Array = [
    {subdomain:"lol",    name:"League of Legends", icon: <SiLeagueoflegends />},
    {subdomain:"csv",    name:"CSV Reader",        icon: <FaFileCsv />},
    {subdomain:"influ",  name:"InfluRank",         icon: <FaRankingStar />},
    {subdomain:"iphone", name:"Iphone",            icon: <FaApple />},
    {subdomain:"cinema", name:"Cinema",            icon: <MdTheaters />},
    {subdomain:"os",     name:"Rafte.ch OS",       icon: <GrSystem />},
    {subdomain:"art",    name:"Artwork",           icon: <GrPaint />},
    {subdomain:"blog",   name:"Blog",              icon: <GrArticle />},
    {subdomain:"hair",   name:"Hairdresser",       icon: <FaScissors />},
    {subdomain:"shop",   name:"Shop",              icon: <FaShoppingBag />},
    {subdomain:"games",  name:"Games",             icon: <IoLogoGameControllerB />},
    
  ]

  const AAA = () => setShowLinks(!showLinks)
  const BBB = () => setShowSettings(!showSettings)
  

  function getSubdomain(url:any) {
    const hostname = new URL(url).hostname;
    const parts = hostname.split('.');
    if (parts.length >= 2) {
        return parts[0];
    }
    return null; 
  }
  const currentSubdomain = getSubdomain(window.location.href);


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (settingsBar.current && !settingsBar.current.contains(event.target as Node)) {
        setShowSettings(false);
      }
      if (linksRef.current && !linksRef.current.contains(event.target as Node)) {
        setShowLinks(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);


  return(
    <>
    <div className="flex h-10 bg-primary justify-between text-white">
          
    <Link className='flex h-10 items-center bg-third_light dark:bg-secondary px-3' to="/">
      <span className='font-bold text-base md:text-lg tracking-wide text-text_light dark:text-white first-letter:text-pink'>RAFTE.CH</span>
    </Link> 

    <div className="bg-[rgb(59,91,147)] w-full h-10 flex justify-between">

    <div ref={linksRef} className='flex items-center position: relative z-50'>
      <button onClick={AAA} className='flex gap-1 items-center'>
        <div className={`flex justify-center h-10 items-center font-medium text-sm hover:bg-lol_light w-[175px]`}>
            <SiLeagueoflegends  className='mx-1'/>
            League of legends
            {!showLinks ? (<MdArrowDropDown className='w-5 h-5'/>):(<MdArrowDropUp className='w-5 h-5'/>)}
        </div>
        {showLinks && <div className='absolute top-full left-0 w-[175px] bg-[rgb(59,91,147)] flex flex-col content-center z-10 rounded-b-md overflow-hidden'>
                {Array.filter(item => item.subdomain !== currentSubdomain).map((item, index) => (
                  <Link to={`https://${item.subdomain}.rafte.ch`} target="_blank" key={index} className='h-8 py-1 flex gap-1 justify-between items-center px-2 hover:bg-lol_light border-t-[1px] border-lol_light'>
                    <div className='flex gap-1 justify-between items-center'>{item.icon}<span className='text-xs'>{item.name}</span></div>
                    <RxOpenInNewWindow  className='text-white w-3 h-3'/>
                  </Link>
                ))}
              </div>}
      </button>
      </div>

      <div ref={settingsBar} className='flex items-center gap-2 px-2 position: relative'>
        <ThemeToggle/>
        <button  onClick={BBB}><IoMdSettings className='cursor-pointer hover:animate-spin'/></button>
        {showSettings && <UserSettings/>}
      </div>
        
    </div>

  </div>

    <div className='w-screen bg-third_light dark:bg-secondary flex justify-center'>
  <div className='h-24 md:h-12 rt:h-12 w-[1080px] flex items-center justify-between px-2 flex-col-reverse md:flex-row rt:flex-row rt:justify-between'>
    <NavButtons/>
    <SearchBar/>
  </div>
    </div>
  </>
  );
}


function ThemeToggle() {
  const { theme, toggleTheme } = useTheme();

  return (
    <button onClick={toggleTheme} className='w-12 h-6 rounded-[20px] bg-lol_light px-[2px] py-[2px] overflow-hidden flex justify-center items-center'>
      <div className={`w-[48px] h-[20px] rounded-[16px] transition-colors dark:bg-blue-950 bg-sky-500 flex ${theme !== "dark" ? ('justify-start') : ('justify-end')} items-center p-[2px] position: relative`}>
        <div className='rounded-full bg-primary_light w-[16px] h-[16px] position: absolute dark:left-[2px] left-[26px] transition-all'></div>
        {theme === 'dark' ? (
          <div><IoMoonSharp className='h-[16px] w-[16px] text-yellow-400'/></div>
        ) : (
          <div><IoSunnySharp className='h-[16px] w-[16px] text-yellow-400'/></div>
        )}
      </div>
    </button>
  );
}
