import { useState } from "react";
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

export default function Faq(){

    const [clicked, setClicked] = useState<any>({});

    const toggleFaq = (key:any) => {
        setClicked((prev:any) => ({
            ...prev,
            [key]: !prev[key] 
        }));
    };

    const questions:any = {
        "1":{
            "question":"Do you sell user data for profit?",
            "answer":"<p class='text-text'>No, we are not selling Your data</p>"
        },
        "2":{
            "question":"How many people are working on the project?",
            "answer":"<p class='text-text'>Only me, one person.</p>"
        },
        "3":{
            "question":"How can I support this project?",
            "answer":"<p class='text-text'>Link to patreon/paypal/etc.</p>"
        },
        "4":{
            "question":"Where can I report an issue with website?",
            "answer":"<p class='text-text'>In settings section or directly at <a href=mailto:info@rafte.ch class='text-pink'>info@rafte.ch</a></p>"
        },
        "5":{
            "question":"Why profile takes so long to load?",
            "answer":"<p class='text-text'>Website uses cheap server beacuse, owner of the website is not making enough money to upgrade hosting.</p>"
        },
        "6":{
            "question":"?",
            "answer":"<p class='text-text'>?.</p>"
        }
    } 

    const oddKeys = Object.keys(questions).filter((key, index) => index % 2 === 0);
    const evenKeys = Object.keys(questions).filter((key, index) => index % 2 !== 0);

    return(
        <div className="flex justify-center lg:mb-[500px]">
        <div className="flex w-[1080px] box-border text-black dark:text-white px-2 pt-5">
        <div className={`flex justify-center w-full`}>
            <div className="w-full lg:w-1/2 p-1 hidden lg:flex flex-col gap-2">
                {oddKeys.map((key) => {
                    const item = questions[key];
                    return (
                        <div key={key} className={`relative overflow-hidden bg-third_light dark:bg-secondary rounded-md ${clicked[key] ? "dark:bg-third" : ""}`}>
                            <button onClick={() => toggleFaq(key)} className="w-full flex items-center justify-between p-4">
                                <span className="w-8 h-8 text-[20px]">{key}.</span>
                                <span className="md:w-[400px] text-left"> {item.question}</span>
                                {!clicked[key] ? <MdArrowDropDown className="w-8 h-8"/> : <MdArrowDropUp className="w-8 h-8"/>}
                            </button>
                            {clicked[key] && <div className="flex justify-between items-center w-full p-4 pt-1">
                                                <div className="w-8 h-8"></div>
                                                <p dangerouslySetInnerHTML={{ __html: item.answer }} className="md:w-[400px]"/>
                                                <div className="w-8 h-8"></div>
                                             </div>}
                        </div>
                    );
                })}
            </div>
            <div className="w-full md:w-1/2w-1/2 p-1 hidden lg:flex flex-col gap-2">
                {evenKeys.map((key) => {
                    const item = questions[key];
                    return (
                        <div key={key} className={`relative overflow-hidden bg-third_light dark:bg-secondary rounded-md ${clicked[key] ? "dark:bg-third " : ""}`}>
                            <button onClick={() => toggleFaq(key)} className="w-full flex items-center justify-between p-4">
                                <div className="flex justify-between items-center w-full">
                                    <span className="w-8 h-8 text-[20px]">{key}.</span>
                                    <span className="md:w-[400px] text-left"> {item.question}</span>
                                    {!clicked[key] ? <MdArrowDropDown className="w-8 h-8"/> : <MdArrowDropUp className="w-8 h-8"/>}
                                </div>
                            </button>
                            {clicked[key] && <div className="flex justify-between items-center w-full p-4 pt-1">
                                                <div className="w-8 h-8"></div>
                                                <p dangerouslySetInnerHTML={{ __html: item.answer }} className="md:w-[400px]"/>
                                                <div className="w-8 h-8"></div>
                                             </div>}
                        </div>
                    );
                })}
            </div>

            <div className="w-full p-1 lg:hidden flex flex-col gap-2">
                {Object.keys(questions).map((key:any) => {
                    const item = questions[key];
                    return (
                        <div key={key} className={`relative overflow-hidden bg-third_light dark:bg-secondary rounded-md ${clicked[key] ? "dark:bg-third" : ""}`}>
                            <button onClick={() => toggleFaq(key)} className="w-full flex items-center justify-between p-4">
                                <span className="w-8 h-8 text-[20px]">{key}.</span>
                                <span className="text-[14px]"> {item.question}</span>
                                {!clicked[key] ? <MdArrowDropDown className="w-8 h-8"/> : <MdArrowDropUp className="w-8 h-8"/>}
                            </button>
                            {clicked[key] && <div className="flex justify-between items-center w-full p-4 pt-1">
                                                <div className="w-8 h-8"></div>
                                                <p dangerouslySetInnerHTML={{ __html: item.answer }} className="text-[14px]"/>
                                                <div className="w-8 h-8"></div>
                                             </div>}
                        </div>
                    );
                })}
            </div>
        </div>
        </div>
        </div>
    )
}