import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import RegionList from "../navBar/RegionList";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import GameTimer from "../cases/GameTimer";
import { useVersion } from "../contexts/VersionContext";
import { Link } from "react-router-dom";
import { mapType } from "../cases/MapsSpells";
import { hosting } from "../cases/Hosting";

export default function LiveGames() {
  const [liveGames, setLiveGames] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState('EUNE');
  const [showRegions, setShowRegions] = useState(false);
  const toggleRegions = () => setShowRegions(!showRegions);

  const { version } = useVersion();

  const handleGoClick = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${hosting}/liveGame/${selectedRegion.toLowerCase()}`);
      setLiveGames(response.data);
      setLoading(false);
    } catch (error: any) {
      if (error.response?.data?.error) {
        console.log('lipa');
      } else {
        console.log(error);
      }
    }
  }, [selectedRegion])

  const handleRegionSelect = (region: string) => {
    setSelectedRegion(region);
    setShowRegions(false);
  };

  useEffect(() => {
    handleGoClick();
  }, [selectedRegion, handleGoClick]);


  return (
    <div className="w-full flex justify-center mt-[50px] lg:mb-[550px]">
      <div className={`w-full lg:w-[1080px] ${loading?('lg:h-[381px]'):('')} bg-third_light dark:bg-secondary flex flex-col items-center p-3 lg:rounded-md gap-4`}>
        <div className="position: relative">
          <button onClick={toggleRegions} className="text-black w-[210px] justify-between rounded-md flex items-center bg-inputColor px-2 h-9 font-semibold text-sm">
            {selectedRegion.toUpperCase()} {showRegions ? <MdArrowDropUp fontSize={20} /> : <MdArrowDropDown fontSize={20} />}
          </button>
          {showRegions && <RegionList onSelect={handleRegionSelect} />}
        </div>

        <div className="flex-col lg:flex-row flex justify-evenly w-full text-white gap-2 lg:gap-0">
          {liveGames?.gameList?.map((game: any, gameIndex: number) => {
            const team = game.participants.reduce((acc: any, participant: any) => {
              acc[participant.teamId] = [...(acc[participant.teamId] || []), participant];
              return acc;
            }, {});

            return (
          <div key={gameIndex} className={`bg-primary_light dark:bg-third p-2 rounded-md w-full lg:w-[200px] ${game.gameMode !== "CHERRY" ? ('lg:h-[305px]'):('lg:h-[375px]')}`}>
                <div className="borderHor flex justify-between text-black dark:text-white">
                  <span className={`${game.gameMode === "ARAM" ? ('uppercase'):('lowercase first-letter:uppercase')}`}>{mapType(game.gameQueueConfigId)}</span>
                  <GameTimer gameLength={game.gameLength}/>
                </div>
              <div className="flex flex-col pt-2]">
                
                <Link to={`/summoner/${selectedRegion.toLowerCase()}/${game?.participants[0].riotId.split('#')[0]}/${game?.participants[0].riotId.split('#')[1]}/live`} className="hidden lg:flex items-center flex-col pt-1 pb-1 gap-2 text-black dark:text-white">
                  <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${game?.participants[0].profileIconId}.png`} alt={game?.participants[0].profileIconId} className="w-[100px] h-[100px] rounded-md"/>
                  <span className="font-bold max-w-[100px] overflow-hidden whitespace-nowrap hover:underline">{game?.participants[0].riotId.split('#')[0]}</span>
                </Link>

                <div className="flex justify-between">

                  <div className="flex flex-col justify-between gap-1">
                  {game.gameMode !== "CHERRY" ? (
                    team[100]?.map((user: any, userIndex: number) => (
                      <Link to={`/summoner/${selectedRegion.toLowerCase()}/${user?.riotId.split('#')[0]}/${user?.riotId.split('#')[1]}/live`} key={userIndex} className="flex gap-1 hover:underline">
                        <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${user.profileIconId}.png`} alt={user.profileIconId} className="w-5 h-5 rounded-md"/>
                        <span className="text-sm w-14 overflow-hidden whitespace-nowrap text-text">{user.riotId.split('#')[0]}</span>
                      </Link>
                    ))
                  ):(
                    team[100]?.slice(0,8).map((user: any, userIndex: number) => (
                      <Link to={`/summoner/${selectedRegion.toLowerCase()}/${user?.riotId.split('#')[0]}/${user?.riotId.split('#')[1]}/live`} key={userIndex} className="flex gap-1 hover:underline">
                        <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${user.profileIconId}.png`} alt={user.profileIconId} className="w-5 h-5 rounded-md"/>
                        <span className="text-sm w-14 overflow-hidden whitespace-nowrap text-text">{user.riotId.split('#')[0]}</span>
                      </Link>
                    ))
                  )}
                  </div>
                  
                  <Link to={`/summoner/${selectedRegion.toLowerCase()}/${game?.participants[0].riotId.split('#')[0]}/${game?.participants[0].riotId.split('#')[1]}/live`} className="lg:hidden flex items-center justify-center flex-col pt-1 pb-1 gap-2 text-black dark:text-white">
                    <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${game?.participants[0].profileIconId}.png`} alt={game?.participants[0].profileIconId} className="w-[100px] h-[100px] rounded-md"/>
                    <span className="font-bold max-w-[100px] overflow-hidden whitespace-nowrap hover:underline">{game?.participants[0].riotId.split('#')[0]}</span>
                  </Link>

                  <div className="flex flex-col justify-between gap-1">
                  {game.gameMode !== "CHERRY" ? (
                    team[200]?.map((user: any, userIndex: number) => (
                      <Link to={`/summoner/${selectedRegion.toLowerCase()}/${user?.riotId.split('#')[0]}/${user?.riotId.split('#')[1]}/live`}  key={userIndex} className="flex gap-1 hover:underline">
                        <span className="text-sm w-14 overflow-hidden whitespace-nowrap text-text text-right">{user.riotId.split('#')[0]}</span>
                        <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${user.profileIconId}.png`} alt={user.profileIconId} className="w-5 h-5 rounded-md"/>
                      </Link>
                    ))
                  ):(
                    team[100]?.slice(8,16).map((user: any, userIndex: number) => (
                      <Link to={`/summoner/${selectedRegion.toLowerCase()}/${user?.riotId.split('#')[0]}/${user?.riotId.split('#')[1]}/live`}  key={userIndex} className="flex gap-1 hover:underline">
                        <span className="text-sm w-14 overflow-hidden whitespace-nowrap text-text text-right">{user.riotId.split('#')[0]}</span>
                        <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${user.profileIconId}.png`} alt={user.profileIconId} className="w-5 h-5 rounded-md"/>
                      </Link>
                    ))
                  )}
                  </div>

                </div>

              </div>
              
          </div>
            );
          })}
        </div>

      </div>
    </div>
  );
}
