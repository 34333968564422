import { Link } from "react-router-dom";
import { RxOpenInNewWindow } from "react-icons/rx";
import { SiLeagueoflegends } from "react-icons/si";
import { useVersion } from "../contexts/VersionContext";
import { ClipLoader } from "react-spinners";

export default function PatchNotes(){
    const {version} = useVersion();

    const convertVersion = (versionString:any) => {
        const parts = versionString.split('.');
        if (parts.length < 2) {
          return versionString;
        }
        return `${parts[0]}-${parts[1]}`;
      };

    return(
        <Link target="_blank" to={`https://www.leagueoflegends.com/en-us/news/game-updates/patch-${convertVersion(version)}-notes/`} className="w-full md:w-[220px] h-[100px] overflow-hidden bg-primary_l_trans dark:bg-primary_trans p-2 rounded-none md:rounded-md shadow-md BoxShadow flex justify-center items-center relative">
        {!version ? (<ClipLoader color="#85417C"/>):(
            <>
            <span className="flex justify-center items-center w-5 h-5 absolute top-0 right-0 bg-[#9E9EB1] rounded-bl-md">
                <RxOpenInNewWindow  className='text-white w-4 h-4'/>
            </span>
            <span className="text-[15px] text-black dark:text-white font-medium flex items-center gap-1"><SiLeagueoflegends className="text-[20px]" />Patch notes <span className="flex text-pink">{version}</span></span>    
            </>
        )}
        </Link>
    )
}