import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import {MdContentCopy} from 'react-icons/md';
import {BsCheck} from "react-icons/bs"
import {HiCheckBadge} from 'react-icons/hi2';
import { useEffect, useState } from "react";
import { title } from "../cases/Titles";
import { useVersion } from "../contexts/VersionContext";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { ClipLoader } from "react-spinners";
import languageData from "../json/languageData.json";
import { useLanguage } from "../contexts/LanguageContext";

export default function SummonerInfo({summonerData, loading} : any){
    const { region, summoner, tagLine} = useParams()
    const {version} = useVersion();
    const [copied, setCopied] = useState(false);
    const [isFavorited, setIsFavorited] = useState(false);

    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;

    const handleFavoriteClick = () => {
      const favoriteSummoners = JSON.parse(localStorage.getItem('favorites') || '[]');
      const currentSummoner = { region, summoner, tagLine };
    
      if (isFavorited) {
        const filteredFavorites = favoriteSummoners.filter(
          (fav:any) => fav.summoner !== summoner || fav.region !== region || fav.tagLine !== tagLine
        );
        localStorage.setItem('favorites', JSON.stringify(filteredFavorites));
        setIsFavorited(false);
      } else {
        localStorage.setItem('favorites', JSON.stringify([...favoriteSummoners, currentSummoner]));
        setIsFavorited(true);
      }
    };

    useEffect(() => {
      const favoriteSummoners = JSON.parse(localStorage.getItem('favorites') || '[]');
      const isCurrentSummonerFavorited = favoriteSummoners.some(
        (fav:any) => fav.summoner === summoner && fav.region === region && fav.tagLine === tagLine
      );
      setIsFavorited(isCurrentSummonerFavorited);
    }, [region, summoner, tagLine]);

    const copyToClipboard = () => {
      const link = window.location.href;
      const message = currLangData.copy +'\n';
      const siteURL = message + link;
    
      if (navigator.clipboard) {
        navigator.clipboard.writeText(siteURL)
          .then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
          })
          .catch((error) => {
            console.error('Failed to copy:', error);
          });
      } else {
        let tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.value = siteURL;
        tempInput.select();
        tempInput.setSelectionRange(0, 99999);
    
        try {
          setCopied(true);
          setTimeout(() => setCopied(false), 1000);
        } catch (error) {
          console.error('Fallback copy method failed:', error);
        }
    
        document.body.removeChild(tempInput);
      }
    };

return(
    <div className="pt-2 w-full h-[190px] bg-white dark:bg-secondary flex justify-center items-center">
        <div className="w-[1080px] flex">
            <div className="ml-[0.5rem] rt:ml-0 position: relative bg-primary_light dark:bg-primary p-[5px] rounded-[11px] h-[130px] w-[110px] flex justify-center">
                
                {!loading ? (
                <>
                <img src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${summonerData?.summonerInfo.profileIconId}.png`} alt={summonerData?.summonerInfo.profileIconId} className="w-[100px] h-[100px] rounded-md"/>

                <div className=" position: absolute bottom-[3px] left-[0px] flex justify-center w-full">
                    <span className="flex justify-center bg-primary_light dark:bg-primary text-[12px] rounded-md dark:text-text text-black">{currLangData.level} {summonerData?.summonerInfo.summonerLevel}</span>
                </div>
                </>
                ):(<ClipLoader color="#85417C" className="mt-10"/>)}

            </div>
            <div className="ml-[15px]">

            {!loading ? (
                <div className="flex items-center">
                  <span className="font-bold dark:text-white text-black text-[26px] position: relative parEle max-w-[200px] text-ellipsis whitespace-nowrap overflow-hidden">{summonerData?.gameName} <h4 className="italic text-[10px] text-text position: absolute top-[-15px] left-0 hidden childEle">{currLangData.prev} {summonerData?.gameName}</h4></span>
                  <span className="text-text text-[14px] pl-1">#{tagLine}</span>
                  {summonerData?.summonerInfo.name === "Ëren Yaeger" ? (<HiCheckBadge className='fill-pink ml-[5px] h-[26px] w-[26px]'/>):(null)}
                </div>
            ): (<><Skeleton baseColor="#1C1C1F" highlightColor="#31313C" containerClassName="w-[200px] h-[30px] flex-1 dark:flex hidden"/><Skeleton baseColor="#FFFFFF" highlightColor="#EBEEF1" containerClassName="w-[200px] h-[30px] flex-1 dark:hidden flex"/></>)}

            {!loading ? (
                <span className="text-[16px] italic dark:text-text text-black">{title(summonerData?.summonerTitle.preferences.title)}</span>
            ) : (<><Skeleton baseColor="#1C1C1F" highlightColor="#31313C" containerClassName="w-[130px] flex-1 dark:flex hidden mt-2"/><Skeleton baseColor="#FFFFFF" highlightColor="#EBEEF1" containerClassName="w-[130px] flex-1 dark:hidden flex mt-2"/></>)}

                <div className="h-[32px] w-[62px] mt-[5px] flex items-center justify-between">
                    <button onClick={handleFavoriteClick} className="w-[28px] h-[28px] rounded-md dark:bg-primary bg-primary_light ">
                        {isFavorited ? (<AiFillStar className="w-[28px] h-[28px] p-[2px] fill-pink"/>):(<AiOutlineStar className="w-[28px] h-[28px] p-[2px] fill-text hover:fill-pink"/>)}
                    </button>
                    <button className="w-[28px] h-[28px] rounded-md dark:bg-primary bg-primary_light" onClick={copyToClipboard}>
                      {copied ? <BsCheck className="w-[28px] h-[28px] p-[2px] fill-pink"/> : <MdContentCopy className="w-[28px] h-[28px] p-[5px] fill-text hover:fill-pink"/>}
                    </button>
                </div>
                <div className="mt-[5px]">
                <button
                  className="bg-pink px-[10px] text-[14px] rounded-md h-[34px] text-white"
                  onClick={() => window.location.reload()}
                >
                  {currLangData.summonerProfile.buttons.update}
                </button>
                </div>
            </div>
        </div>
    </div>
)

}