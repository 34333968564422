export default function PrivPol(){

    return(
        <div className="flex justify-center">
        <div className="flex flex-col justify-center max-w-[1080px] text-black dark:text-white px-2 pt-5">
        <p className="font-semibold pt-[10px] text-[13px]">Privacy Policy</p>
    
        <p className="text-left text-text font-[11px]">At Rafte.ch, the privacy of our visitors is of utmost importance to us. This Privacy Policy outlines the types of personal information that is received and collected by <a href="https://lol.rafte.ch" className="text-pink">www.rafte.ch</a> and how it is used.</p>
    
        <p className="font-semibold pt-[10px] text-[13px]">Information Collection and Use</p>
    
        <p className="text-left text-text font-[11px]">When you use our website, we may collect certain personal information from you, such as your name, email address, and summoner name. We collect this information to provide you with the best possible experience on our site and to enable us to provide you with personalized content.</p>
    
        <p className="text-left text-text font-[11px]">We use Riot Games' API to fetch summoner data and DDragon services to get certain images in better quality for our website. By using our site, you consent to the collection and use of your personal information in accordance with this policy.</p>
    
        <p className="font-semibold pt-[10px] text-[13px]">Information Sharing and Disclosure</p>
    
        <p className="text-left text-text font-[11px]">We do not sell, trade, or otherwise transfer your personal information to third parties. However, we may share your personal information with trusted third-party service providers who assist us in operating our website or providing services to you, as long as those parties agree to keep this information confidential.</p>
    
        <p className="text-left text-text font-[11px]">We may also release your personal information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</p>
    
        <p className="font-semibold pt-[10px] text-[13px]">Cookies</p>
    
        <p className="text-left text-text font-[11px]">Our website uses cookies to enhance your browsing experience. By using our site, you consent to our use of cookies. You may choose to disable cookies through your individual browser options.</p>
    
        <p className="font-semibold pt-[10px] text-[13px]">Security</p>
    
        <p className="text-left text-text font-[11px]">We take the security of your personal information seriously and have implemented appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect.</p>
    
        <p className="font-semibold pt-[10px] text-[13px]">Ads by Google</p>
    
        <p className="text-left text-text font-[11px]">We may use Google Adsense or other third-party advertising networks to display advertisements on our website. These ads may be served by Google or other companies and may be tailored based on your browsing history and interests.</p>
        <p className="text-left text-text font-[11px]">Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of the DART cookie enables it to serve ads to our users based on their visit to our site and other sites on the Internet. Users may opt out of the use of the DART cookie by visiting the Google Ad and Content Network Privacy Policy.</p>
        <p className="text-left text-text font-[11px]">Please note that these third-party ad servers or ad networks have their own privacy policies, and we do not have control over the cookies that may be used by third-party advertisers. We encourage you to review the privacy policies of these third-party ad servers or ad networks for more information regarding their practices and how to opt out.</p>
        <p className="text-left text-text font-[11px]">Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users. To learn more about Google's Advertising Principles, please visit the Google Advertising Principles page.</p>
    
        <p className="font-semibold pt-[10px] text-[13px]">Changes to This Policy</p>
    
        <p className="text-left text-text font-[11px]">We reserve the right to modify this Privacy Policy at any time. Any changes will be posted to this page, and your continued use of our website after any modifications indicate your acceptance of the new Privacy Policy.</p>
    
        <p className="font-semibold pt-[10px] text-[13px]">California Privacy Rights</p>
    
        <p className="text-left text-text font-[11px]">Under the California Consumer Privacy Act, California residents have the right to request that we disclose certain information about our collection and use of your personal information over the past 12 months. You may make such a request by emailing us at <a href='mailto:info@rafte.ch' className='text-pink'>info@rafte.ch</a>.</p>
    
        <p className="font-semibold pt-[10px] text-[13px]">Children's Online Privacy Protection Act</p>
    
        <p className="text-left text-text font-[11px]">Our website is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you are under 13, please do not provide us with any personal information.</p>
    
        <p className="font-semibold pt-[10px] text-[13px]">Contact Us</p>
    
        <p className="text-left text-text font-[11px]">If you have any questions about this Privacy Policy, please contact us at <a href='mailto:info@rafte.ch' className='text-pink'>info@rafte.ch</a>.</p>
    
        <p className="font-semibold pt-[10px] text-[13px]">Effective Date</p>
    
        <p className="text-left text-text font-[11px]">This Privacy Policy is effective as of May 1, 2023.</p>
        </div>
        </div>
    )
}