import DetailsComponent from "./DetailsComponent";
import languageData from "../../json/languageData.json";
import { useLanguage } from "../../contexts/LanguageContext";

export default function DetailsOverview({match, version, champions}:any){

    const {language: lang} = useLanguage();
    const currLangData: any = (languageData as any)[lang] || (languageData as any).en;
  

    const mode = match?.match.info.gameMode;
    const queue = match?.match.info.queueId;

    const participantData = match.match.info.participants.map((user:any) => ({
        name: user.riotIdGameName,
        champ: user.championId,
        kills: user.kills,
        deaths: user.deaths,
        assists: user.assists,
        wards: user.detectorWardsPlaced,
        gold: user.goldEarned,
        cs: user.totalMinionsKilled,
        dmgd: user.totalDamageDealtToChampions,
        dmgt: user.totalDamageTaken,
        place: user.placement,
      }));
    
      const findHighestValues = () => {
        return participantData.reduce((highest:any, user:any) => ({
          champ: highest.champ || user.champ,
          kills: Math.max(highest.kills, user.kills),
          deaths: Math.max(highest.deaths, user.deaths),
          assists: Math.max(highest.assists, user.assists),
          wards: Math.max(highest.wards, user.wards),
          gold: Math.max(highest.gold, user.gold),
          cs: Math.max(highest.cs, user.cs),
          dmgd: Math.max(highest.dmgd, user.dmgd),
          dmgt: Math.max(highest.dmgt, user.dmgt),
        }), {
          champ: null,
          kills: -Infinity,
          deaths: -Infinity,
          assists: -Infinity,
          wards: -Infinity,
          gold: -Infinity,
          cs: -Infinity,
          dmgd: -Infinity,
          dmgt: -Infinity,
        });
      };
      
      const highestValues = findHighestValues();

    return(
        <div className="flex flex-col p-1 gap-1">
            {mode !== "CHERRY" ? (
            <>
            <div className="flex justify-center gap-1">
                <div className="lg:w-1/3 w-1/2 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.kills}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"kills"} divider={"kills"}/>
                </div>
                <div className="lg:w-1/3 w-1/2 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.assists}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"assists"} divider={"assists"}/>
                </div>
                <div className="lg:w-1/3 w-1/2 hidden lg:flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.deaths}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"deaths"} divider={"deaths"}/>
                </div>
            </div>

            <div className="lg:hidden flex gap-1">
                <div className="w-1/2 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.deaths}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"deaths"} divider={"deaths"}/>
                </div>
                <div className="w-1/2 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.cs}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"cs"} divider={"cs"}/>
                </div>
            </div>

            <div className="flex gap-1">
                <div className="w-1/2 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.dmgD}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"dmgd"} divider={"dmgd"}/>
                </div>
                <div className="w-1/2 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.dmgT}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"dmgt"} divider={"dmgt"}/>
                </div>
            </div>

            <div className="flex gap-1 ">
                <div className="w-1/2 lg:w-1/3 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.gold}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"gold"} divider={"gold"}/>
                </div>
                <div className="hidden w-1/2 lg:w-1/3 lg:flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.cs}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"cs"} divider={"cs"}/>
                </div>
                <div className="w-1/2 lg:w-1/3 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.ward}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"wards"} divider={"wards"}/>
                </div>
            </div>
            </>

            ):(
            
            <>
            <div className="flex justify-center gap-1">
                <div className="lg:w-1/3 w-1/2 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.kills}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"kills"} divider={"kills"} mode={mode} queue={queue}/>
                </div>
                <div className="lg:w-1/3 w-1/2 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.assists}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"assists"} divider={"assists"} mode={mode} queue={queue}/>
                </div>
                <div className="lg:w-1/3 w-1/2 hidden lg:flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.deaths}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"deaths"} divider={"deaths"} mode={mode} queue={queue}/>
                </div>
            </div>

            <div className="lg:hidden flex gap-1">
                <div className="w-1/2 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.deaths}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"deaths"} divider={"deaths"} mode={mode} queue={queue}/>
                </div>
                <div className="lg:hidden w-1/2 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.gold}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"gold"} divider={"gold"} mode={mode} queue={queue}/>
                </div>
            </div>

            <div className="flex gap-1">
                <div className="w-1/2 lg:w-1/3 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.dmgD}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"dmgd"} divider={"dmgd"} mode={mode} queue={queue}/>
                </div>
                <div className="w-1/2 lg:w-1/3 flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.dmgT}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"dmgt"} divider={"dmgt"} mode={mode} queue={queue}/>
                </div>
                <div className="hidden w-1/3 lg:flex flex-col bg-primary_light dark:bg-third rounded-md p-1">
                    <div className="text-black dark:text-white text-xs text-center pb-1">{currLangData.compare.gold}</div>
                    <DetailsComponent participantData={participantData} highestValues={highestValues} version={version} champions={champions} variable={"gold"} divider={"gold"} mode={mode} queue={queue}/>
                </div>
            </div>
            </>
            )}
        </div>
    )
}