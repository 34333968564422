export default function detectBrowser() {
    let userAgentString = navigator.userAgent;

    let isChrome = userAgentString.includes("Chrome") && !userAgentString.includes("OP");
    let isIE = userAgentString.includes("MSIE") || userAgentString.includes("rv:");
    let isFirefox = userAgentString.includes("Firefox");
    let isSafari = userAgentString.includes("Safari") && !userAgentString.includes("Chrome");
    let isOpera = userAgentString.includes("OP") && userAgentString.includes("Opera");

    let browserName = "Unknown Browser";

    if (isChrome) {
        browserName = "Google Chrome";
    } else if (isIE) {
        browserName = "Internet Explorer";
    } else if (isFirefox) {
        browserName = "Mozilla Firefox";
    } else if (isSafari) {
        browserName = "Apple Safari";
    } else if (isOpera) {
        browserName = "Opera";
    }
    
    return browserName;
}